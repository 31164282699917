import React, { useState } from 'react';
import styled from 'styled-components';
import { useOnClickOutside } from '../../hooks/useOnClickOutside';

export function DateSelector(props) {
  const [openSelector, setOpenSelector] = useState(false);
  const [value, setValue] = useState(false);

  function closeSelector() {
    setOpenSelector(false);
  }

  const containerRef = useOnClickOutside(closeSelector);

  function toggleSelector() {
    setOpenSelector(!openSelector);
  }

  const select = (selectedValue) => () => {
    setValue(selectedValue);
    props.onChange({
      type: props.type,
      value: selectedValue
    });
  };

  return (
    <>
      <StyledSelector onClick={toggleSelector} ref={containerRef}>
        <StyledXSelectorPop openSelector={openSelector}>
          {
          props.data.map((item) => (
            <StyledXDropdownLi key={item} onClick={select(item)}>
              {item}
            </StyledXDropdownLi>
          ))
        }
        </StyledXSelectorPop>
        <StyledXSelectorTitle isSelected={!!value}>
          <div>{value || props.title}</div>
        </StyledXSelectorTitle>
      </StyledSelector>
    </>
  );
}

const StyledSelector = styled.div`
  position: relative;
  display: flex;
  margin-bottom: 0.8em;
  justify-content: flex-start;
  align-items: center;
  background-position: 95% 50%;
  background-size: 16px 16px;
  background-repeat: no-repeat;
  transition: transform 200ms ease-in-out;
  cursor: pointer;
  width: 30%;
  min-width: 180px;
  height: 36px;
  border-color: #bfbfbf;
  border-radius: 3px;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3Csvg width='18' height='12' viewBox='0 0 18 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M17.6141 2.79493L15.6006 0.781392L8.81732 7.56463L2.03201 0.779327L0.0184937 2.79283L8.82127 11.5956L10.8348 9.58208L10.8309 9.57817L17.6141 2.79493Z' fill='black'/%3E%3C/svg%3E%0A");
  color: rgba(0, 0, 0, 0.5);
  font-weight: 700;
  text-transform: none;
  padding-right: 0;
  padding-left: 0;
  font-family: Codecpro, sans-serif;
  
  @media screen and (max-width: 767px) {
    position: relative;
    width: 100%;
    height: 45px;
    min-width: 320px; 
  }
  
  @media screen and (max-width: 479px) {
    min-width: 290px;
  }
`;

const StyledXSelectorPop = styled.div`
  display: block;
  visibility: ${(props) => (props.openSelector ? 'visibility' : 'hidden')};
  opacity: ${(props) => (props.openSelector ? '1' : '0')};
  position: absolute;
  left: 0;
  top: 34px;
  right: auto;
  overflow: scroll;
  width: 100%;
  max-height: 200px;
  border-style: none;
  border-color: transparent;
  background-color: #fff;
  object-fit: none;
  z-index: 5;
  transition: visibility 0.3s, opacity 0.3s ease-in;
  
  @media screen and (max-width: 767px) {
    max-height: 200px;
  }
`;

const StyledXDropdownLi = styled.div`
  display: flex;
  width: 100%;
  min-height: 40px;
  padding-left: 1em;
  justify-content: flex-start;
  align-items: center;
  background-color: transparent;
  transition: all 200ms ease-in-out;
  color: #1f1f1f;
  font-size: 0.9rem;
  line-height: 1.1rem;
  text-align: left;
  text-decoration: none;
  text-transform: none;
  cursor: pointer;
  
  &:hover {
    background-image: linear-gradient(180deg, #e2e7ff, #e2e7ff);
  }
  
  &:active {
    background-image: linear-gradient(180deg, #abb2d8, #abb2d8);
    color: #fff;
  }
  
  @media screen and (max-width: 991px) { 
    &:hover {
      border-color: rgba(143, 163, 194, 0.25);
    }
  }
  
  @media screen and (max-width: 767px) {
    justify-content: flex-start;
  }
`;

const StyledXSelectorTitle = styled.div`
  margin-right: 0.5em;
  margin-left: 0.5em;
  color: ${(props) => (props.isSelected ? 'black' : 'inherit')};
`;
