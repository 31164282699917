import React, {
  Fragment, useEffect, useState, useContext
} from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { StoreContext } from './StoreContext';
import { getProductBySku } from './tools/getData';
import { Tag } from './Tags';
import { icons } from './Icons/icons';
import { Svg } from './Icons/Svg';

export function Drms(props) {
  const { sku, color, size = 28 } = { ...props };

  const context = useContext(StoreContext);

  const skuData = getProductBySku(context.skus, sku);

  if (skuData) {
    return (
      <>
        {skuData.fields.unit_items.map((oneUnit, ind) => (
          <span className="s_name_tag" key={`${sku}_${oneUnit.drm_sku}`}>
            <Svg width={size} height={size} color={color}>{icons[`platform_${oneUnit.drm_sku}`]}</Svg>
          </span>

        ))}
      </>
    );
  } return null;
}
