import React from 'react';
import Slider from './Slider';

export default function ProductList(props) {
  const {
    blockId,
    blockType = 'products',
    template = 'lg',
    limit,
    show_more,
    title,
    filter_key,
    filter_val,
    lim,
    setLim = () => void 0,

    filters,

    children
  } = props;

  return (

    <>

      {['slider2', 'bundle'].includes(template) && (
        <>
          {!!title && (
            <div className="cc">
              <div className="c_block">
                <div className="c_title">{title}</div>
              </div>
            </div>
          )}
          <Slider
            template={template}
            {...props}
          >
            {children}
          </Slider>

          {blockType === 'products' && <div className="c_block_sliderafter" />}
        </>
      )}

      <div className={`cc cc--${template}`}>
        {/* <div className={`c_bg c_bg--${template}`}></div> */}

        {!['slider2', 'bundle'].includes(template) && (
        <>
          <div className="cc">
            <div className="c_block">
              {!!title && <div className="c_title">{title}</div>}
            </div>
          </div>

          {filters}

          <div className="c_block">
            <div className="g">
              <div className={`grid grid--${template}`}>
                {children}
              </div>
            </div>

            {!!limit && show_more === 'button' && (
              <div className="x_more">
                <div
                  style={{ marginBottom: 44, position: 'relative' }}
                  className="but"
                  onClick={() => setLim(lim ? false : limit)}
                >
                  {!!lim && <span>See more</span>}
                  {!lim && <span>See less</span>}
                </div>
              </div>
            )}
          </div>
        </>
        )}
      </div>

    </>
  );
}
