import React, {useContext} from 'react';
import {AccountTitle} from './AccountTitle'
import {AccountRowWrap} from './AccountRowWrap'
import {AccountRowSet} from './AccountRowSet'
import {PaymentInfo} from "../PaymentInfo";
import {SettingsButton} from "../SettingsButton";
import {PaymentsMethodSettings, isMobile} from '../../api/cartApi';
import {StoreContext} from "../StoreContext";

export function AccountPaymentInformation() {
  const {loginToken} = useContext(StoreContext);
  const {userProfile} = useContext(StoreContext);

  const openWidgetHandler = async (e) => {
    e.preventDefault();

    const popup = isMobile() && window.open('', '_blank');

    await (async () => {
      await PaymentsMethodSettings({popup, loginToken, userProfile});
    })();
  }

  return (
    <AccountRowWrap>
      <AccountTitle title={'payment INFORMATION'}/>
      <AccountRowSet direction={"column"}>
        <PaymentInfo title={"Payment Methods"} desc={"Press Manage button to setup your favorite payment methods"}/>
        <SettingsButton text={"Manage"} type={"Manage"} cursor={"pointer"} onClick={openWidgetHandler} />
      </AccountRowSet>
    </AccountRowWrap>
  );
}

