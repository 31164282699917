import React, { useContext } from 'react';
import { StoreContext } from './StoreContext';
import { CartIcon } from './Button';

export function CartButton({ handlePop, children, cartAmount }) {
  const context = useContext(StoreContext);

  return (
    <>
      {Boolean(cartAmount) && (
        <div
          className={`k00_trigger ${!cartAmount ? 'shown' : ''}`}
          onClick={handlePop}
        >
          <CartIcon />
          {!!cartAmount && <div className="k00_num">{cartAmount}</div>}
        </div>
      )}

      <div className={`kb00 ${context.cartShown ? 'shown' : ''}`}>
        {children}
      </div>
    </>
  );
}
