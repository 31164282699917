import React from 'react';
import styled from 'styled-components';


export function AccountRowWrap({children}) {

  return (
    <StyledWrap>{children}</StyledWrap>
  );
}

const StyledWrap = styled.div`
  width: 100%;
  margin-bottom: 20px;
  
  @media screen and (max-width: 479px) {
    min-width: 260px;
    margin-bottom: 40px;
  }
`;