import React from 'react';
import styled, {keyframes, css} from 'styled-components';

export function SettingsButton({text, link, onClick, cursor, disabled}) {
  if (link) {window.location.assign(link)}

  return (
    <Wrapper onClick={onClick} disabled={disabled}>
      <StyledButton cursor={cursor} disabled={disabled}>{text}</StyledButton>
    </Wrapper>
  );
}

const hoverUnderline = keyframes`
  0% {    
    background-position: 100%;
    background-size: 100% 100%
  }
  50% {
    background-position: 100%;
    background-size: 0 100%
  }
  50.1% {
    background-size: 0 100%;
    background-position: 0
  }
  100% {
    background-position: 0;
    background-size: 100% 100%
  }
`;

const Wrapper = styled.div`
  pointer-events: ${(props) => props.disabled && 'none'};
`;

const StyledButton = styled.div`
  border: 0;
  line-height: inherit;
  text-decoration: none;
  cursor: ${(props) => props.cursor || "auto"};
  border-radius: 0;
  position: relative;
  align-self: center;
  border-bottom: 4px none #f77d00;
  background-color: transparent;
  background-image: linear-gradient(0deg, #f77d00 8%, transparent 0, transparent 93%);
  font-family: 'Codecpro ultra', sans-serif;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
  display: inline;
  padding: 9px 1px 8px 0px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: 100%;
  transition: none;
  
  &:hover {
    ${props => props.cursor ? css`animation:${hoverUnderline} .6s ease forwards` : 'none'};
  }
  
  ${(props) => props.disabled && disabledStyledButton};
  
  @media screen and (max-width: 991px) {    
    display: flex;
  }  
  
  @media screen and (max-width: 479px) {    
    align-self: flex-start;
  }
`;

const disabledStyledButton = css`
  opacity: 0.5;
  pointer-events: none;
`;
