import React from 'react';
import styled from 'styled-components';


export function BoldText({children}) {
  return (
    <StyledBoldText>
      {children}
    </StyledBoldText>
  )
}

const StyledBoldText = styled.strong`  
  font-family: 'Codecpro ultra', sans-serif;
  text-transform: uppercase;
`;