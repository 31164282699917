import React, {
  useEffect, useState, useRef, Fragment, useContext
} from 'react';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import Grid from './Grid';
import Account from './Account/Account';
import {getData, getProductBySku, checkIfInCart} from './tools/getData';
import ScrollToTop from './ScrollToTop'

import Pop from './Pop';
import {Cart} from './Cart';
import {Header} from './Header';
import {Footer} from './Team17/Footer';
import {Subscribe} from './SubscribeToNews';
import GameBanner from './GameBanner';
import {Preloader} from './Preloader';
import {CookieBanner} from './CookieBanner';
import {MessageHandler} from './MessageHandler';
import {StoreContext} from './StoreContext';
import {sendClick} from '../helpers/analytics';
import {LogInWidget} from './LogInWidget';
import {News} from "./News/News";
import {NewsPopup} from "./News/NewsPopup";

export default function GameStore() {
  const {
    skus, bundles, products, blocks, routes, tags,
    cartShown, setCartShown, p2pdata, addItemToCart, cart, showPreloader
  } = useContext(StoreContext);

  const bodyRef = useRef();

  const sortBlocks = (arr) => {
    arr = arr.sort((a, b) => {
      a = getData(blocks, a);
      b = getData(blocks, b);
      return parseFloat(a.order) - parseFloat(b.order);
    });
    return arr || [];
  };

  const filterRoutes = (routes) => {
    // let r = routes.filter(oneRoute => oneRoute['fields']['URL'] !== '/product')
    const r = routes.filter((oneRoute) => oneRoute.fields.URL !== '/product');
    return r;
    // return routes
  };

  const [cartBodyScroll, setCartBodyScroll] = useState(0);

  useEffect(() => {
    if (cartShown) {
      const h = bodyRef.current.getBoundingClientRect().top * -1;
      setCartBodyScroll(h);
      bodyRef.current.style.overflow = 'hidden';
      bodyRef.current.style.height = '100vh';
      bodyRef.current.scrollTo({ top: h });
    } else {
      bodyRef.current.style.overflow = 'inherit';
      bodyRef.current.style.height = 'auto';
      window.scrollTo({ top: cartBodyScroll });
      setCartBodyScroll(0);
    }
  }, [cartShown]);


  const addGameFromLanding = (sku) => {
    const product = getProductBySku(skus, sku);
    if (!product) {
      return;
    }
    if (!checkIfInCart(cart.items, product.fields.unit_items[0].sku)) {
      addItemToCart(product.fields.unit_items[0].sku);
      sendClick('sb-team17_store_add-item', sku, { source: 'landing' });
    }
    setCartShown(true);
    sendClick('sb-team17_cart_show', 'item');
  };

  return (
    <div ref={bodyRef}>
      {(!p2pdata || showPreloader) && <Preloader />}
      <Router>
        <ScrollToTop/>
        <LogInWidget/>
        {p2pdata && <CookieBanner/>}
        <Header/>
        <MessageHandler handleMessage={addGameFromLanding}/>
        {tags
        && blocks
        && products
        && skus
        && routes
        && p2pdata
        && bundles && (
          <>
            <div
              className={`b catalogue ${
                cartShown ? 'catalogue--cartshown' : ''
              }`}
            >
              <Switch>
                <Route path="/account">
                  <Account/>
                </Route>

                {filterRoutes(routes).map((oneRouteData, ind) => {
                  const oneRoute = {...oneRouteData.fields};

                  if (Object.keys(oneRoute).length) {
                    return (
                      <Route
                        exact
                        path={`${oneRoute.URL}${
                          oneRoute.keyId ? oneRoute.keyId : ''
                        }`}
                        key={`routes_${ind}`}
                        render={({history, match, location}) => (
                          <>
                            {sortBlocks(oneRoute.blocks).map(
                              (oneBlockId, ind) => {
                                const oneBlock = getData(
                                  blocks,
                                  oneBlockId
                                );
                                const filter_key = oneBlock.filter_key
                                  ? oneBlock.filter_key[0]
                                  : null;

                                const fv = oneBlock.filter_val
                                  ? oneBlock.filter_val[0]
                                  : null;
                                const a = fv
                                  ? getData(tags, fv, 'id')
                                  : null;
                                const filter_val = match.params.id
                                  ? match.params.id
                                  : a;

                                if (oneBlock.active) {
                                  return (
                                    <Fragment key={`grid_${oneBlockId}`}>
                                      {ind === 1 && (
                                        <GameBanner games={p2pdata}/>
                                      )}
                                      <Grid
                                        key={`grid_${ind}`}
                                        route={oneRouteData}
                                        configurable={
                                          oneRoute.configurable
                                        }
                                        fixed_products={oneBlock.fixed_products}
                                        blockId={oneBlockId}
                                        {...oneBlock}
                                        filter_key={filter_key}
                                        filter_val={filter_val}
                                      />
                                    </Fragment>
                                  );
                                }
                                return null;
                              }
                            )}
                          </>
                        )}
                      />
                    );
                  }
                  return (
                    <Grid
                      configurable={oneRoute.configurable}
                    />
                  );
                })}
              </Switch>

              <Route exact path="/">
                <News/>
              </Route>

              <Subscribe/>
              <Footer/>
            </div>

            <Pop/>
          </>
        )}

        {tags && products && skus && p2pdata && bundles && (
          <>
            <Cart setCartShown={setCartShown} cartShown={cartShown}/>
          </>
        )}
      </Router>
    </div>
  );
}
