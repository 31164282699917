import React, {
  useContext, Fragment, useState, useEffect
} from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { StoreContext } from './StoreContext';
import { filterTags, filterProducts, getData } from './tools/getData';
import { Svg } from './Icons/Svg';
import { icons } from './Icons/icons';
import { Drms } from './Drms';

export function Tags(props) {
  const {

    disabled = true,
    type = 'tag',
    className = 'c_tags',
    active,
    title,
    productId,
    what = 'genre',
    route = '/',
    size,
    sku,
    color,
    all,
    textSearch
  } = { ...props };
  const context = useContext(StoreContext);

  let filteredTags = filterTags(context.tags, what, productId, null, context.products, textSearch);

  const tagsQuantity = (genreName) => {
    const ret = filterProducts(
      context.products,
      context.skus,
      context.tags,
      'genre',
      genreName,
      textSearch
    );

    function checkIfNameIncludes(productId, textSearch, arrProducts, arrSkus) {
      if (!textSearch) return true;
      const prodData = getData(arrProducts, productId);

      const skuData = getData(arrSkus, prodData.ref_sku[0]);
      const prodName = skuData.name;

      return prodName.includes(textSearch);
    }

    ret.filter((one) => checkIfNameIncludes(
      one.id,
      textSearch,
      context.products,
      context.skus,
    ));

    return ret.length;
  };

  filteredTags = filteredTags.sort(
    (oneTag, oneTag2) => tagsQuantity(oneTag2.fields.id) - tagsQuantity(oneTag.fields.id)
  );

  if (filteredTags.length || what === 'platform') {
    return (
      <>

        {/* <div className={className}> */}

        {Boolean(title) && (
        <span>
          {title}
          :&nbsp;&nbsp;
        </span>
        )}

        {Boolean(all) && (
        <Tag
          key={`_${'all'}`}
              // quantity = {tagsQuantity()}
          disabled={false}
          color={color}
          active={active}
          id=""
          title={all}
          url=""
          route={route}
          type={type}
          what=""
          size={size}
          textSearch={textSearch}
        />
        )}

        {what !== 'platform' && filteredTags.map((oneTag, ind) => {
          oneTag = oneTag.fields;
          const url = oneTag.id;
          // let title = getData(filteredTags, oneG, 'title');
          const { title } = oneTag;
          if (url && tagsQuantity(oneTag.id)) {
            return (
              <Tag
                key={`_${ind}`}
              // quantity = {tagsQuantity(oneTag['id'])}

                disabled={disabled}
                color={color}
                active={active}
                id={url}
                title={title}
                url={url}
                route={route}
                type={type}
                what={what}
                size={size}
                textSearch={textSearch}
              />
            );
          }

          return null;
        })}

        {what === 'platform' && (
        <Drms
          productId={productId}
          sku={sku}
        />
        )}

        {/* </div> */}
      </>
    );
  }

  return null;
}

export function Tag(props) {
  const {
    id,

    route = '/',
    title,
    url,
    type,
    active,
    what,
    size = 28,
    color = '#FFF',
    disabled,
    all,
    textSearch
  } = { ...props };

  const context = useContext(StoreContext);

  const tagsQuantity = (genreName, textSearch) => {
    const ret = filterProducts(
      context.products,
      context.skus,
      context.tags,
      'genre',
      genreName,
      textSearch
    );

    function checkIfNameIncludes(productId, textSearch, arrProducts, arrSkus) {
      if (!textSearch) return true;
      const prodData = getData(arrProducts, productId);

      const skuData = getData(arrSkus, prodData.ref_sku[0]);
      const prodName = skuData.name;

      return prodName.includes(textSearch);
    }

    ret.filter((one) => checkIfNameIncludes(
      one.id,
      textSearch,
      context.products,
      context.skus,
    ));

    return ret.length;
  };

  const [quantity, setQuantity] = useState(
    tagsQuantity(url), textSearch
  );

  useEffect(() => {
    setQuantity(tagsQuantity(url), textSearch);
  }, [textSearch]);

  if (type === 'tag' && what !== 'platform') {
    return (
      <CSSdisabled disabled={disabled}>
        <Link
          title={title}
          url={url}
          className={`s_name_sub_tag ${active === id ? 's_name_sub_tag--active' : ''}`}
          to={`${route}/${url}`}
        >
          {title}
        </Link>
      </CSSdisabled>
    );
  }

  if (type === 'tag' && what === 'platform') {
    return (
      <CSSdisabled disabled={disabled}>
        <Link
          title={title}
          url={url}
          className={`s_name_tag ${active === id ? 's_name_tag--active' : ''}`}
          style={{ position: 'relative', padding: '0px', bottom: '-0.1em' }}
          to={`${route}/${url}`}
        >
          <Svg width={size} height={size} color={color}>{icons[`platform_${id}`]}</Svg>
          {/* {id} */}

        </Link>
      </CSSdisabled>
    );
  }

  if (type === 'menu') {
    return (
      <CSSdisabled disabled={disabled}>
        <Link
          style={{ textDecoration: 'none' }}
          className={`m_li ${active === id ? 'active' : ''}`}
          title={title}
          url={url}
          to={`/${route}/${url}`}
        >
          {title}
        </Link>
      </CSSdisabled>
    );
  }

  if (type === 'filters') {
    return (
      <option value={url}>
        {title}
        {' '}
        <div>-</div>
        <div>{quantity}</div>
        {/* <Link
          style={{textDecoration:'none'}}
          className={`m_li ${active === id ? 'active' : ''}`}
          title={title}
          url={url}
          to={`/${route}/${url}`}>
          {title}
        </Link> */}
      </option>
    );
  }
}

const CSSdisabled = styled.span`
  ${({ disabled }) => disabled && css`pointer-events: none !important;`};
`;
