import {
  IS_DEV,
  LOGIN_API,
  LOGIN_CALLBACK_URL,
  LOGIN_CALLBACK_URL_LOCALE
} from '../config';


const getHeaders = (token) => {
  const headers = {
    'Authorization': `Bearer ${token}`,
    'Content-Type': 'application/json',
  };
  return headers;
};

export const getUserProfile = (token) => {
  return fetch(
    `${LOGIN_API}/users/me`, {
      method: 'GET',
      headers: getHeaders(token)
    }
  )
    .then((response) => {
       return response.json()
    });

}

export const getSocProvidersUrl = (token, partner) => {
  return fetch(
    `${LOGIN_API}/users/me/social_providers/${partner}/login_url?login_url=${IS_DEV ? LOGIN_CALLBACK_URL_LOCALE : LOGIN_CALLBACK_URL}`, {
      method: 'GET',
      headers: getHeaders(token)
    }
  )
    .then((response) => {
      return response.json()
    });
}

export const deleteSocProvidersUrl = (token, partner) => {
  return fetch(
    `${LOGIN_API}/users/me/social_providers/${partner}?login_url=${IS_DEV ? LOGIN_CALLBACK_URL_LOCALE : LOGIN_CALLBACK_URL}`, {
      method: 'DELETE',
      headers: getHeaders(token)
    }
  )
    .then((response) => {
      return response
    });
}

export const getSocProviders = (token) => {
  return fetch(
    `${LOGIN_API}/users/me/social_providers`, {
      method: 'GET',
      headers: getHeaders(token)
    }
  )
    .then((response) => {
      return response.json()
    });

}