import React from 'react';
import styled from 'styled-components';


export function Button(props) {

  return (
    <StyledBut {...props}>
      {props.title}
      {props.children}
    </StyledBut>
  );
}

const StyledBut = styled.div`
  position: relative;
  display: flex;
  overflow: visible;
  min-height: 45px;
  min-width: 45px;
  justify-content: center;
  align-items: center;
  flex: 0 auto;
  border-radius: 44px;
  background-color: hsla(0, 0%, 100%, 0.24);
  background-image: none;
  box-shadow: 0 4px 10px -4px rgba(66, 12, 105, 0);
  transition: transform 200ms ease, color 200ms ease, border-color 200ms ease, background-color 200ms ease;
  color: #fff;
  line-height: 0;
  font-weight: 700;
  text-align: center;
  letter-spacing: 0.8px;
  text-transform: uppercase;
  cursor: ${(props) => props.disabled ? "not-allowed" : "pointer"};
  padding: ${(props) => props.icon ? "0.7em 4em 0.7em 2em" : "0.7em 2em 0.7em 2em"};
  margin: 0;
  text-decoration: none;
  opacity: ${(props) => props.disabled ? "0.5" : "1"};
  pointer-events: ${(props) => props.disabled ? "none" : "auto"};
  
  &:hover {
    background-color: ${(props) => props.color || "#58c15b" };
  }

  @media screen and (max-width: 767px) {
    min-width: 36px;
    margin-right: 1em;
    margin-left: 1em;
    width: 100%;
    min-height: 45px;
    font-size: 14px;
    padding: ${(props) => props.icon ? "0.7em 4em 0.7em 1em;" : "0.7em 1em 0.7em 1em"};
  }
`;