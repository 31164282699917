import React from 'react';
import { useLocation } from "react-router-dom";
import './styles.css';

export function Subscribe() {
  return (
    useLocation().pathname !== "/account" &&
    (<section
      id="newsletter"
      className="global-newsletter vue-app mb-12 xl:mb-16"
    >
      <div className="container">
        <div className="2xl:w-10/12 mx-auto">
          <span>
            <form
              action="https://team17.us10.list-manage.com/subscribe/post?u=1cd6ce10800bb7150fd3cc2c2&amp;id=a334ce9dc3"
              method="post"
              id="mc-embedded-subscribe-form"
              name="mc-embedded-subscribe-form"
              target="_blank"
              data-animate="fade-up"
              className="validate global-newsletter__form rounded-20 overflow-hidden relative px-6 py-10 lg:p-10 2xl:py-14 2xl:px-12 bg-royal animate"
            >
              <div className="row xl:flex items-center relative z-10">
                <div className="column xl:w-6/12 xl-max:mb-4">
                  <div
                    data-animate="splitting-group"
                    className="wysiwyg items splitting animate"
                    style={{ '--item-total': 1 }}
                  >
                    <h3
                      className="words chars splitting"
                      style={{
                        '--item-index': 0,
                        '--word-total': 3,
                        '--char-total': 16
                      }}
                    >
                      <span
                        className="word"
                        data-word="Newsletter"
                        style={{ '--word-index': 0 }}
                      >
                        <span
                          className="char"
                          data-char="N"
                          style={{ '--char-index': 0 }}
                        >
                          N
                        </span>
                        <span
                          className="char"
                          data-char="e"
                          style={{ '--char-index': 1 }}
                        >
                          e
                        </span>
                        <span
                          className="char"
                          data-char="w"
                          style={{ '--char-index': 2 }}
                        >
                          w
                        </span>
                        <span
                          className="char"
                          data-char="s"
                          style={{ '--char-index': 3 }}
                        >
                          s
                        </span>
                        <span
                          className="char"
                          data-char="l"
                          style={{ '--char-index': 4 }}
                        >
                          l
                        </span>
                        <span
                          className="char"
                          data-char="e"
                          style={{ '--char-index': 5 }}
                        >
                          e
                        </span>
                        <span
                          className="char"
                          data-char="t"
                          style={{ '--char-index': 6 }}
                        >
                          t
                        </span>
                        <span
                          className="char"
                          data-char="t"
                          style={{ '--char-index': 7 }}
                        >
                          t
                        </span>
                        <span
                          className="char"
                          data-char="e"
                          style={{ '--char-index': 8 }}
                        >
                          e
                        </span>
                        <span
                          className="char"
                          data-char="r"
                          style={{ '--char-index': 9 }}
                        >
                          r
                        </span>
                      </span>
                      <span className="whitespace"> </span>
                      <span
                        className="word"
                        data-word="sign"
                        style={{ '--word-index': 1 }}
                      >
                        <span
                          className="char"
                          data-char="s"
                          style={{ '--char-index': 10 }}
                        >
                          s
                        </span>
                        <span
                          className="char"
                          data-char="i"
                          style={{ '--char-index': 11 }}
                        >
                          i
                        </span>
                        <span
                          className="char"
                          data-char="g"
                          style={{ '--char-index': 12 }}
                        >
                          g
                        </span>
                        <span
                          className="char"
                          data-char="n"
                          style={{ '--char-index': 13 }}
                        >
                          n
                        </span>
                      </span>
                      <span className="whitespace"> </span>
                      <span
                        className="word"
                        data-word="up"
                        style={{ '--word-index': 2 }}
                      >
                        <span
                          className="char"
                          data-char="u"
                          style={{ '--char-index': 14 }}
                        >
                          u
                        </span>
                        <span
                          className="char"
                          data-char="p"
                          style={{ '--char-index': 15 }}
                        >
                          p
                        </span>
                      </span>
                    </h3>
                  </div>
                </div>
                <div className="column xl:w-6/12">
                  <div className="flex flex-wrap -mx-1">
                    <div className="mb-3 w-full lg:w-6/12 px-1">
                      <span className="block">
                        <label
                          htmlFor="newsletter_first_name"
                          className="form-label mb-1"
                        >
                          First Name
                        </label>
                        <input
                          name="MERGE1"
                          id="mce-MMERGE1-0"
                          placeholder="First Name"
                          type="text"
                          maxLength="255"
                          className="form-input"
                        />
                      </span>
                    </div>
                    <div className="mb-3 w-full lg:w-6/12 px-1">
                      <span className="block">
                        <label
                          htmlFor="newsletter_last_name"
                          className="form-label mb-1"
                        >
                          Last Name
                        </label>
                        <input
                          name="MERGE2"
                          id="mce-MMERGE2-0"
                          placeholder="Last Name"
                          type="text"
                          maxLength="255"
                          className="form-input"
                        />
                      </span>
                    </div>
                    <div className="mb-4 w-full px-1">
                      <span className="block">
                        <label
                          htmlFor="newsletter_email"
                          className="form-label mb-1"
                        >
                          Email Address
                        </label>
                        <input
                          placeholder="Email Address"
                          type="email"
                          maxLength="255"
                          className="form-input"
                          name="EMAIL"
                          id="mce-EMAIL"
                          required
                        />
                      </span>
                    </div>
                    <div className="mb-4 w-full px-1">
                      <span className="relative block">
                        <div className="flex">
                          <input
                            type="checkbox"
                            name="MMERGE5"
                            id="mce-MMERGE5-0"
                            value="I confirm that I have read and agree to the Privacy Policy"
                            className="form-checkbox text-white"
                            required
                          />
                          <label
                            htmlFor="newsletter_gdpr"
                            className="copy-s text-white ml-2"
                            style={{ marginTop: '2px' }}
                          >
                            I confirm that I have read and agree to the&nbsp;
                            <a
                              className="underline text-forest"
                              aria-label="Team17 Privacy Policy"
                              rel="noopener noreferrer"
                              target="_blank"
                              href="https://www.team17.com/privacy-policy/"
                            >
                              Privacy Policy
                            </a>
                            .
                          </label>
                        </div>
                      </span>
                    </div>
                    <div className="w-full px-1 flex flex-col items-end">
                      <button
                        type="submit"
                        className="btn btn--rounded btn--dark btn--tangerine"
                        name="subscribe"
                        id="mc-embedded-subscribe"
                      >
                        <span className="btn__label">Sign up</span>
                        <span className="btn__iconWrapper">
                          <span className="btn__icon">
                            <span
                              className="inline-svg"
                              style={{ paddingTop: '100%' }}
                            >
                              <svg
                                viewBox="0 0 21 20"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g
                                  fill="none"
                                  fillRule="evenodd"
                                  stroke="currentColor"
                                  strokeWidth="3"
                                >
                                  <path
                                    strokeLinejoin="round"
                                    d="M9.903 1l9 9-9 9"
                                  />
                                  <path d="M.903 10.109L18.793 10" />
                                </g>
                              </svg>
                            </span>
                          </span>
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <img
                src="https://cdn3.xsolla.com/files/uploaded/67256/aaf2dd30328a7c4077eccc5d6289909f.jpg"
                role="presentation"
                className="object-cover-absolute"
              />
            </form>
          </span>
        </div>
      </div>
    </section>)
  );
}
