import React, {
  useState, useContext, useEffect
} from 'react';
import { StoreContext } from './StoreContext';
import { CartBody } from './CartBody';
import { CartButton } from './CartButton';
import { sendClick } from '../helpers/analytics';
import {useLocation} from "react-router-dom";
import {parseJwt} from "../helpers/helpers";

export function Cart() {
  const context = useContext(StoreContext);
  const [cartAmount, setCartAmount] = useState(0);

  useEffect(() => {
    const amount = context.cart.items.filter((item) => !item.is_free).length;
    if (amount !== cartAmount) {
      setCartAmount(amount);
    }
  }, [context.cart.items, cartAmount]);

  const handlePop = () => {
    context.setCartShown(true);
    sendClick('sb-team17_cart_show', 'button');
  }

  const {showCartButton, setShowCartButton} = useContext(StoreContext);
  let location = useLocation();

  useEffect(() => {
    location.pathname !== "/account" && setShowCartButton(true)
  },[showCartButton, setShowCartButton, location]);

  const {loginToken} = useContext(StoreContext);
  const [email, setEmail] = useState('');

  useEffect(() => {
    if (loginToken) {
      setEmail(parseJwt(loginToken).email)
    }
  }, [setEmail, loginToken]);

  return (
    context.showCartButton && (
      <CartButton
        cartAmount={cartAmount}
        handlePop={handlePop}>
        <CartBody
          shown={context.cartShown}
          {...context}
          handleClose={() => {
            context.setCartShown(false);
          }}
          cartAmount={cartAmount}
        />
    </CartButton>
    )
  );
}
