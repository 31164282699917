import React, { useState, useContext, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import Product from './Product';
import { StoreContext } from './StoreContext';
import { getProductBySku, getData } from './tools/getData';
import { Grid } from './Grid';
import { ButtonCart } from './Button';
import {AgeGatePopup} from './AgeGatePopup/AgeGatePopup';

export function Pop(props) {
  const {
    history,
    location,
    children,
    handle = () => void 0,
  } = { ...props };

  const [popData, setPopData] = useState();

  const context = useContext(StoreContext);

  function readyMe() {
    return setTimeout(() => {
      let vh = window.innerHeight * 0.01;
      document.getElementsByTagName('body')[0].style.setProperty('--vh', `${vh}px`);
      window.addEventListener('resize', () => {
        vh = window.innerHeight * 0.01;
        document.getElementsByTagName('body')[0].style.setProperty('--vh', `${vh}px`);
      });
    }, 1000);
  }

  useEffect(() => {
    if (context.skus && context.products && location.pathname.includes('product')) {
      document.body.style.overflowY = 'hidden';

      const prodSku = location.pathname.split('/product/')[1];
      const oneProduct = getProductBySku(context.products, prodSku);

      context.setCartShown(false);

      if (oneProduct) {
        const oneSku = getData(context.skus, oneProduct.fields.ref_sku[0]);
        setPopData({
          ...oneProduct.fields,
          template: 'small',
          itemType: 'game',
          sku: oneSku.sku,
          ...oneSku,
          productId: oneProduct.id,
          landing_page_url: oneSku.landing_page_url ? oneSku.landing_page_url : false,
          ageGate:context.ageGateTable
          // landing_page_url: oneSku['landing_page_url'] ? oneSku['landing_page_url'] : `https://${oneSku['sku']}.xsollasitebuilder.com`,
        });
      }
    }

    return () => {
      document.body.style.overflowY = 'inherit';
    };
  }, [location]);

  if (window.location.pathname.includes('product') && popData) {
    return (
      <>
        {context.ageGateTable && (<AgeGatePopup gameAgeGate={context.ageGateTable.get(popData.landing_page_url)} page={popData.landing_page_url} >
          <div className="p0 shown">

        <div data-a="target0" className="p0 shown">
          <div data-a="trigger" className="pz shown" onClick={() => handle()} />
          <div data-a="target" className="pz shown">

            <div className="px0">
              <div data-a="trigger" className="px">
                <div className="px_t17" />
                <div
                  className="px_back"
                  onClick={() => {
                    setPopData();
                    // history.goBack();
                    history.push('/');
                  }}
                >
                  <div className="w-embed">
                    <svg width={18} height={18} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M11.5364 3.5364C11.8879 3.18492 11.8879 2.61508 11.5364 2.2636C11.185 1.91213 10.6151 1.91213 10.2637 2.2636L4.26365 8.2636C3.91218 8.61508 3.91218 9.18492 4.26365 9.5364L10.2637 15.5364C10.6151 15.8879 11.185 15.8879 11.5364 15.5364C11.8879 15.1849 11.8879 14.6151 11.5364 14.2636L6.17284 8.9L11.5364 3.5364Z" fill="white" />
                    </svg>
                  </div>
                </div>
              </div>
              {popData.active && (
                <div className="s_cta s_cta--pop">
                  <ButtonCart itemType="game" {...popData} />
                </div>
              )}
            </div>

            {context.cartShown && children}

            <div className="pb_2cols">
              <div className="pbifr0">
                <div className="pbifr_border" />

                {!!popData.landing_page_url && (
                  <div className="pbifr w-embed w-iframe">
                    <iframe className="pbifr0 w-embed w-iframe" src={popData.landing_page_url} onLoad={() => readyMe()} />
                  </div>
                )}

                {!popData.landing_page_url && (
                <div style={{
                  display: 'flex', height: '100%', placeContent: 'center', alignItems: 'center'
                }}
                >
                  No page created yet
                  <br />
                  <br />
                  <br />
                </div>
                )}

              </div>

              {/* <PopRight {...popData} /> */}
            </div>
          </div>
        </div>
      </div>
        </AgeGatePopup>
        )}
      </>
    );
  }
  return null;
}

export function PopRight(props) {
  const context = useContext(StoreContext);

  return (
    <div className="pb_2colr_right">

      <Product
        {...props}
        template="good"
      />

      <div className="pb_also0">
        <div className="pb_also_title">See also</div>
        <Grid
          products={context.products}
          template="micro"
          limit={2}
        />
      </div>

    </div>
  );
}

export function PopBundle() {
  return (
    <div className="item_inner">
      <div className="c_pic0 c_pic0--good">
        <div className="pb_also_bundle0">
          <div className="pb_bundle_1"><div className="c_pic temp-oc" /></div>
          <div className="pb_bundle_1"><div className="c_pic temp-ag" /></div>
          <div className="pb_bundle_1"><div className="c_pic temp-raj" /></div>
          <div className="pb_bundle_1"><div className="c_pic temp-atom" /></div>
        </div>
      </div>
      <div className="c_txt c_txt--good">
        <div className="c_main c_main--good">
          <div className="s_cta">
            <div className="but">
              <div>$18.99 BUY NoW</div>
              <div className="but_price but_price--old">$28.99</div>
            </div>
            <div className="but but--k">
              <div className="w-embed">
                <svg width={18} height={14} viewBox="0 0 18 14" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                  <title>Cart</title>
                  <desc>Created using Figma</desc>
                  <g id="Canvas" transform="translate(2183 241)">
                    <g id="Cart">
                      <use xlinkHref="#path0_fill" transform="translate(-2183 -241)" fill="#FFFFFF" />
                    </g>
                  </g>
                  <defs>
                    <path id="path0_fill" fillRule="evenodd" d="M 15.8943 1.86667L 15.0984 3.93654C 15.0879 3.96393 15.0759 3.99048 15.063 4.01614L 13.2 9.584C 13.0635 9.99178 12.6816 10.2667 12.2517 10.2667L 3.85327 10.2667C 3.42334 10.2667 3.0415 9.99178 2.90503 9.584L 0.947266 3.73334L 0.600098 3.73334C 0.268555 3.73334 0 3.46471 0 3.13333L 0 2.46667C 0 2.1353 0.268555 1.86667 0.600098 1.86667L 14.073 1.86667L 14.572 0.568527C 14.6255 0.429626 14.7114 0.312469 14.8181 0.222397C 14.9282 0.0867157 15.0959 0 15.2844 0L 17.4001 0C 17.7314 0 18 0.268631 18 0.600006L 18 1.26666C 18 1.59804 17.7314 1.86667 17.4001 1.86667L 15.8943 1.86667ZM 12.5881 3.73334L 3.51685 3.73334C 3.18115 3.73334 2.94092 4.05775 3.03857 4.37898L 4.04736 7.69131C 4.17554 8.1123 4.56372 8.40001 5.00391 8.40001L 11.1013 8.40001C 11.5413 8.40001 11.9297 8.1123 12.0579 7.69131L 13.0664 4.37898C 13.1643 4.05775 12.9241 3.73334 12.5881 3.73334ZM 2.84204 12.6C 2.84204 11.8268 3.46875 11.2 4.24194 11.2L 4.28418 11.2C 5.05737 11.2 5.68408 11.8268 5.68408 12.6C 5.68408 13.3732 5.05737 14 4.28418 14L 4.24194 14C 3.46875 14 2.84204 13.3732 2.84204 12.6ZM 10.4211 12.6C 10.4211 11.8268 11.0479 11.2 11.821 11.2L 11.8633 11.2C 12.6365 11.2 13.2632 11.8268 13.2632 12.6C 13.2632 13.3732 12.6365 14 11.8633 14L 11.821 14C 11.0479 14 10.4211 13.3732 10.4211 12.6Z" />
                  </defs>
                </svg>
              </div>
            </div>
          </div>
          <div className="s_name_h1 s_name_h1--bundle">
            Overcooked PS4 Bundle
            <br />
          </div>
        </div>
        <div className="s_name_h2">
          <div className="s_name_sub">
            Platform: &nbsp;
            <span className="s_name_sub_tag">PS4</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(Pop);
