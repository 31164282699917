import React from 'react';
import currencyFormat from 'currency-format';

export const checkIfHasDiscount = (price) => (
  price && price.amount !== price.amount_without_discount
);

const hideUSLettersInDollarCurrency = (currency) => (currency === 'USD' ? 'USN' : currency);

export const getPrice = (unit_items) => (unit_items ? unit_items[0].price : null);

export const getAmount = (unit_items) => {
  const price = getPrice(unit_items);
  return price ? price.amount : null;
};

export const getAmountWithoutDiscount = (unit_items) => {
  const price = getPrice(unit_items);
  if (!price) {
    return null;
  }

  return price.amount !== price.amount_without_discount
    ? price.amount_without_discount
    : null;
};

export const getCurrencyIso = (unit_items) => {
  const price = getPrice(unit_items);
  return price ? price.currency : null;
};

export const formatCurrencyIso = (currencyIso) => {
  const currency = hideUSLettersInDollarCurrency(currencyIso);
  return currency ? (
    <>
      {currencyFormat[currency].symbol.grapheme}
      &thinsp;
    </>
  ) : null
};

export const formatAmount = (prc) => {
  if (!prc) return 0;
  if (prc.toString().split('.').length === 1) return prc;
  return prc.toString().search('.') !== -1 ? <>{parseFloat(prc).toFixed(2)}</> : <>{prc}</>;
};

export const checkPreOrder = (unit_items = []) => Boolean(
  unit_items.filter((item) => item.is_pre_order).length
);

export const checkIfHasKeys = (unit_items = []) => Boolean(unit_items.filter((item) => item.has_keys).length);

export const checkStock = (unit_items = []) => Boolean(checkPreOrder(unit_items) || checkIfHasKeys(unit_items));

export const checkBundlePrice = (type, bundle_price) => {
  return type === 'bundle' ? Boolean(bundle_price) : true;
}
