import React, {useContext, useState} from 'react';
import {NavLink} from 'react-router-dom';
import styled from 'styled-components';
import {StoreContext} from './StoreContext';
import {Glitch} from './Glitch';
import {useOnClickOutside} from "../hooks/useOnClickOutside";
import {XSOLLA_LOGIN_TOKEN_SB} from '../config'

export function DropDown({caption, items, on: initialOn = false}) {
  let [on, setOn] = useState(initialOn)
  const {syncCartsLogOut} = useContext(StoreContext);

  function toggle() {
    setOn(!on)
  }

  function close() {
    setOn(false)
  }

  async function LogOut() {
    await syncCartsLogOut()

    document.cookie = `${XSOLLA_LOGIN_TOKEN_SB}=null;`
    window.location.assign("/")
  }

  let containerRef = useOnClickOutside(close)

  return (
    <StyledUserAccount ref={containerRef} className="w-dropdown">
      <div onClick={toggle} className="dropdown-toggle w-dropdown-toggle" aria-controls="w-dropdown-list-0"
           aria-haspopup="menu" aria-expanded="false" role="button" tabIndex="0">
        {caption}
      </div>
      <nav className={on ? "dropdown-list w-dropdown-list w--open" : "dropdown-list w-dropdown-list"}>
        {items.map(function (item, i) {
          return (
            item.link ?
              <NavLink to={item.link} key={i} onClick={toggle} className="dropdown-link w-inline-block">
                <Glitch text={"Settings"}/>
              </NavLink>
              :
              <div className="dropdown-link" key={i} onClick={LogOut}>
                <Glitch text={item.text}/>
              </div>
          )
        })}
      </nav>
    </StyledUserAccount>
  );
}

const StyledUserAccount = styled.div`
  display: flex;
  align-items: center;
  
  margin-left: 30px;
  min-width: 130px;
  min-height: 24px;
  
  @media screen and (min-width: 1920px) {
    order: 1;
  }
  
  @media screen and (max-width: 767px) {        
    margin-right: 0px;
    margin-left: 0px;
    order: 1;
  }
  
  @media screen and (max-width: 479px) {
        display: flex;
        width: 100%;
        margin-top: 10px;
        justify-content: center;
        align-items: center;
    }
`;
