import React from 'react';
import { useResizeDetector } from 'react-resize-detector';
import './styles.css';

const DESKTOP_IMAGE_SRC = "https://cdn3.xsolla.com/files/uploaded/67256/b5336824c798c6233c086eb26e22294e.png";
const MOBILE_IMAGE_SRC = "https://cdn3.xsolla.com/files/uploaded/67256/6a463cfb07809d2160b45710df508b3b.png";

const GameBanner = () => {
  const { width, ref } = useResizeDetector({
    handleHeight: false,
    refreshMode: 'throttle'
  });
  const src = width >= 399 ? DESKTOP_IMAGE_SRC : MOBILE_IMAGE_SRC;

  return (
    <section
      className="game-banner"
      ref={ref}
    >
      <img
        className="game-banner__image"
        src={src}
        alt="store-items"
      />
    </section>
  );
};

export default GameBanner;
