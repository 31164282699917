import React from 'react';
import styled from 'styled-components';
import {AccountInfo} from './AccountInfo'
import {AccountRedeemCoupon} from './AccountRedeemCoupon'
import {AccountSocMedia} from './AccountSocMedia'
import {AccountPaymentInformation} from './AccountPaymentInformation'
import {AccountSupport} from './AccountSupport'
import {AccountDescription} from "./AccountDescription";

export function AccountSettingsForm() {

  return (
      <StyledXAccountSettings>
        <StyledAccountSettingsH1>Account settings</StyledAccountSettingsH1>
        <StyledAccountSettingsDesc>
          <AccountDescription>Manage the account details you&#x27;ve shared with Team17 including your name, contact info and more.</AccountDescription>
        </StyledAccountSettingsDesc>
        <AccountInfo/>
        <AccountRedeemCoupon/>
        <AccountSocMedia/>
        <AccountPaymentInformation/>
        <AccountSupport/>
      </StyledXAccountSettings>
  );
}

const StyledXAccountSettings = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;

  @media screen and (max-width: 991px) {
    display: grid;
    margin-bottom: 2em;
    flex-direction: column;
    grid-auto-columns: 1fr;
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
  }
  
  @media screen and (max-width: 479px){
    display: block;
  }
`;

const StyledAccountSettingsH1 = styled.div`
  width: 100%;
  margin-bottom: 10px;
  padding-top: 10px;
  padding-right: 100px;
  font-family: 'Codecpro ultra', sans-serif;
  font-size: 3em;
  line-height: 1em;
  font-weight: 400;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  
  @media screen and (min-width: 1440px) {
    text-transform: uppercase;
  }
  
  @media screen and (max-width: 991px) {
    width: 100%;
    margin-bottom: 0px;
    font-size: 3.2em;
  }  
  
  @media screen and (max-width: 767px) {
    width: 100%;
    padding-top: 0px;
    padding-right: 220px;
  }
  
  @media screen and (max-width: 479px) {
    margin-bottom: 20px;
    padding-right: 100px;
    font-size: 2.6em;
  }
`;

const StyledAccountSettingsDesc = styled.div`
  margin-bottom: 4em;
    
  @media screen and (max-width: 991px) {
    margin-bottom: 10px;
  }  
`;
