import React, {
  useEffect, Fragment
} from 'react';

export const MessageHandler = ({ handleMessage }) => {
  useEffect(() => {
    const handleEvent = (event) => {
      const { data } = event;
      if (data && data.type === 'addGameToCart') {
        handleMessage(data.sku);
      }
    };

    window.addEventListener('message', handleEvent, false);

    return function cleanup() {
      window.removeEventListener('message', handleEvent);
    };
  });

  return <></>;
};
