import React, {
  Fragment, useEffect, useState, useContext
} from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

export function GridZeroState({ handleClear = () => void (0) }) {
  return (
    <>
      <div className="c_zero_state0">
        <div className="c_zero_state">
          <div className="c_zero_img">
            <img src="https://assets.website-files.com/5e73f39d8f7c0b9c03c51853/5f52cd8e6de4e1fd109a4b59_onionKing.png" loading="lazy" width={170} alt />
          </div>
          <div className="c_zero_text">
            <div>
              Nothing was found!
              <br />
            </div>
          </div>

          <div onClick={handleClear} className="but but--clearsearch"><div>Clear search</div></div>

        </div>
      </div>
    </>
  );
}
