import React from 'react';

import { ReactComponent as WarningIcon } from './Icons/warning.svg';

export function CartError({ errorMessage }) {
  return (
    <div className="k_cart_error">
      <WarningIcon className="k_cart_error-icon" />
      <span className="k_cart_error-text">{errorMessage}</span>
    </div>
  );
}
