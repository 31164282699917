import React, { useState, useEffect, useContext } from 'react';
import { StoreContext } from './StoreContext';
import { getCookie } from '../helpers/helpers';

import './cookie_banner.css';
import { ReactComponent as CrossIcon } from './Icons/cross.svg';
import {AGE} from "./Variable";

export const ACCEPT_COOKIES = 'AcceptCookies';
export const AGE_GATE = 'AgeGate';

export const environments = {
  isDev: window.location.href.indexOf('team17.com', 0) < 0,
  isProd: window.location.href.indexOf('team17.com', 0) >= 0
};

export function CookieBanner() {
  const context = useContext(StoreContext);
  const [showBanner, setShowBanner] = useState(true);

  useEffect(() => {
    if (getCookie(ACCEPT_COOKIES) == 'true' || getCookie(ACCEPT_COOKIES) == 'false') {
      setShowBanner(false);
    }
  }, []);

  const getUrlToCookie = () => {
    const url = new URL(window.location.href);
    const age = url.searchParams.get('age');
    environments.isProd
      ? document.cookie = `${ACCEPT_COOKIES}=true; domain=team17.com`
      : document.cookie = `${ACCEPT_COOKIES}=true;`;
    context.setGDPR(true);

    if (!age) return;
    environments.isProd
      ? document.cookie = `${AGE_GATE}=${age}; domain=team17.com`
      : document.cookie = `${AGE_GATE}=${age};`;
    context.setAgeGate(age);
    localStorage.setItem(AGE, age)
  };

  const handleAcceptCookie = () => {
    getUrlToCookie();
    setShowBanner(false);
  };

  const handleCloseCookie = () => {
    environments.isProd
      ? document.cookie = `${ACCEPT_COOKIES}=false; domain=team17.com`
      : document.cookie = `${ACCEPT_COOKIES}=false;`;
    setShowBanner(false);
  };

  return (
    <>
      <div className="background_cookie_banner" hidden={!showBanner} />
      <div className="cookie_banner" hidden={!showBanner}>
        <div className="cookie_banner__close" onClick={handleCloseCookie}>
          <CrossIcon />
        </div>
        <div className="cookie_banner__chef" />
        <div className="cookie_banner__header">we use cookies</div>
        <div className="cookie_banner__text">
          we use cookies on this website, see
          {' '}
          <a href="https://www.team17.com/legal/" target="_black">terms & conditions</a>
          {' '}
          or
          {' '}
          <a href="https://www.team17.com/privacy-policy/" target="_black">cookie policy</a>
          {' '}
          for more details.
        </div>
        <div className="cookie_banner__accept" onClick={handleAcceptCookie}>accept</div>
        <div className="cookie_banner__border" />
      </div>
    </>
  );
}
