import React, {useContext} from 'react';
import {DropDown} from './DropDown';
import {LogInButton} from './LogInButton';
import {StoreContext} from "./StoreContext";
import {UserImage} from "./UserImage";
import {Glitch} from "./Glitch";
import {Preloader} from "./Preloader";


export function LogIn() {
  const {loginToken} = useContext(StoreContext);
  const {userProfile} = useContext(StoreContext);
  const {jwtUser} = useContext(StoreContext);
  let name ="";

  if (jwtUser) {
    if (Boolean(jwtUser.email)) {
      name = jwtUser.email
    }
    else if (Boolean(jwtUser.nickname)) {
      name = jwtUser.nickname
    } else {
      name = jwtUser.name
    }
  }

  return (
    <>
      {loginToken ?
        userProfile ?
        <DropDown
          caption={<>
            {userProfile.picture && (<UserImage picture={userProfile.picture} />)}
            <Glitch text={name}/>
            <div className="icon-5 w-icon-dropdown-toggle"/>
          </>}
          items={[{text:"Settings", link:"/account"}, {text:"log out ➥"}]}
        >
        </DropDown> : <Preloader />
        :
        <LogInButton/>}
    </>
  );
}
