import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { CWidth } from "../Styles/CWidth";
import { CPaddings } from "../Styles/CPaddings";
import { TitleH2 } from "../Styles/TitleH2";
import { getNews } from "../../api/launcherApi";
import { NewsPopup } from "./NewsPopup";
import { ShowMoreButton } from "../Button/ShowMoreButton";
import { NewsCard } from "./NewsCard";
import { MAX_NUMBER_OF_NEWS_PER_PAGE, NUMBER_OF_NEWS_PER_REQUEST } from "../../config";

export function News() {
  const [newsData, setNewsData] = useState(false)
  const [showButton, setShowButton] = useState(true);
  const [newsOnPage, setNewsOnPage] = useState(NUMBER_OF_NEWS_PER_REQUEST);
  const [newsLength, setNewsLength] = useState(false);
  const [newsPopupData, setNewsPopupData] = useState(false);
  const newsRef = useRef(null)
  useEffect(() => {
    if (!newsData) {
      getNews(NUMBER_OF_NEWS_PER_REQUEST).then((data) => {
        setNewsLength(data.count);
        setNewsData(data);
      });
    }
  }, []);

  function showMore() {
    const counterNews = newsOnPage + NUMBER_OF_NEWS_PER_REQUEST;
    setNewsOnPage(counterNews);

    getNews(NUMBER_OF_NEWS_PER_REQUEST, newsOnPage).then((data) => {
      setNewsData([...newsData, ...data]);
    });

    if (newsData.length + NUMBER_OF_NEWS_PER_REQUEST >= newsLength || counterNews >= MAX_NUMBER_OF_NEWS_PER_PAGE) {
      setShowButton(false);
    }
  }

  function showLess() {
    setNewsData(newsData.slice(0, NUMBER_OF_NEWS_PER_REQUEST));
    setNewsOnPage(NUMBER_OF_NEWS_PER_REQUEST);
    setShowButton(true);
    newsRef.current.scrollIntoView()
  }
  function displayButton() {
    if (showButton) {
      return <ShowMoreButton title={"show more"} onClick={showMore} />;
    } else {
      return <ShowMoreButton title={"show less"} onClick={showLess} />;
    }
  }
  return (
    <>
      <StyledC ref={newsRef}>
        <CWidth>
          <CPaddings>
            <TitleH2 title={"news"} />
            <StyledListNews>
              {newsData && newsData.map((item) => {
                return (
                  <NewsCard
                    setNewsPopupData={setNewsPopupData}
                    key={item.id}
                    date={item.enDate}
                    category={item.category}
                    title={item.localized_contents[0].title}
                    description={item.localized_contents[0].text_short}
                    text={item.localized_contents[0].text_long}
                    image={item.localized_contents[0].image}
                  />
                );
              })}
            </StyledListNews>
            <StyledXBtnBlockNews>
              {newsLength > 3 && displayButton()}
            </StyledXBtnBlockNews>
          </CPaddings>
        </CWidth>
      </StyledC>
      <NewsPopup
        setNewsPopupData={setNewsPopupData}
        newsPopupData={newsPopupData}
      />
    </>
  );
}

const StyledC = styled.div`
  position: relative;
  width: 100%;
  
  @media screen and (min-width: 1440px) {
    display: block;
  }
`;

const StyledListNews = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  min-height: 300px;
  margin-bottom: 2.4rem;
  padding-right: 60px;
  padding-left: 60px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  transition: background-color 250ms ease-in-out, border-color 250ms ease-in-out;
  text-align: center;
  
  &:after {
    content: "";
    width: 30%;
  }
    
  @media screen and (min-width: 1440px) {
    max-width: 1140px;
    padding-right: 0;
    padding-left: 0;
  }
  
  @media screen and (min-width: 1920px) {
    max-width: 1280px;
  }
  
  @media screen and (max-width: 991px) {
    display: flex;
    padding-right: 0;
    padding-left: 0;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: stretch;
  }
  
  @media screen and (max-width: 767px) {
    max-width: none;
    margin-bottom: 1rem;
    padding-right: 15px;
    padding-left: 15px;
    justify-content: space-between;
  }
  
  @media screen and (max-width: 479px) {
    justify-content: center;
  }
`;

const StyledXBtnBlockNews = styled.div`
  display: flex;
  width: 100%;
  margin-top: 20px;
  padding-top: 0;
  margin-bottom: 60px;
  justify-content: center;
  
  @media screen and (max-width: 479px) {
    margin-bottom: 100px;
  }
`;