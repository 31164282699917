import React from 'react';

export const CartCoupon = ({
  handleApplyCode, isLoadingCoupon, coupon, setCoupon
}) => {
  const isEmptyCoupon = coupon === '';
  return (
    <div className="k_banner">
      <div className="k_coupon0">
        <div className="k_coupon w-embed">
          <input
            type="text"
            onChange={(event) => setCoupon(event.target.value)}
            placeholder="XXXXXXXXXX"
            className="k_input k_input--coupon"
            value={coupon}
          />
        </div>
        <div className="k_apply_coupon">
          <div
            onClick={(event) => !isEmptyCoupon && handleApplyCode(event)}
            className={`button_cart button_cart--apply_code ${isEmptyCoupon || isLoadingCoupon ? 'button_cart--disabled' : ''}`}
          >
            <div className={`preloader ${!isLoadingCoupon ? 'hide' : ''}`} />
            <div>Apply promocode</div>
          </div>
        </div>
      </div>
    </div>
  );
};
