import React from 'react';
import styled from 'styled-components';


export function AccountDescription({children}) {
  return (
    <StyledDesc>
      {children}
    </StyledDesc>
  );

}

const StyledDesc = styled.div`
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  max-height: 5.5em;
  margin-bottom: 0em;
  font-family: Codecpro, sans-serif;
  line-height: 140%;
  font-weight: 400;
  letter-spacing: 0.5px;
  font-size: 14px;
  
  @media screen and (min-width: 1440px) {
    font-size: 18px;
  }
  
  @media screen and (max-width: 991px) {    
    margin-bottom: 10px;
  }
  
`;