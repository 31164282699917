import React, {
  Fragment, useEffect, useContext, useRef, useState
} from 'react';
import styled from 'styled-components';
import { StoreContext, GAMES_PER_CART_LIMIT } from './StoreContext';
import {
  getData, getBundleData, getProductBySku
} from './tools/getData';
import { CartProduct } from './CartProduct';
import { CartBottom } from './CartBottom';
import { skuWithoutDRM } from '../helpers/sku';
import { sendClick } from '../helpers/analytics';

export function CartBody(props) {
  const {
    handleClose = () => {}, cart, shown, cartAmount
  } = props;
  const cartBody = useRef(null);
  const [isCodeApply, setIsCodeApply] = useState(false);
  const context = useContext(StoreContext);
  const {
    clearCart, cartShown, skus, products, bundles, cartLoading
  } = context;
  const [coupon, setCoupon] = useState('');

  const handleDrmChange = (sku, newDrm, newDrmSku) => {
    const newCart = JSON.parse(JSON.stringify(context.cart));
    newCart.forEach((oneItem) => {
      if (oneItem.sku === sku) {
        oneItem.drm = newDrm;
        oneItem.drmSku = newDrmSku;
      }
    });
    context.updateCart();
  };

  const handleClear = () => {
    handleClose();
    setCoupon('');
    clearCart();
    sendClick('sb-team17_cart_clear');
  };

  useEffect(() => {
    if (cartAmount === 0) {
      setCoupon('');
    }
  }, [cartAmount]);

  useEffect(() => {
    const bonusItems = cart.items.filter((item) => item.is_free);
    if (bonusItems.length) {
    }
  }, [cart.items]);

  return (
    <div
      className={`k0 ${cartShown ? 'shown' : ''}`}
      ref={cartBody}
    >
      <form
        className={`kba ${shown ? 'shown' : ''}`}
      >

        {/* HEADER */}
        <div className="k_ha">
          <div>Cart</div>
          <div
            onClick={handleClear}
            id="kart_clear"
            data-kart="clear"
            className="k_top_clear"
          >
            Clear
            <div data-kart="close" className="button_kart--close" />
          </div>

          <div
            data-kart-close="true"
            data-kart="close"
            className="k_top_close"
            onClick={() => {
              handleClose();
            }}
          >
            <img src="https://assets.website-files.com/5e73f39d8f7c0b9c03c51853/5e7e9e1089fbd7262f09f3bc_Union.svg" />
          </div>
        </div>

        {/* LIST */}
        <div className="k_b_cont_overflow">
          <div className="kb_cont">
            <ul id="kart" data-kart="ul" className="k_ula w-list-unstyled">

              {Boolean(cart.items.length) && cart.items.map((oneCartItem, cartItemIndex) => {
                const { price } = oneCartItem;
                if (oneCartItem.is_free) {
                  return null;
                }
                if (oneCartItem.type === 'game_key') {
                  const oneSku = { ...getProductBySku(skus, skuWithoutDRM(oneCartItem)) }.fields;
                  let oneProductId;
                  let oneProduct;
                  if (oneSku) {
                    oneProductId = oneSku.ref_products[0];
                    oneProduct = { ...getData(products, oneProductId) };
                  }
                  // const oneSku = { ...getData(context.skus, oneProduct.ref_sku ? oneProduct.ref_sku[0] : null) };
                  if (oneSku && oneProductId) {
                    return (
                      <Fragment key={`cart_${oneProductId}`}>
                        {oneProduct && oneSku && (
                        <CartProduct
                          activeDrm={oneCartItem.drm_sku}
                          handleDrmChange={handleDrmChange}
                          key={`cartproduct_${oneProductId}`}
                          productId={oneProductId}
                          {...oneProduct}
                          {...oneSku}
                          sku={skuWithoutDRM(oneCartItem)}
                          productType="game"
                          disabled={cartItemIndex + 1 > GAMES_PER_CART_LIMIT}
                          cartAmount={cartAmount}
                          price={price}
                        />
                        )}
                      </Fragment>
                    );
                  }
                } else {
                  const { sku } = oneCartItem;
                  const oneBundleData = getBundleData(sku, bundles);
                  return (
                    <Fragment key={`cart_bundle_${sku}}`}>
                      {oneBundleData && (
                      <CartProduct
                        sku={sku}
                        productType="bundle"
                        disabled={cartItemIndex + 1 > GAMES_PER_CART_LIMIT}
                        {...oneBundleData}
                        price={price}
                      />
                      )}
                    </Fragment>
                  );
                }
              })}
              <li className={`item_loader ${!cartLoading ? 'hide' : ''}`} />
            </ul>

          </div>
        </div>
        <CartBottom
          cartItems={cart.items}
          setIsCodeApply={setIsCodeApply}
          isCodeApply={isCodeApply}
          handleClear={handleClear}
          price={cart.price}
          coupon={coupon}
          setCoupon={setCoupon}
        />
      </form>

      <div
        className={`pz ${cartShown ? 'shown' : ''}`}
        style={{
          pointerEvents: cartShown ? 'auto' : 'none'
        }}
        onClick={() => {
          handleClose();
        }}
      />

    </div>

  );
}

const CssFixedBottom = styled.div`
  @media screen and (min-width: 991px) {
    display: none !important;
  }

  @media screen and (max-width: 767px) {
    display: block !important;
  }

  @media screen and (max-width: 0px) {
    display: block !important;
  }
`;

const CssNotFixedBottom = styled.div`
  @media (max-width: 991px) {
    display: none !important;
  }

  @media (max-width: 767px) {

  }

  @media (max-width: 0px) {

  }
`;
