import React from 'react';
import { CartBonusProduct } from './CartBonusProduct';

export const CartBonusList = ({ products }) => (
  <div className="k_banner">
    <ul className="k_ula w-list-unstyled">
      {products.map((product) => <CartBonusProduct key={product.sku} product={product} />)}
    </ul>
  </div>
);
