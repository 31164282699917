import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';

import {StoreContext} from '../StoreContext';
import {AccountTitle} from './AccountTitle'
import {AccountRowWrap} from './AccountRowWrap'
import {AccountRowSet} from './AccountRowSet'
import {SettingsButton} from '../SettingsButton';

import {redeemEntitlement, getEntitlementGames} from '../../api/cartApi';
import {parseGamesResponse, formatErrorMessage} from '../tools/entitlement';
import {ReactComponent as FolderIcon} from '../Icons/folder.svg';
import {sendClick} from '../../helpers/analytics';

export function AccountRedeemCoupon() {
  const { userID, loginToken } = useContext(StoreContext);

  const [coupon, setCoupon] = useState('');
  const [couponError, setCouponError] = useState('');
  const [resultBonuses, setResultBonuses] = useState([]);

  useEffect(() => {
    loadEntitlementGames();
  }, []);

  const loadEntitlementGames = async () => {
    const response = await getEntitlementGames({ userID, loginToken });

    if(response.items) {
      const bonuses = parseGamesResponse(response);
      setResultBonuses(bonuses);
    }
  };

  const submitCoupon = async () => {
    setCouponError('');

    sendClick('sb-team17_UA_coupon-sent');

    try {
      const response = await redeemEntitlement({ code: coupon, userID, loginToken });

      let result = {};
      try { result = await response.json(); }
      catch (e) { }

      if('errorCode' in result) {
        setCouponError(formatErrorMessage(result));
        sendClick('sb-team17_UA_coupon-redeem-error');
      }
      else {
        loadEntitlementGames();
        sendClick('sb-team17_UA_coupon-redeem-success');
      }
    }
    catch (e) {
      sendClick('sb-team17_UA_coupon-redeem-error');
      console.error(e);
    }
  };

  const onFormSubmit = (e) => {
    e.preventDefault();
    submitCoupon();
  };

  return (
    <AccountRowWrap>
      <Wrapper>
        <AccountTitle title={'Redeem coupon'}/>
        <form onSubmit={onFormSubmit}>
          <AccountRowSet direction={"column"}>
            <InputGroup>
              <CouponInput
                type="text"
                placeholder="XXXXXXXXXX"
                className="k_input k_input--redeem"
                onChange={(e) => setCoupon(e.target.value)}
                value={coupon}
              />
              {couponError && (
                <CouponErrorMessage>{couponError}</CouponErrorMessage>
              )}
            </InputGroup>
            <Button>
              <SettingsButton
                type={"edit"}
                text={"Apply coupon"}
                cursor={"pointer"}
                disabled={coupon === ""}
                onClick={submitCoupon}
              />
            </Button>
          </AccountRowSet>
        </form>
        {resultBonuses.length > 0 && (
          <FilesResult>
            <FilesResultTitle>Download your bonus content:</FilesResultTitle>
            <FilesList>
              {resultBonuses.map((item, index) => {
                return (
                  <FilesListItem
                    key={index}
                    onClick={() => {
                      sendClick('sb-team17_UA_bonus-download');
                      window.open(item.url);
                    }}
                  >
                    <FolderIcon /> <span>{item.name}</span>
                  </FilesListItem>
                )
              })}
            </FilesList>
          </FilesResult>
        )}
      </Wrapper>
    </AccountRowWrap>
  );
}

const Wrapper = styled.div`
  @media screen and (min-width: 480px) {
    padding-bottom: 20px;
  }
`;

const InputGroup = styled.div`
  flex: 1;
  
  @media screen and (max-width: 479px) {
    width: 100%;
  }
`;

const CouponInput = styled.input`
  width: 60%;

  @media screen and (max-width: 991px) {
    width: 65%;
  }

  @media screen and (max-width: 479px) {
    width: 100%;
  }
`;

const Button = styled.div`
  @media screen and (max-width: 479px) {
    margin-top: 10px;
  }

  @media screen and (min-width: 992px) {
    margin-top: 6px;
  }
`;

const CouponErrorMessage = styled.div`
  font-size: 14px;
  margin-top: 3px;
  color: #f77d00;
`;

const FilesResult = styled.div`
  margin-top: 30px;
`;

const FilesResultTitle = styled.h4``;

const FilesList = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: 10px;
  column-gap: 20px;
`;

const FilesListItem = styled.div`
  display: flex;
  cursor: pointer;

  font-size: 18px;
  line-height: 18px;
  font-weight: 400;
  letter-spacing: 0.5px;
  
  opacity: 1;
  margin-bottom: 10px;
  transition: opacity 200ms ease-in-out;

  &:hover {
    opacity: 0.8;
  }
  
  & > svg {
    width: 20px;
    margin-right: 10px;
  }
  
  & > span {
    margin-top: 2px;
  }

  @media screen and (max-width: 767px) {
    font-size: 14px;
  }
`;
