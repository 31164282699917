import React from 'react';
import styled from 'styled-components';


export function AccountTitle({title}) {
  return (
    <StyledTitle>{title}</StyledTitle>
  );
}

const StyledTitle = styled.div`
  width: 100%;
  font-family: 'Codecpro ultra', sans-serif;
  line-height: 1em;
  font-weight: 400;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  margin-top: 0px;
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(226, 231, 255, 0.5);
  font-size: 1.3em;
  text-align: left;
  
  @media screen and (min-width: 1440px) {
    text-transform: uppercase;
  }
  
  @media screen and (max-width: 991px) {
    max-width: none;
    margin-bottom: 20px;
    font-size: 2em;
  }
  
  @media screen and (max-width: 767px) {
    margin-bottom: 0px;
    text-align: left;
  }
`;