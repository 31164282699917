import React from 'react';

export function getData(array, id, key, full) {
  let res;
  array && array.forEach((one) => {
    if (one.id === id) res = key ? one.fields[key] : full ? one : one.fields;
  });
  return res;
}

export function getProductBySku(skuArray, sku) {
  let res;
  skuArray && skuArray.forEach((one) => {
    if (one.fields.sku.toLowerCase() === sku.toLowerCase()) res = one;
  });
  return res;
}

export const checkIfInCart = (skuArray, sku) => Boolean(skuArray.filter((one) => ((one.sku) === sku)).length);

export const filterTags = (
  tags,
  what,
  productId,
  value,
  arrProducts,
  arrSkus,
) => {
  // filter by type
  let a = tags.filter((oneTag) => oneTag.fields.group && oneTag.fields.group.includes(what));
  // filter by product
  if (a.length) {
    a = productId

      ? a.filter((oneTag) => {
        const ret = oneTag.fields[what] && (oneTag.fields[what].includes(productId));

        // ret = ret && checkIfNameIncludes(oneTag['id'], textSearch) ? true : false

        return ret;
      })

      : a;
  }
  // : a.filter(one => checkIfNameIncludes(one['id']), textSearch);

  if (a.length) a = value ? a.filter((oneTag) => oneTag.fields.id === value) : a;
  return a;
};

const checkFilters = (
  arrTags,
  productId,
  filterkey,
  filterval,
  arrProducts,
  arrSkus,
) => {
  if (!filterkey || !filterkey.length) return true;
  if (!filterval || !filterval.length) {
    return false;
  }
  const check = filterTags(
    arrTags,
    filterkey,
    productId,
    filterval,
    arrProducts,
    arrSkus,
  );
  // return check;
  return !!check.length;
};

const checkActive = (oneProduct) => {
  let check;
  check = !!(oneProduct.fields.active && oneProduct.fields.ref_sku);
  return Object.keys(oneProduct.fields).length ? check : false;
};



export const filterProducts = (
  arrProducts,
  arrSkus,
  arrTags,
  filterkey,
  filterval,

) => {
  let ret = (filterval) ? arrProducts.filter((oneProd) => checkFilters(
    arrTags,
    oneProd.id,
    filterkey,
    filterval,
    arrProducts,
    arrSkus,
  )) : arrProducts;
  ret = ret.filter((oneProd) => checkActive(oneProd));

  return ret;
};

export const getBundleData = (sku, bundles) => {
  let d = bundles.filter(one => one['sku'] === sku);
  return d.length ? d[0] : false;
}

export const getBundleAmount = (sku, bundles) => {
  const bundleData = getBundleData(sku, bundles);
  return bundleData.price ? parseFloat(bundleData.price.amount) : 0;
};

export const getBundleCurrencyIso = (sku, context) => {
  const bundleData = getBundleData(sku, context);
  return bundleData.price ? bundleData.price.currency : null;
};
