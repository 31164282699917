import React from 'react';
import styled from 'styled-components';
import {AccountDescription} from "./Account/AccountDescription";

export function PaymentInfo({title, desc}) {

  return (
    <StyledWrap>
      <StyledTitle>
        {title}
      </StyledTitle>
      <AccountDescription>
        {desc}
      </AccountDescription>
    </StyledWrap>
  );
}

const StyledWrap = styled.div`
  display: flex;
  width: 90%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-bottom: 1px none #f77d00;
`;

const StyledTitle = styled.h4`
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
  
  @media screen and (max-width: 479px) {
    width: 100%;
  }
`;
