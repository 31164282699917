import React from 'react';
import styled from 'styled-components';
import {Button} from './Button';


export const DisconnectButton = styled(Button)`  
  @media screen and (max-width: 479px) {
    margin: 0 0 30px 0.4em;
    order: -1;
  }
`;