import React, {
  useEffect, useState, useContext, useRef
} from 'react';
import { Link } from 'react-router-dom';
import { StoreContext } from './StoreContext';

export function SearchDrop(props) {
  const { term } = { ...props };

  const context = useContext(StoreContext);
  const { products } = context;

  const getSearch = (term, allProducts, limit = 14) => {
    if (!term || !allProducts.length) {
      return [];
    }
    return allProducts.filter((one) => (one.fields.name.toLowerCase().includes(term.toLowerCase()) && one.fields.active)).splice(0, limit);
  };

  const results = getSearch(term, products);

  if (results.length === 0) {
    return null;
  }

  return (
    <div className={`h_search_pop0 ${term ? 'shown' : ''}`}>
      <div className="h_search_pop_bg" />
      <div className="h_search_pop_results0">
        {getSearch(term, products).map((one, ind) => (
          <Link
            to={`/product/${one.fields.sku.toLowerCase()}`}
            className="h_search_pop_result"
            key={`result_${one.fields.sku}_${ind}`}
          >
            {one.fields.name}

            {/* {Boolean(one["fields"]['dlc_for']) && <div className="h_search_pop_result_tag">DLC/Addon</div>} */}

            {/* {!Boolean(one["fields"]['dlc_for']) && <div className="h_search_pop_result_tag">Game</div>} */}

          </Link>
        ))}
      </div>
    </div>
  );
}

export function Search(props) {
  const [search, setSearch] = useState('');

  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
         * Alert if clicked on outside of element
         */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          // alert("You clicked outside of me!");
          setSearch('');
        }
      }
      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  }

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  return (
    <div
      ref={wrapperRef}
      className={'h_search0 active'}
    >
      <input
        type="text"
        className="h_search_inp"
        placeholder="Search"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />

      <SearchDrop term={search} />
    </div>
  );
}
