import React from 'react';
import styled from 'styled-components';
import {Svg} from "../Icons/Svg";
import {icons} from "../Icons/icons";


export function IconToButton(props) {
  return (
    <StyledButCta {...props}>
      <div className="w-embed">
        <Svg
          width={24}
          height={24}
        >
          {icons[props.icon]}
        </Svg>
      </div>
    </StyledButCta>
  );
}

const StyledButCta = styled.div`
  position: absolute;
  left: auto;
  top: 0;
  right: 0;
  bottom: auto;
  z-index: 0;
  display: flex;
  width: 44px;
  height: 44px;
  max-height: 44px;
  min-height: 0;
  margin-bottom: 0;
  padding-right: 14px;
  padding-left: 14px;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
  flex: 0 0 auto;
  border-radius: 30px;
  background-color: ${(props) => props.color || "#58c15b" };
  transition: all 200ms ease;
  
  @media screen and (min-width: 1440px) {
    margin-left: 10px;
    padding: 13px 14px;
    border-radius: 20px;
  }  
  
  @media screen and (min-width: 1920px) {
    padding-right: 14px;
    padding-left: 15px;
  }
  
  @media screen and (max-width: 767px) {
    width: 44px;
    height: 44px;
  }
`;