import React from 'react';
import styled from 'styled-components';
import {EnterBDay} from "./EnterBDay"
import {Error} from "./Error"
import {AGE, colorOrange} from "../Variable";
import {AgePopupButton} from "../Button/AgePopupButton";
import {IconToButton} from "../Button/IconToButton";
import {LinkTextDecorationNone} from "../Styles/LinkTextDecorationNone";
import {sendClick} from '../../helpers/analytics';


export function AgeGatePopup({gameAgeGate, children, page}) {
  const age = localStorage.getItem(AGE);

  return (<>
  {gameAgeGate && +age < gameAgeGate ? 
      <StyledPopAge>
        <StyledPopAgeZ/>
        <StyledPopAgeBody>
          {!!age || age === '0' ?
            <>
              <Error title={"Access denied"}/>
              <LinkTextDecorationNone to={"/"} onClick={() => {}} >
                <AgePopupButton
                  title={"return to main page"}
                  icon
                  color={colorOrange}
                  width={"44px"}
                  height={"44px"}
                  disabled={false}
                  onClick={() => sendClick('sb-team17_banner_return')}
                >
                  <IconToButton
                    icon={"buttonArrow"}
                    color={colorOrange}
                  />
                </AgePopupButton>
              </LinkTextDecorationNone>
            </>
            :
            <EnterBDay/> }
        </StyledPopAgeBody>
      </StyledPopAge>
    : children}
    </>)
}

const StyledPopAge = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1010;
  display: flex;
  clear: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  @media screen and (max-width: 767px) {
    justify-content: flex-start;
  }
`;

const StyledPopAgeZ = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  display: flex;
  clear: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgb(55, 0, 101);
  
  @media screen and (max-width: 767px) {
    background-color: #131313;
  }
`;

const StyledPopAgeBody = styled.div`
  position: fixed;
  z-index: 1;
  display: flex;
  max-height: 400px;
  min-height: 300px;
  min-width: 400px;
  margin-right: auto;
  margin-left: auto;
  padding: 3em;
  clear: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 0 none transparent;
  border-radius: 20px;
  background-color: rgb(44, 0, 81);
  
  @media screen and (max-width: 991px) {
    min-width: 200px;
    border-style: none;
    border-radius: 20px;
  }
    
  @media screen and (max-width: 767px) {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    max-height: none;
    max-width: 100%;
    min-height: 0;
    min-width: auto;
    padding: 1em;
  }
  
  @media screen and (max-width: 479px) {
    margin-top: 0;
  }
`;
