import {
  STORE_API,
  SANDBOX_SECURE_HOST,
  SECURE_HOST,
  STORE_CART_ID,
  STORE_PROJECT_ID,
  IS_DEV,
  ANALYTICS_TAG
} from '../config';

const MOBILE_PAYSTATION_BREAKPOINT = 990;

const getUserHeaders = (userID, token, email) => {
  const headers = token ? {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${token}`
  } : {
    'Content-Type': 'application/json',
    'x-unauthorized-id': userID
  };
  if (email) {
    headers['x-user'] = btoa(JSON.stringify({ email, xsolla_product_tag: ANALYTICS_TAG }));
  }
  return headers;
};

const handleResponse = (method) => (response) => {
  if (response.statusCode && response.statusCode !== 200) {
    window.Sentry.captureException(new Error(`${method} error, status = ${response.statusCode}, errorMessage = ${response.errorMessage}`));
  }
  return response;
};

export const getUserCart = async (userID, token = null) => fetch(
  `${STORE_API}/v2/project/${STORE_PROJECT_ID}/cart/${STORE_CART_ID}`, {
    method: 'GET',
    headers: new Headers(getUserHeaders(userID, token))
  }
)
  .then((response) => response.json())
  .then(handleResponse('getUserCart'));

export const getGames = () => fetch(
  `${STORE_API}/v2/project/${STORE_PROJECT_ID}/items/game?locale=en`, {
    method: 'GET'
  }
)
  .then((response) => response.json())
  .then(handleResponse('getGames'));

export const getBundles = () => fetch(
  `${STORE_API}/v2/project/${STORE_PROJECT_ID}/items/bundle?locale=en`, {
    method: 'GET'
  }
)
  .then((response) => response.json())
  .then(handleResponse('getBundles'));

export const redeemCoupon = ({
  couponCode, selectedUnitItems, userID, token
}) => {
  const headers = getUserHeaders(userID, token);
  const body = {
    coupon_code: couponCode,
    cart: {
      id: STORE_CART_ID
    }
  };

  if (selectedUnitItems) {
    body.selected_unit_items = selectedUnitItems;
  }
  return fetch(
    `${STORE_API}/v2/project/${STORE_PROJECT_ID}/promocode/redeem`, {
      method: 'POST',
      headers: new Headers(headers),
      body: JSON.stringify(body)
    }
  )
    .then((response) => response.json())
    .then((response) => ({
      ...response,
      statusCode: response.statusCode === 404 ? '' : response.statusCode // чтобы не падали ошибки в sentry, если просто неверный промокод ввели
    }))
    .then(handleResponse('redeemCoupon'));
};

export const saveItemsToCart = async (
  userId = null, token = null, cart
) => {

  if (!cart.items.length) {
    return false
  }

  return fetch(`${STORE_API}/v2/project/${STORE_PROJECT_ID}/cart/${STORE_CART_ID}/fill`, {
  method: 'PUT',
  headers: getUserHeaders(userId, token),
  body: JSON.stringify({
    items: cart.items
  })
})
  .then((response) => response.json())
  .then(handleResponse('saveItemsToCart'))
};

const initPayStationToken = ({
  headers
}) => fetch(`${STORE_API}/v2/project/${STORE_PROJECT_ID}/payment/cart/${STORE_CART_ID}`, {
  method: 'POST',
  headers: new Headers(headers),
  body: JSON.stringify({
    locale: 'en-US',
    sandbox: IS_DEV,
    settings: {
      ui: {
        theme: 'light'
      }
    }
  })
})
  .then((response) => response.json());

const initPaymentsMethodSettingsToken = (token, userProfile) => fetch(`${STORE_API}/v2/xsolla_login/paystation/token`, {
  method: 'POST',
  headers: getUserHeaders(null, token),
  body: JSON.stringify({
    xsolla_login_user_id: userProfile.id,
    token_data: {
      user: {
        id: {
          value: userProfile.id
        },
        name: {
          value: userProfile.username
        },
        email: {
          value: userProfile.email
        }
      },
      settings: {
        project_id: +STORE_PROJECT_ID,
        ui: {
          mode: "user_account"
        }
      }
    }
  })
})
  .then((response) => response.json());

export const openPayStationWidget = ({ token }) => {
  const option = {
    access_token: token,
    sandbox: IS_DEV,
    host: getPayStationUrl(),
    lightbox: {
      height: '685px',
      spinner: 'round'
    }
  };

  window.XPayStationWidget.init(option);
  window.XPayStationWidget.open();
};

export const setPSSuccessCallback = (callback) => {
  window.onmessage = async (event) => {
    try {
      const psData = JSON.parse(event.data);
      if (psData.data.state === 'status' && psData.data.value === 'done') {
        sessionStorage.setItem('isOrdered', '1');
        callback();
      }
    } catch (e) {}
  };

  window.XPayStationWidget.on(window.XPayStationWidget.eventTypes.CLOSE, () => {
    window.XPayStationWidget.off();
    if (sessionStorage.getItem('isOrdered') === '1') {
      sessionStorage.clear();
    }
  });
};

export const getErrorMsg = (response) => {
  const errorMsgArr = response.errorMessage.split(':');
  const errMsg = errorMsgArr.length > 1 ? errorMsgArr[1] : response.errorMessage;
  return errMsg;
};

const getErrorCode = (response) => {
  const errorMsgArr = response.errorMessage.match(/\[(\d{4}-\d{4})\]/g);
  const errCode = errorMsgArr.length > 0 ? errorMsgArr[0] : response.errorMessage;
  return errCode;
};

const getPayStationUrl = () => (
  IS_DEV ? SANDBOX_SECURE_HOST : SECURE_HOST
);

export const isMobile = () => window.innerWidth < MOBILE_PAYSTATION_BREAKPOINT;

export const checkoutCart = async ({
  email, popup, userID, loginToken
}) => {
  const headers = getUserHeaders(userID, loginToken, email);

  const { token } = await initPayStationToken({ headers });
  if (popup) {
    popup.location.href = `https://${getPayStationUrl()}/paystation/?access_token=${token}`;
  } else {
    openPayStationWidget({ token });
  }
};

export const PaymentsMethodSettings = async ({
  popup, loginToken, userProfile
}) => {
  const {token} = await initPaymentsMethodSettingsToken(loginToken, userProfile);
  if (popup) {
    popup.location.href = `https://${getPayStationUrl()}/paystation/?access_token=${token}`;
  } else {
    openPayStationWidget({token});
  }
};

export const putItemToCart = async (
  itemSKU, userID, loginToken
) => fetch(`${STORE_API}/v2/project/${STORE_PROJECT_ID}/cart/${STORE_CART_ID}/item/${itemSKU}`, {
  method: 'PUT',
  headers: new Headers(getUserHeaders(userID, loginToken)),
  body: JSON.stringify({
    quantity: 1
  })
}).then((response) => (response.status === 204 ? {} : response.json()))
  .then((response) => ({
    ...response,
    statusCode: response.errorMessage && getErrorCode(response) === '[0401-1421]' ? '' : response.statusCode // чтобы не падали ошибки в sentry, если игр больше чем заданный лимит
  }))
  .then(handleResponse('putItemToCart'));

export const deleteItemFromCart = async (
  itemSKU, userID, loginToken
) => fetch(`${STORE_API}/v2/project/${STORE_PROJECT_ID}/cart/${STORE_CART_ID}/item/${itemSKU}`, {
  method: 'DELETE',
  headers: new Headers(getUserHeaders(userID, loginToken))
}).then((response) => (response.status === 204 ? {} : response.json()))
  .then(handleResponse('putItemToCart'));

export const clearCartApi = async (
  userID = null, loginToken = null
) => fetch(`${STORE_API}/v2/project/${STORE_PROJECT_ID}/cart/${STORE_CART_ID}/clear`, {
  method: 'PUT',
  headers: new Headers(getUserHeaders(userID, loginToken))
}).then((response) => (response.status === 204 ? {} : response.json()))
  .then(handleResponse('putItemToCart'));

export const redeemEntitlement = ({ code, userID, loginToken }) => fetch(`${STORE_API}/v2/project/${STORE_PROJECT_ID}/entitlement/redeem`, {
  method: 'POST',
  headers: new Headers(getUserHeaders(userID, loginToken)),
  body: JSON.stringify({
    code
  })
});

export const getEntitlementGames = ({ userID, loginToken }) => fetch(
  `${STORE_API}/v2/project/${STORE_PROJECT_ID}/entitlement?additional_fields[]=attributes`,
  {
    method: 'GET',
    headers: new Headers(getUserHeaders(userID, loginToken))
  }
)
  .then((response) => response.json());
