import React, {
  Fragment, useEffect, useState, useContext
} from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

export function Preloader(props) {
  return (
    <div className="prel0">
      <div className="prel w-embed">
        <svg className="prel" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 480 480">
          <defs>
            <style dangerouslySetInnerHTML={{ __html: '\n            .fon {\n                fill: #ff005b;\n            }\n            .w100 {\n                fill: #ffffff;\n            }\n        ' }} />
          </defs>
          <rect className="fon" width={480} height={480} />
          <g className="w100" transform="translate(100 100)">
            <polygon id="cart" points="0 0 50 0 50 40 300 40 300 60 290 60 290 80 280 80 280 100 270 100 270 120 260 120 260 140 250 140 250 160 240 160 240 180 230 180 230 200 50 200 50 230 190 230 190 250 30 250 30 20 0 20">
              <animate
                attributeName="points"
                dur="3s"
                repeatCount="indefinite"
                calcMode="spline"
                begin="0s"
                fill="freeze"
                keyTimes="0; 0.05; 0.1; 0.15; 0.2; 0.25; 1"
                keySplines="1, 0, 1, 1;
                      0, 0, 1, 1;
                      0, 0, 1, 1;
                      0, 0, 1, 1;
                      0, 0, 0, 1;
                      0, 0, 1, 1;"
                values="0,0 50,0 50,40 300,40 300,60 290,60 290,80 280,80 280,100 270,100 270,120 260,120 260,140 250,140 250,160 240,160 240,180 230,180 230,200 50,200 50,230 190,230 190,250 30,250 30,20 0,20;
              150,0 200,0 180,40 430,40 420,60 410,60 400,80 390,80 380,100 370,100 360,120 350,120 340,140 330,140 320,160 310,160 300,180 290,180 280,200 100,200 85,230 225,230 215,250 55,250 170,20 140,20;
              350,0 400,0 380,40 630,40 620,60 610,60 600,80 590,80 580,100 570,100 560,120 550,120 540,140 530,140 520,160 510,160 500,180 490,180 480,200 300,200 285,230 425,230 415,250 255,250 370,20 340,20;
              -350,0 -300,0 -280,40 -30,40 -20,60 -30,60 -20,80 -30,80 -20,100 -30,100 -20,120 -30,120 -20,140 -30,140 -20,160 -30,160 -20,180 -30,180 -20,200 -200,200 -185,230 -45,230 -35,250 -195,250 -310,20 -340,20;
              -150,0 -100,0 -80,40 170,40 180,60 170,60 180,80 170,80 180,100 170,100 180,120 170,120 180,140 170,140 180,160 170,160 180,180 170,180 180,200 0,200 15,230 155,230 165,250 5,250 -110,20 -140,20;
              0,0 50,0 50,40 300,40 300,60 290,60 290,80 280,80 280,100 270,100 270,120 260,120 260,140 250,140 250,160 240,160 240,180 230,180 230,200 50,200 50,230 190,230 190,250 30,250 30,20 0,20;
              0,0 50,0 50,40 300,40 300,60 290,60 290,80 280,80 280,100 270,100 270,120 260,120 260,140 250,140 250,160 240,160 240,180 230,180 230,200 50,200 50,230 190,230 190,250 30,250 30,20 0,20"
              />
              <animate
                attributeName="opacity"
                dur="3s"
                repeatCount="indefinite"
                calcMode="spline"
                begin="0s"
                fill="freeze"
                keyTimes="0; 0.05; 0.1; 0.15; 0.2; 0.25; 1"
                keySplines="1, 0, 1, 1;
                      0, 0, 1, 1;
                      0, 0, 1, 1;
                      0, 0, 1, 1;
                      0, 0, 0, 1;
                      0, 0, 1, 1;"
                values="1; 0.5; 0; 0; 0.5; 1; 1"
              />
            </polygon>
            <polygon id="koleso1" points="50 260 90 260 90 300 50 300">
              <animate
                attributeName="points"
                dur="3s"
                repeatCount="indefinite"
                calcMode="spline"
                begin="0s"
                fill="freeze"
                keyTimes="0; 0.05; 0.1; 0.15; 0.2; 0.25; 1"
                keySplines="1, 0, 1, 1;
                      0, 0, 1, 1;
                      0, 0, 1, 1;
                      0, 0, 1, 1;
                      0, 0, 0, 1;
                      0, 0, 1, 1;"
                values="50,260 90,260 90,300 50,300;
              70,260 110,260 90,300 50,300;
              270,260 310,260 290,300 250,300;
              -170,260 -130,260 -110,300 -150,300;
              30,260 70,260 90,300 50,300;
              50,260 90,260 90,300 50,300;
              50,260 90,260 90,300 50,300"
              />
              <animate
                attributeName="opacity"
                dur="3s"
                repeatCount="indefinite"
                calcMode="spline"
                begin="0s"
                fill="freeze"
                keyTimes="0; 0.05; 0.1; 0.15; 0.2; 0.25; 1"
                keySplines="1, 0, 1, 1;
                      0, 0, 1, 1;
                      0, 0, 1, 1;
                      0, 0, 1, 1;
                      0, 0, 0, 1;
                      0, 0, 1, 1;"
                values="1; 0.5; 0; 0; 0.5; 1; 1"
              />
            </polygon>
            <polygon id="koleso2" points="150 260 190 260 190 300 150 300">
              <animate
                attributeName="points"
                dur="3s"
                repeatCount="indefinite"
                calcMode="spline"
                begin="0s"
                fill="freeze"
                keyTimes="0; 0.05; 0.1; 0.15; 0.2; 0.25; 1"
                keySplines="1, 0, 1, 1;
                      0, 0, 1, 1;
                      0, 0, 1, 1;
                      0, 0, 1, 1;
                      0, 0, 0, 1;
                      0, 0, 1, 1;"
                values="150,260 190,260 190,300 150,300;
              170,260 210,260 190,300 150,300;
              370,260 410,260 390,300 350,300;
              -70,260 -30,260 -10,300 -50,300;
              130,260 170,260 190,300 150,300;
              150,260 190,260 190,300 150,300;
              150,260 190,260 190,300 150,300"
              />
              <animate
                attributeName="opacity"
                dur="3s"
                repeatCount="indefinite"
                calcMode="spline"
                begin="0s"
                fill="freeze"
                keyTimes="0; 0.05; 0.1; 0.15; 0.2; 0.25; 1"
                keySplines="1, 0, 1, 1;
                      0, 0, 1, 1;
                      0, 0, 1, 1;
                      0, 0, 1, 1;
                      0, 0, 0, 1;
                      0, 0, 1, 1;"
                values="1; 0.5; 0; 0; 0.5; 1; 1"
              />
            </polygon>
            <polygon id="cartSplash1" points="0 0 250 0 250 20 0 20">
              <animate
                attributeName="points"
                dur="3s"
                repeatCount="indefinite"
                calcMode="spline"
                begin="0s"
                fill="freeze"
                keyTimes="0; 0.1; 1"
                keySplines="1, 0, 1, 1;
                      0, 0, 1, 1;"
                values="0,0 50,0 50,20 0,20;
              0,0 250,0 250,20 0,20;
              0,0 250,0 250,20 0,20"
              />
              <animate
                attributeName="opacity"
                dur="3s"
                repeatCount="indefinite"
                calcMode="spline"
                begin="0s"
                fill="freeze"
                keyTimes="0; 0.1; 1"
                keySplines="1, 0, 1, 1;
                      0, 0, 1, 1;"
                values="1; 0; 0"
              />
            </polygon>
            <polygon id="splash1" points="50 290 290 290 290 300 50 300">
              <animate
                attributeName="points"
                dur="3s"
                repeatCount="indefinite"
                calcMode="spline"
                begin="0s"
                fill="freeze"
                keyTimes="0; 0.1; 1"
                keySplines="1, 0, 1, 1;
                      0, 0, 1, 1;"
                values="50,290 90,290 90,300 50,300;
              50,290 290,290 290,300 50,300;
              50,290 290,290 290,300 50,300"
              />
              <animate
                attributeName="opacity"
                dur="3s"
                repeatCount="indefinite"
                calcMode="spline"
                begin="0s"
                fill="freeze"
                keyTimes="0; 0.1; 1"
                keySplines="1, 0, 1, 1;
                      0, 0, 1, 1;"
                values="1; 0; 0"
              />
            </polygon>
            <polygon id="splash2" points="150 290 390 290 390 300 150 300">
              <animate
                attributeName="points"
                dur="3s"
                repeatCount="indefinite"
                calcMode="spline"
                begin="0s"
                fill="freeze"
                keyTimes="0; 0.15; 1"
                keySplines="1, 0, 1, 1;
                      0, 0, 1, 1;"
                values="150,290 190,290 190,300 150,300;
              150,290 390,290 390,300 150,300;
              150,290 390,290 390,300 150,300"
              />
              <animate
                attributeName="opacity"
                dur="3s"
                repeatCount="indefinite"
                calcMode="spline"
                begin="0s"
                fill="freeze"
                keyTimes="0; 0.15; 1"
                keySplines="1, 0, 1, 1;
                      0, 0, 1, 1;"
                values="1; 0; 0"
              />
            </polygon>
          </g>
        </svg>
      </div>
      <div>
        <br />
        Loading...
      </div>
    </div>

  );
}
