import React from "react";
// import { defaultIcon } from "./icons";

const defaultIcon = ''



export const Svg = ({
  name = "defaultIcon",
  width = 44,
  height = 44,
  forceHeight,
  color,
  png,
  children,
  style,
}) => {
  // let icon = children ? children.replace(/currentColor/g, color) : defaultIcon;

  // try {
  width = width ? width : children.split('width="')[1].split('"')[0]; //50
  height = height ? height : children.split('height="')[1].split('"')[0]; //44
  forceHeight = forceHeight ? forceHeight : height;
  let icon = children ? children : defaultIcon;

  if (color)
    icon = icon.replace(
      /fill="((.*?))(?!fill="none")\b\S+"/gim,
      `fill="${color}"`
    );

  let encoded = window.btoa(icon);


  // } catch (e) {}
  return (
    <div
      style={{
        display: "inline-flex",
        width: `${width}px`,
        height: `${forceHeight}px`,
        alignItems: "center",
        ...style
      }}
    >
      <div
        style={{
          color: color,
          width: `${width}px`,
          height: `${height}px`,
          transition: "all 200ms ease-in-out",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "50% 50%",
          backgroundSize: "contain",
          backgroundImage: "url(data:image/svg+xml;base64," + encoded + ")",
        }} />
    </div>
  );
};
