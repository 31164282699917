import React, { useState, useEffect } from 'react';
import { v4 as uuid } from 'uuid';

import {
  getGames, getBundles, getUserCart, putItemToCart, getErrorMsg, deleteItemFromCart, clearCartApi, saveItemsToCart
} from '../api/cartApi';
import {
  AIRTABLE_URL,
  AIRTABLE_BASE,
  AIRTABLE_APIKEY,
  USER_ID_LOCAL_STORAGE_KEY,
  STORE_CART_ID,
  XSOLLA_LOGIN_TOKEN_SB
} from '../config';
import { sendClick } from '../helpers/analytics';
import {getCookie, parseJwt} from "../helpers/helpers";

import {
  getUserProfile,
  getSocProviders
} from '../api/loginApi';
import { AGE } from './Variable';
import {getNews} from '../api/launcherApi'

const PAGE_URL = "Page url"
const MIN_AGE = "Min Age"


export const StoreContext = React.createContext();

export const GAMES_PER_CART_LIMIT = 10;
export const GAMES_PER_CART_LIMIT_TEXT = 'Exceeded limit of 6 games per cart';
export const TOKEN_LOGIN = getCookie("xsolla_login_token_sb")

export const StoreProvider = (props) => {
  const { children } = props;
  const [showProductSelector, setShowProductSelector] = useState(null);
  const [message, setMessage] = useState();
  const [dataToSave, setDataToSave] = useState([]);
  const [blocks, setBlocks] = useState();
  const [products, setProducts] = useState();
  const [productsToSave, setProductsToSave] = useState([]);
  const [cartLoading, setCartLoading] = useState(false);

  const initUserID = () => {
    if (!localStorage) {
      return null;
    }
    let localUserID = localStorage.getItem(USER_ID_LOCAL_STORAGE_KEY);
    if (!localUserID) {
      localUserID = uuid();
      localStorage.setItem(USER_ID_LOCAL_STORAGE_KEY, localUserID);
    }
    return localUserID;
  };

  const initLoginToken = () => {
    const url = new URL(window.location.href),
      urlToken = url.searchParams.get("token"),
      cookieToken = getCookie(XSOLLA_LOGIN_TOKEN_SB);

    if (cookieToken !== "null") {
      return cookieToken
    }

    return urlToken
  }

  const loginToken = initLoginToken();
  const userID = initUserID();
  const [cart, setCart] = useState({
    cart_id: STORE_CART_ID,
    price: null,
    is_free: true,
    items: []
  });

  const [cartError, setCartError] = useState('');
  const [cartShown, setCartShown] = useState(false);
  const [tags, setTags] = useState();
  const [p2pdata, setP2pdata] = useState();
  const [bundles, setBundles] = useState();
  const [skus, setSkus] = useState();
  const [routes, setRoutes] = useState();
  const [rendered, setRendered] = useState([]);
  const [ageGate, setAgeGate] = useState(null);
  const [gdpr, setGDPR] = useState(false);

  const [loginWidgetShown, setLoginWidgetShown] = useState(false)
  const [userProfile, setUserProfile] = useState()
  const [jwtUser, setUserJwt] = useState()
  const [linkSocConnect, setLinkSocConnect] = useState()
  const [connectedSoc, setConnectedSoc] = useState()
  const [showPreloader, setShowPreloader] = useState(false)
  const [showCartButton, setShowCartButton] = useState(true)
  const [showDisconnectPopup, setShowDisconnectPopup] = useState(false);
  const [ageGateTable, setAgeGateTable] = useState()

  const setData = (blockId, newData) => {
    const blockIndex = blocks.findIndex((one) => one.id === blockId);

    const newD = JSON.parse(JSON.stringify(blocks));
    newD[blockIndex].fields = { ...newD[blockIndex].fields, ...newData };
    setDataToSave([...new Set([...dataToSave, blockId])]);
    setBlocks([...newD]);
  };

  const syncCarts = async () => {
    setShowPreloader(true);
    const cartData = await getUserCart(userID, null);
    let authCart;
    if (cartData.items.length) {
      authCart = await saveItemsToCart(null, loginToken, cartData);
    } else {
      authCart = await getUserCart(userID, loginToken);
    }
    setCart(authCart);
    setShowPreloader(false);
  };

  const syncCartsLogOut = async () => {
    setShowPreloader(true);
    await clearCartApi(userID);
    if (cart.items.length) {
      await saveItemsToCart(userID, null, cart);
    }
    setShowPreloader(false);
  };

  const updateCart = async () => {
    const cartData = await getUserCart(userID, loginToken);
    if (cartData.errorMessage) {
      setCartError(getErrorMsg(cartData));
      return;
    }
    if (cartData.items.length === 0) {
      setCartShown(false);
    }
    setCart(cartData);
  };

  const clearCart = async () => {
    setCartLoading(true);
    setCartError('');

    if (loginToken) {
      const response = await clearCartApi(userID, loginToken);
      if (response.errorMessage) {
        setCartError(getErrorMsg(response));
        setCartLoading(false);
        return;
      }
    } else {
      const response = await clearCartApi(userID);
      if (response.errorMessage) {
        setCartError(getErrorMsg(response));
        setCartLoading(false);
        return;
      }
    }

    await updateCart();
    setCartLoading(false);
  };

  const cartDeleteItem = async (sku) => {
    setCartLoading(true);
    setCartError('');

    if (loginToken) {
      const response = await deleteItemFromCart(sku, userID, loginToken);
      if (response.errorMessage) {
        setCartError(getErrorMsg(response));
        setCartLoading(false);
        return;
      }
    } else {
      const response = await deleteItemFromCart(sku, userID);
      if (response.errorMessage) {
        setCartError(getErrorMsg(response));
        setCartLoading(false);
        return;
      }
    }

    await updateCart();
    setCartLoading(false);
    sendClick('sb-team17_cart_item-delete', sku);
  };

  const addItemToCart = async (sku) => {
    setCartLoading(true);
    setCartError('');

    if (loginToken) {
      const response = await putItemToCart(sku, userID, loginToken);
      if (response.errorMessage) {
        setCartError(getErrorMsg(response));
        setCartLoading(false);
        return;
      }
    } else {
      const response = await putItemToCart(sku, userID);
      if (response.errorMessage) {
        setCartError(getErrorMsg(response));
        setCartLoading(false);
        return;
      }
    }

    await updateCart();
    setCartLoading(false);
  };

  useEffect(() => {
    if (loginToken)
      syncCarts();
  }, [loginToken]);

  useEffect(() => {
    updateCart();
  }, [userID]);

  useEffect(() => {
    if (
      !routes
      && tags
      && blocks
      && products
      && skus
      && p2pdata
      && bundles
    ) {
      fetch(
        `${AIRTABLE_URL}/routes?sort%5B0%5D%5Bfield%5D=order&sort%5B0%5D%5Bdirection%5D=asc`, {
              headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Bearer ${AIRTABLE_APIKEY}`
              }
          }
      )
        .then((resp) => resp.json())
        .then((response) => {
          setRoutes(response.records);
        })
        .catch((err) => {
          // Error :(
        });
    }
  }, [tags, blocks, products, skus, routes, p2pdata, bundles]);

  const mergeData = (dataAT, dataP2P, needReverse = true) => {
    let data = [];

    const getFromP2p = (sku) => {
      let p2pSKU = false;
      dataP2P.forEach((oneP2P) => {
        if (oneP2P.sku === sku) {
          p2pSKU = oneP2P;
        }
      });
      return p2pSKU;
    };

    // AT first, p2p second
    data = dataAT
      .map((oneSku, ind) => {
        if (getFromP2p(oneSku.fields.sku)) {
          return {
            ...oneSku,
            fields: {
              ...oneSku.fields,
              ...getFromP2p(oneSku.fields.sku)
            }
          };
        }
        return null;
      })
      .filter((v) => v);

    return needReverse ? data.reverse() : data;
  };

  useEffect(() => {
    if (!p2pdata) {
      getGames().then((response) => setP2pdata(response.items));
    }
  }, [p2pdata, setP2pdata]);

  useEffect(() => {
    if (!bundles) {
      getBundles().then((response) => setBundles(response.items));
    }
  }, [bundles, setBundles]);

  useEffect(() => {
    if (!products && p2pdata) {
      const Airtable = require('airtable');
      const base = new Airtable({ apiKey: AIRTABLE_APIKEY }).base(
        AIRTABLE_BASE
      );

      let newProducts = [];

      base('products')
        .select({
          // maxRecords: 3,
          // view: "Grid view",
          sort: [{ field: 'importance', direction: 'desc' }]
        })
        .eachPage(
          (records, fetchNextPage) => {
            newProducts = [...newProducts, ...records];
            fetchNextPage();
          },
          (err) => {
            if (err) {
              console.error(err);
              return;
            }
            if (!err) {
              setProducts(mergeData(newProducts, p2pdata, false));
            }
          }
        );
    }
  }, [products, p2pdata]);

  useEffect(() => {
    if (!blocks) {
      fetch(
        `${AIRTABLE_URL}/blocks?sort%5B0%5D%5Bfield%5D=order&sort%5B0%5D%5Bdirection%5D=asc`, {
              headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Bearer ${AIRTABLE_APIKEY}`
              }
          }
      )
        .then((resp) => resp.json())
        .then((response) => {
          setBlocks(response.records);
        })
        .catch((err) => {
          // Error :(
        });
    }
  }, [blocks]);

  useEffect(() => {
    if (!tags) {
      fetch(
        `${AIRTABLE_URL}/tags?&sort%5B0%5D%5Bfield%5D=order&sort%5B0%5D%5Bdirection%5D=desc`, {
              headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Bearer ${AIRTABLE_APIKEY}`
              }
          }
      )
        .then((resp) => resp.json())
        .then((response) => {
          setTags(response.records);
        })
        .catch((err) => {
          // Error :(
        });
    }
  }, [tags]);

  useEffect(() => {
    if (!skus && p2pdata) {
      const Airtable = require('airtable');
      const base = new Airtable({ apiKey: AIRTABLE_APIKEY }).base(
        AIRTABLE_BASE
      );

      let newSkus = [];

      base('skus')
        .select({
          // maxRecords: 3,
        })
        .eachPage(
          (records, fetchNextPage) => {
            newSkus = [...newSkus, ...records];
            fetchNextPage();
            // setSkus(skus ? [...skus, ...records] : [...records])
          },
          (err) => {
            if (err) {
              console.error(err);
              return;
            }
            if (!err) {
              setSkus(mergeData(newSkus, p2pdata));
            }
          }
        );
    }
  }, [skus, p2pdata]);

  useEffect(() => {
    if (!userProfile && TOKEN_LOGIN !== "null") {
      getUserProfile(TOKEN_LOGIN)
        .then((response) => {
          if (response.error) {
            document.cookie = `${XSOLLA_LOGIN_TOKEN_SB}=null;`
            window.location.assign("/")
          }

          setUserProfile(response)
        })
        .catch((err) => {
        });
    }
  }, [userProfile, setUserProfile]);

  useEffect(() => {
    if (!connectedSoc && TOKEN_LOGIN !== "null" || showDisconnectPopup === "update") {
      getSocProviders(TOKEN_LOGIN)
        .then((response) => {
          setConnectedSoc(response)
        })
        .catch((err) => {
        });
    }
  }, [connectedSoc, setConnectedSoc, showDisconnectPopup]);

  useEffect(() => {
    if (loginToken && !jwtUser) {
      setUserJwt(parseJwt(loginToken));
    }
  }, [loginToken, setUserJwt]);

  useEffect(() => {
    if (!ageGateTable) {
      fetch(
        `${AIRTABLE_URL}/agegate`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${AIRTABLE_APIKEY}`
            }
          }
      )
        .then((resp) => resp.json())
        .then((response) => {
          let ageForGames = new Map();
          response.records.forEach((item)=> {
            ageForGames.set(item.fields[PAGE_URL].substring(0, item.fields[PAGE_URL].length - 1), item.fields[MIN_AGE])
          })
          setAgeGateTable(ageForGames);
        })
        .catch((err) => {
        });
    }
  }, [ageGateTable, setAgeGateTable]);

  useEffect(() => {
    const selectedAge = localStorage.getItem(AGE)
    if (selectedAge) {
      setAgeGate(selectedAge)
    }
  }, []);

  return (
    <StoreContext.Provider
      value={{
        userID,
        showProductSelector,
        setShowProductSelector,

        error: message,
        setMessage,

        setData,
        dataToSave,
        setDataToSave,

        setProducts,
        productsToSave,
        setProductsToSave,

        // CART
        cart,
        clearCart,
        cartShown,
        setCartShown,
        cartDeleteItem,
        cartError,
        setCartError,
        setTags,
        setBlocks,
        setSkus,
        addItemToCart,
        updateCart,
        syncCarts,
        syncCartsLogOut,
        cartLoading,
        setCartLoading,

        products,
        bundles,
        setBundles,
        skus,
        routes,
        blocks,
        tags,
        p2pdata,

        rendered,
        setRendered,

        ageGate,
        setAgeGate,
        gdpr,
        setGDPR,

        loginWidgetShown,
        setLoginWidgetShown,
        loginToken,
        jwtUser,
        setUserJwt,

        userProfile,
        setUserProfile,
        linkSocConnect,
        setLinkSocConnect,
        connectedSoc,
        setConnectedSoc,
        showPreloader,
        setShowPreloader,
        showCartButton,
        setShowCartButton,
        showDisconnectPopup,
        setShowDisconnectPopup,

        ageGateTable,
        setAgeGateTable,

        setRoutes
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};
