import React, {useContext} from 'react';
import {StoreContext} from '../StoreContext';
import {AccountTitle} from './AccountTitle'
import {AccountRowWrap} from './AccountRowWrap'
import {AccountRowSet} from './AccountRowSet'
import {SettingsButton} from '../SettingsButton'
import {SettingsSocMedia} from '../SettingsSocMedia'
import {getSocProvidersUrl} from '../../api/loginApi';
import {TOKEN_LOGIN} from "../StoreContext"


export function AccountSocMedia() {

  const {linkSocConnect, setLinkSocConnect} = useContext(StoreContext);
  const {connectedSoc} = useContext(StoreContext);
  const {setShowPreloader} = useContext(StoreContext);
  const {setShowDisconnectPopup} = useContext(StoreContext);

  const SocMedia = [
    {
      title: "Steam",
      platformName: "steam"
    },
    {
      title: "Discord",
      platformName: "discord"
    },
    {
      title: "Facebook",
      platformName: "facebook"
    },
    {
      title: "Twitter",
      platformName: "twitter"
    }
  ]

  const connectedSocList  = connectedSoc ? connectedSoc.map(function (item) {
      return item.provider
  }) : [];

  const getLink = (platform) => () => {
    setShowPreloader(true)
    getSocProvidersUrl(TOKEN_LOGIN, platform)
      .then((response) => {
        setLinkSocConnect(response.url)
      })
  }

  const openDisconnectPopup = (platform) => {
    setShowDisconnectPopup(platform)
  }

  return (
    <AccountRowWrap>
      <AccountTitle title={'social media'}/>
      {SocMedia && (
        SocMedia.map(function (item) {
        return (
          <AccountRowSet key={item.platformName}>
            <SettingsSocMedia title={item.title} platformName={item.platformName} />
            {connectedSocList.includes(item.platformName)
            ?
            <SettingsButton text={"disconnect"} link={linkSocConnect} onClick={()=>openDisconnectPopup(item.platformName)} cursor={"pointer"} />
            :
            <SettingsButton text={"connect"} link={linkSocConnect} onClick={getLink(item.platformName)} cursor={"pointer"} />}
          </AccountRowSet>
          )
      })
      )}

    </AccountRowWrap>
  );
}
