import React from 'react';

export function CartBonusProduct({ product }) {
  const {
    image_url: imgUrl,
    name,
    drm_name: drmName
  } = product;
  const productName = name.replace(`(${drmName.toLowerCase()})`, '');
  return (
    <li className="k_item" style={{ width: '100%' }}>
      <div className="k_li">
        <div className="k_col1">
          <div className="k_pic0">
            <div className="k_pic">
              <div className="k_pic2">
                <img
                  className="c_pic c_pic--cart"
                  src={imgUrl}
                />
              </div>
            </div>
          </div>
          <div className="k_name0">
            <div className="k_name">
              {productName}
              <br />
              <span className="k_for">
                for&nbsp;
                {drmName}
              </span>
            </div>
          </div>
          <div data-good="desc" data-kart-good="desc" className="k_desc" />
        </div>
        <div className="k_col2">
          <div
            className="txt_bonus"
            style={{ display: 'flex' }}
          >
            BONUS ITEM
          </div>
        </div>
      </div>
    </li>
  );
}
