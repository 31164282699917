import {useEffect, useRef, useState} from  'react'


export function useHover(handleMouseOver, handleMouseOut) {
  const [value, setValue] = useState(false);
  const ref = useRef(null);
  useEffect(
    () => {
      const node = ref.current;
      if (node) {
        node.addEventListener("mouseover", handleMouseOver);
        node.addEventListener("mouseout", handleMouseOut);
        return () => {
          node.removeEventListener("mouseover", handleMouseOver);
          node.removeEventListener("mouseout", handleMouseOut);
        };
      }
    },
    [ref.current]
  );
  return [ref, value];
}