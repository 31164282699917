import React, {useContext} from 'react';
import styled from 'styled-components';
import {Glitch} from './Glitch';
import {StoreContext} from "./StoreContext";


export function LogInButton() {

  const {setLoginWidgetShown} = useContext(StoreContext);

  function login() {
    setLoginWidgetShown("open")
  }


  return (
    <StyledLogIn onClick={login}>
      <Glitch text={"LOG IN / REGISTER"} />
    </StyledLogIn>
  );
}

const StyledLogIn = styled.div`
  display: flex;
  margin-left: 30px;
  
  @media screen and (min-width: 1920px) {
    order: 1;
  }  
  
  @media screen and (max-width: 767px) {
    position: static;
    right: auto;
    margin-right: 10px;
    margin-left: 0;
    justify-content: flex-end;
    order: 1;
    flex: 0 auto;
  }  
  
  @media screen and (max-width: 479px) {
    width: 100%;
    margin-top: 10px;
    margin-right: 0;
    justify-content: center;
    align-items: center;
  }
`;