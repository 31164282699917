import React from 'react';
import styled from 'styled-components';
import {Button} from './Button';


export const NewsCardButton = styled(Button)`  
  margin: 0;
  
  @media screen and (max-width: 767px) {
    padding: 0.7em 2em;
  }
`;