import React, {useState} from 'react';
import styled from 'styled-components';
import {XPopupClose} from "../Styles/XPopupClose";
import {BoldText} from "../Styles/BoldText";


export function NewsPopup({newsPopupData, setNewsPopupData}) {
  const [imageHeight, setImageHeight] = useState(false)
  const {date, category, title, text, image} = newsPopupData;

  function closePopup() {
    setNewsPopupData(false)
  }

  function createMarkup(text) {
    return {__html: text};
  }

  function scrollHandler(e) {
    if (e.target.scrollTop > 10) {
      setImageHeight("200px");
    } else {
      setImageHeight(false);
    }
  }

  return (
    newsPopupData && (
      <StyledPopUp>
        <StyledPopUpZ onClick={closePopup}/>
        <StyledPopUpBody>
          <XPopupClose onClick={closePopup}/>
          <StyledModalTitleImage image={image} imageHeight={imageHeight}>
            <StyledPopUpHeadContent>
              <StyledModalInfoWrapper>
                <StyledModalInfo>
                  <StyledNewsPopupCategory>{category}</StyledNewsPopupCategory>
                  <StyledNewsPopupDate>{date}</StyledNewsPopupDate>
                </StyledModalInfo>
                <StyledNewsPopupTitle>
                  <BoldText>{title.substring(0,56)}</BoldText>
                </StyledNewsPopupTitle>
              </StyledModalInfoWrapper>
            </StyledPopUpHeadContent>
          </StyledModalTitleImage>
          <StyledModalTextWrapper onScroll={scrollHandler} >
            <StyledNewsPopupContent dangerouslySetInnerHTML={createMarkup(text)}/>
          </StyledModalTextWrapper>
        </StyledPopUpBody>
      </StyledPopUp>
    )
  )
}

const StyledPopUp = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  display: flex;
  clear: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  @media screen and (max-width: 767px) {
    justify-content: flex-start;
  }
`;

const StyledPopUpZ = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  clear: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(19, 19, 19, 0.86);
  
  @media screen and (max-width: 767px) {
    background-color: #131313;
  }
`;

const StyledPopUpBody = styled.div`
  position: fixed;
  z-index: 1;
  display: flex;
  max-height: 90vh;
  max-width: 700px;
  min-width: 700px;
  margin-right: auto;
  margin-left: auto;
  padding-bottom: 2em;
  clear: none;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 20px;
  background-color: #400576;
  color: #fff;
  
  @media screen and (max-width: 991px) {
    top: 60px;
    min-height: 300px;
    min-width: 200px;
  }
  
  @media screen and (max-width: 767px) {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    max-height: none;
    max-width: 100%;
    min-width: auto;
    border-style: none;
    border-radius: 0;
  }
  
  @media screen and (max-width: 479px) {
    margin-top: 0;
    border-style: none;
    border-radius: 0;
  }
`;

const StyledModalTitleImage = styled.div`
  width: 100%;
  height: 426px;
  min-height: ${(props)=> props.imageHeight || "426px"};
  align-items: flex-end;
  background-image: url('${(props)=> props.image}');
  background-position: center center;
  background-size: cover;
  border-radius: 20px;
  transition: min-height .5s ease;
  
  @media screen and (max-width: 767px) {
    background-position: 50% 50%;
    border-radius: 0;
  }
  
  @media screen and (max-width: 479px) {
    background-size: cover;
    background-repeat: no-repeat;
  }
`;

const StyledPopUpHeadContent = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  padding-right: 50px;
  padding-bottom: 20px;
  padding-left: 50px;
  align-items: flex-end;
  background-image: linear-gradient(0deg, #400576 15%, transparent 71%);
  
  @media screen and (max-width: 767px) {
    padding-right: 20px;
    padding-left: 20px;
  }
`;

const StyledModalInfoWrapper = styled.div`
  width: 600px;
  color: #e9e9e9;
  font-size: 14px;
  
  @media screen and (max-width: 479px) {
    width: 100%;
  }  
`;

const StyledModalInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const StyledNewsPopupDate = styled.div`
  margin-bottom: 0;
`;

const StyledNewsPopupCategory = styled.div`
  margin-bottom: 0;
  color: #fff;
`;

const StyledNewsPopupTitle = styled.h1`
  font-size: 36px;
  line-height: 36px;
  overflow: hidden;
  
  @media screen and (max-width: 767px) {
    font-size: 1.4em;
    line-height: 1.2em;
  }  
`;

const StyledModalTextWrapper = styled.div`
  overflow: auto;
  width: 600px;
  height: auto;
  margin-top: 20px;
  margin-right: 50px;
  margin-left: 50px;
  
  @media screen and (max-width: 767px) {
    width: 100%;
    margin-right: 0;
    margin-left: 0;
    padding-right: 20px;
    padding-left: 20px;
  }
`;

const StyledNewsPopupContent = styled.div`
  font-size: 16px;
  line-height: 1.5em;
  overflow-x: hidden;
  overflow-wrap: break-word;
`;
