import React from 'react';
import styled from 'styled-components';
import {NewsCardButton} from '../Button/NewsCardButton';


export function NewsCard({setNewsPopupData, category, date, title, description, image, text}) {

  function showPopup() {
    setNewsPopupData({
      category,
      date,
      title,
      description,
      image,
      text
    })
  }

  return (
    <StyledNewsSliderBlock>
      <StyledNewsSliderImageBlock image={image}/>
      <StyledNewsTextBlock>
        <StyledNewsText>
          <StyledNewsNoteBlock>
            <StyledNewsSliderCategory>{category}</StyledNewsSliderCategory>
            <StyledNewsSliderDate>{date}</StyledNewsSliderDate>
          </StyledNewsNoteBlock>
          <StyledHeading3>{title.substring(0,56)}</StyledHeading3>
          <StyledAccentLine/>
          <StyledTNews>{description}</StyledTNews>
        </StyledNewsText>

        <StyledNewsBtnBlock>
          <NewsCardButton
            title={"READ MORE"}
            onClick={showPopup}
          />
        </StyledNewsBtnBlock>
      </StyledNewsTextBlock>
    </StyledNewsSliderBlock>
  )
}

const StyledNewsSliderBlock = styled.div`
  display: block;
  width: 30%;
  max-width: 400px;
  margin: 1em 10px;
  align-items: stretch;
  border-radius: 20px;
  background-color: hsla(0, 0%, 91.4%, 0.15);
  box-shadow: none;
  transition: all 250ms ease-in;
  
  @media screen and (max-width: 991px) {
    display: block;
    height: auto;
    max-height: none;
    max-width: none;
    margin-right: 0;
    margin-left: 0;
    flex: 0 auto;
    width: 46%;
    min-height: 0;
    justify-content: space-between;
  }
  
  @media screen and (max-width: 767px) {
    width: 100%;
  }
  
  @media screen and (max-width: 479px) {
    width: 100%;
    height: auto;
    max-height: 600px;
    min-height: 0;
  }  
`;

const StyledNewsSliderImageBlock = styled.div`
  width: 100%;
  max-height: none;
  max-width: none;
  min-height: 200px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background-image: url('${(props) => props.image}');
  background-position: 50% 50%;
  background-size: cover;
  
  @media screen and (max-width: 991px) {
    border-top-right-radius: 8px;
    border-bottom-left-radius: 0;
  }
  
  @media screen and (max-width: 479px) {
    max-height: 200px;
    min-height: 200px;
  }
`;

const StyledNewsTextBlock = styled.div`
  display: flex;
  padding: 20px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  border-radius: 0 8px 8px 0;
  text-align: left;
  width: 100%;
  min-height: 330px;
  
  @media screen and (max-width: 767px) {
    width: 100%;
    min-height: auto;
  }
`;

const StyledNewsText = styled.div`
  width: 100%;
  color: #fff;
`;

const StyledNewsNoteBlock = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 0;
  justify-content: space-between;
  color: #fff;
  font-size: 0.8em;
`;

const StyledNewsSliderCategory = styled.div`
  font-size: 14px;
`;

const StyledNewsSliderDate = styled.div`
  font-size: 14px;
`;

const StyledHeading3 = styled.h3`
  font-family: 'Codecpro ultra', sans-serif;
  font-size: 1.4em;
  line-height: 1.2em;
  text-transform: uppercase;
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: 300;
  
  @media screen and (min-width: 991px) {
    overflow: hidden;
    max-height: 50px;
    object-fit: contain;
  }
  
  @media screen and (max-width: 991px) {
    text-align: left;
  }
  
  @media screen and (max-width: 479px) {
    overflow: hidden;
    max-height: 75px;
    padding-left: 1px;
    line-height: 1.2em;
  }
`;

const StyledAccentLine = styled.div`
  max-height: 0;
  max-width: 70px;
  min-width: 70px;
  border-style: solid;
  border-width: 1px;
  border-color: #f77d00;
`;

const StyledTNews = styled.p`
  overflow: hidden;
  overflow-wrap: break-word;
  -webkit-line-clamp: 5;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  max-height: 100px;
  min-height: 120px;
  margin-top: 10px;
  color: #e9e9e9;
  line-height: 1.5rem;
  object-fit: contain;
  
  @media screen and (min-width: 1920px) {
    overflow: hidden;
  }
  
  @media screen and (max-width: 991px) {
    max-height: 400px;
  }
  
  @media screen and (max-width: 767px) {
    font-size: 14px;
    min-height: 30px;
  }
`;

const StyledNewsBtnBlock = styled.div`
  margin-top: 1em;
`;