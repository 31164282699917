import React, { useContext, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { StoreContext } from './StoreContext';
import { Button } from './Button';
import { Tags } from './Tags';
import { checkIfHasKeys, checkPreOrder, checkStock } from './tools/buyNowLogic';

export function Product(props) {
  const {
    productId, // productId airtable product reference
    className,
    isBlockedGame,
    id,
    developer,
    tags,

    group_bundles,

    template,

    sku,
    released,
    genre,

    image_1,
    image_url,
    video,
    video_2,
    landing_page_url,

    short_description,

    dlc_for,

    unit_items,

    badge,

    steam_pc,
    linux,
    ps4,
    xbox1,
    mac,
    gog,

    name,

    history,
    match,
    location
  } = { ...props };

  const [pop, setPop] = useState(false);

  const context = useContext(StoreContext);

  return (

    <div className="c_block c_block--slider2">
      <div className={`item_container item_container--${template}`}>
        <div className={`item item--${template}`}>
          <div className={`item_inner item_inner--${template}`}>
            <ProductPic
              {...props}
              handle={() => history.push(`/product/${sku.toLowerCase()}`)}
              isBlockedGame={isBlockedGame}
            />
            <div className={`c_txt c_txt--${template}`}>
              <div className={`s_info0 s_info0--${template}`}>
                <div className={`c_badge c_badge--${template}`}>

                  {Boolean(badge) && <div className="s_badge s_badge--buynow">{badge}</div>}
                  {!badge && (
                    <>
                      {!checkPreOrder(unit_items)
                            && checkStock(unit_items)
                            && (
                            <div className="s_badge s_badge--buynow">
                              <div>Out now</div>
                              {(checkPreOrder(unit_items) || checkIfHasKeys(unit_items)) && (
                              <div
                                className="c_tags"
                              >
                                <Tags
                                  // title={"Platform"}
                                  sku={sku}
                                  productId={productId}
                                  route="/platform"
                                  what="platform"
                                  size={12}
                                  color="#bf52f5"
                                  style={{ height: '32px' }}
                                />
                              </div>
                              )}
                            </div>
                            )}

                      {checkPreOrder(unit_items)
                            && <div className="s_badge s_badge--preorder">Coming soon</div>}
                      {(!checkPreOrder(unit_items)
                            && !checkIfHasKeys(unit_items))
                            && <div className="s_badge s_badge--out">Out of stock</div>}
                    </>
                  )}

                </div>

                <div className={`s_name_h1 s_name_h1--${template}`}>
                  {name}
                </div>

                {Boolean(short_description) && (
                <div className={`c_txt1_desc c_txt1_desc--${template}`}>
                  {short_description}
                </div>
                )}

                <div className={`c_tags0 c_tags0--${template}`}>
                  {['lg', 'slider2'].includes(template) && (
                  <div className={`c_tags0 c_tags0--${template}`}>
                    <div className="c_tags">
                          <Tags
                            sku={sku}
                            title="Platform"
                            productId={productId}
                            route="/platform"
                            what="platform"
                          />
                        </div>
                    <div className="c_tags">
                          <Tags
                            title="Genre"
                            productId={productId}
                            route="/genre"
                            what="genre"
                          />
                        </div>
                    {/* <div className="c_tags">
                          <Tags
                            title="Developer"
                            productId={productId}
                            route="/developer"
                            what="developer"
                          />
                        </div> */}
                  </div>
                  )}
                </div>
              </div>

              <div className={`s_cta s_cta--${template}`}>
                <Button
                  isBlockedGame={isBlockedGame}
                  sku={sku}
                  {...props}
                  type="game"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
}

export const ProductPic = (props) => {
  const {
    template, sku, image_url, landing_page_url, isBlockedGame, handle = () => void (0)
  } = { ...props };

  return (

    <div className={`c_pic0 c_pic0--${template}  ${isBlockedGame ? 'blur' : ''}`}>
      <img
        onClickCapture={(e) => {
          e.stopPropagation();
          if (
            landing_page_url
          ) {
            handle();
          }
        }}
        className={`c_pic c_pic--${template}`}
        src={image_url}
      />
      {['slider2', 'bundle'].includes(template) && (
        <>
          <img
            className="c_pic_fx c_pic_fx--slider2"
            src={image_url}
          />
          <img
            className="c_pic_fx c_pic_fx--slider2 c_pic_fx--2"
            src={image_url}
          />
        </>
      )}
    </div>
  );
};

export default withRouter(Product);
