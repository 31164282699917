import React from 'react';
import styled from 'styled-components';


export function IconLink({text, link}) {

  return (
    <StyledLink href={link} target="_blank">{text}</StyledLink>
  );
}

const StyledLink = styled.a`
  position: relative;
  z-index: 1;
  display: inline;
  margin-right: 0.2em;
  margin-bottom: 0px;
  margin-left: 0.1em;
  padding-top: 0px;
  padding-right: 16px;
  padding-bottom: 0px;
  background-image: url("data:image/svg+xml,%3Csvg width='22' height='21' viewBox='0 0 22 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0)'%3E%3Cpath d='M18 17.5H4V3.55212L7.5 3.5V0H0.5V21H21.5V12.25H18V17.5ZM11 0L14.5 3.5L9.25 8.75L12.75 12.25L18 7L21.5 10.5V0H11Z' fill='white'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0'%3E%3Crect width='21' height='21' fill='white' transform='translate(0.5)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
  background-position: 100% 60%;
  background-size: 12px;
  background-repeat: no-repeat;
  -webkit-transition: opacity 250ms ease-in-out;
  transition: opacity 250ms ease-in-out;
  color: #fff;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
    text-decoration: none;
  }

  @media screen and (max-width: 991px) {
    text-align: center;
  }
  
  @media screen and (max-width: 479px)  {
    justify-content: center;
    align-items: center;
    flex: 0 auto;
    line-height: 1.2rem;
  }
`;
