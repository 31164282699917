import {
  LAUNCHER_HOST, PROJECT_ID_NEWS
} from '../config';
import {getEnDate} from "../helpers/helpers";


export const getNews = async (
  limit, offset = 0
) => {
  return await fetch(`${LAUNCHER_HOST}/projects/${PROJECT_ID_NEWS}/news?limit=${limit}&offset=${offset}`)
    .then((response) => response.json())
    .then((response) => {
      let res = response.news.map((item) => {
        return {
          ...item,
          enDate: getEnDate(item.date)
        }
      });

      res.count = response.count;

      return res;
    })
    .catch((err) => {
    });
};