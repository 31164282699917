import React, {useContext, useState, useEffect} from 'react';
import styled from 'styled-components';
import {StoreContext} from "../StoreContext";
import {DateSelector} from "../Selector/DateSelector";
import {AgePopupButton} from "../Button/AgePopupButton";
import {AGE, colorOrange} from "../Variable";
import {IconToButton} from "../Button/IconToButton";
import {CancelButton} from "./CancelButton";
import {LinkTextDecorationNone} from "../Styles/LinkTextDecorationNone";
import {sendCustomEvent, sendClick} from '../../helpers/analytics';

export function EnterBDay() {
  const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  const days = Array.from(new Array(31),(v,i)=>i+1);
  const years = Array.from(new Array(82), (v,i)=> new Date().getFullYear()-i);

  const [ageGateData, setAgeGateData] = useState({})
  const {setAgeGate} = useContext(StoreContext)
  useEffect(() => {
    sendCustomEvent('sb-team17_banner_show')
  }, [])
  function getSelectValue(item) {
    let date = {};

    switch(item.type) {
      case 'month': date = {month: item.value}; break;
      case 'date': date = {date: item.value}; break;
      case 'year': date = {year: item.value}; break;
    }

    setAgeGateData(prev => {
      return {
        ...prev,
        ...date
      }
    })
  }

  const setAge = () => {
    const dateAge = new Date(ageGateData.year, months.indexOf(ageGateData.month), ageGateData.date)
    const age = Math.floor((new Date() - dateAge) / 31536000000);
    localStorage.setItem(AGE, age)
    setAgeGate(age)
    sendClick('sb-team17_banner_confirm', age)
  }

  return (
    <StyledAgeEnterYourBday>
      <StyledH2Lead id="you_must_be_over_17">enter your age</StyledH2Lead>
      <StyledXMenuLine id="platform">
        <StyledXMenuLabel/>
        <StyledXSelectorsBlock>
          <DateSelector type={"month"} title={"Month"} data={months} onChange={getSelectValue} />
          <DateSelector type={"date"} title={"Date"} data={days} onChange={getSelectValue}/>
          <DateSelector type={"year"} title={"Year"} data={years} onChange={getSelectValue}/>
        </StyledXSelectorsBlock>
      </StyledXMenuLine>

      <StyleAgeButtons>
        <LinkTextDecorationNone to={"/"}>
          <CancelButton onClick={() => sendClick('sb-team17_banner_cancel')} text={"cancel"} cursor={"pointer"} />
        </LinkTextDecorationNone>

        <AgePopupButton
          title={"confirm"}
          icon
          color={colorOrange}
          width={"44px"}
          height={"44px"}
          disabled={!ageGateData.month || !ageGateData.date || !ageGateData.year}
          onClick={() => setAge()}
        >
          <IconToButton
            icon={"buttonArrow"}
            color={colorOrange}
          />
        </AgePopupButton>
      </StyleAgeButtons>

    </StyledAgeEnterYourBday>
  );

}

const StyledAgeEnterYourBday = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: center;

  @media screen and (max-width: 767px) {
    align-items: center;
  }
`;

const StyledH2Lead = styled.div`
  width: 100%;
  margin-bottom: 0.5em;
  padding: 0.25em 1em;
  font-family: 'Codecpro ultra', sans-serif;
  font-size: 32px;
  line-height: 36px;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  
  @media screen and (max-width: 767px) {
    margin-top: 0;
  }  
  
  @media screen and (max-width: 479px) {
    padding-right: 0.5em;
    padding-left: 0.5em;
  }
`;

const StyledXMenuLine = styled.div`
  display: block;
  margin-bottom: 0.6em;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  z-index: 1;
  
  @media screen and (max-width: 479px) {
    text-align: left;
  }
`;

const StyledXMenuLabel = styled.div`
  margin-bottom: 0.4em;
  color: #fff;
  font-size: 0.9rem;
  text-transform: none;
  display: flex;
  width: 100%;
  justify-content: center;
`;

const StyledXSelectorsBlock = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 1em;
  
  @media screen and (max-width: 767px) {
    flex-direction: column;
    align-items: center;
  }
`;

const StyleAgeButtons = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  
  @media screen and (max-width: 767px) {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }
  
  @media screen and (max-width: 479px) {
    margin-right: auto;
    margin-left: auto;
    align-self: center;
  }
`;