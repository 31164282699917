import React, {useEffect,useState} from 'react';
import { useHover } from '../hooks/useHover';

export default function Slider(props) {
  const {
    blockId,
    children,
    blockType,
    template
  } = { ...props };

  const [slideNum, setSlideNum] = useState(children ? children.length : 0);

  useEffect(() => {
    if (children && children.length !== slideNum) {
      global[`mySwiper_${blockId}`] && global[`mySwiper_${blockId}`].update();
      setSlideNum(children.length);
    }
  }, [props, blockId, children, slideNum]);

  useEffect(() => {
    const swiper2 = `
    var mySwiper_${blockId} = new Swiper('#${blockId}', {
      updateOnWindowResize: true,
      slidesPerView: 1,
      direction: 'horizontal',
      centeredSlides: true,
      setWrapperSize: true,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true,
      },
      autoplay: {
        delay: 3000
      }
    });
  `;

    const swiperBundle = `
    var mySwiper_${blockId} = new Swiper('#${blockId}', {
      updateOnWindowResize: true,
      direction: 'horizontal',
      // centeredSlides: true,
      setWrapperSize: true,
      // autoHeight: true,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      slidesPerView: 'auto',
      // slidesPerGroup: 'auto',
      // slidesPerGroup: 3,
      slidesPerColumn: 'auto',
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true,
      },
      // breakpoints: {
      //   // when window width is >= 320px
      //   320: {
      //     centeredSlides: true,
      //   },
      //   // when window width is >= 480px
      //   480: {
      //     centeredSlides: false,
      //   },
      //   // when window width is >= 640px
      //   1200: {
      //     centeredSlides: false,
      //   }
      // }
    });
`;

    const script = document.createElement('script');
    script.async = true;
    script.innerHTML = (blockType === 'products') ? swiper2 : swiperBundle;
    document.body.appendChild(script);
  }, [blockId]);

  const handleMouseOver = (event) => {
    const $sliderElement = event.target.closest('.js-slider-stop');
    const $sliderElementProducts = event.target.closest('.slider_products');

    if ($sliderElement === null || $sliderElementProducts === null) {
      return true
    }

    const slider = (event.target.closest('.slider_products') || event.target.closest('.slider_bundles')).swiper

    if (slider.autoplay.running){
      slider.autoplay.stop();
    }
  }

  const handleMouseOut = (event) => {
    const $sliderElement = event.target.closest('.js-slider-stop');
    const $sliderElementProducts = event.target.closest('.slider_products');

    if ($sliderElement === null || $sliderElementProducts === null) {
      return true
    }

    const slider =  (event.target.closest('.slider_products') || event.target.closest('.slider_bundles')).swiper

    if (!slider.autoplay.running){
      slider.autoplay.start();
    }
  }

  const [hoverRef, isHovered] = useHover(handleMouseOver, handleMouseOut);

  return (
    <div
      ref={hoverRef}
      id={`${blockId}`}
      className={`swiper-container swiper-container-horizontal swiper-container-autoheight slider_${blockType}`}
      style={{
        cursor: 'grab'
      }}
    >
      <div className={`swiper-wrapper swiper-wrapper--${blockType === 'bundles' ? 'md' : template}`}>{children}</div>
      <div className="swiper-button-prev" />
      <div className="swiper-button-next" />
      <div className="swiper-pagination swiper-pagination-bullets">
        <span className="swiper-pagination-bullet" />
        <span className="swiper-pagination-bullet swiper-pagination-bullet-active" />
        <span className="swiper-pagination-bullet" />
        <span className="swiper-pagination-bullet" />
      </div>
    </div>
  );
}

export const Slide = ({
  template,
  image_url,
  children,
  sku,
  blockType
}) => (
  <div
    className={`swiper-slide swiper-slide--${template}`}
    key={`slide_${sku}}`}
  >
    <div className={`s_slide s_slide--${template}`}>
      <div className={`c_block c_block--${template}`}>
        <div className="s_cont">

          {children}

          {blockType === 'products' && (
          <div className={`item_bg item_bg--${template}`}>
            <img
              className="c_pic"
              src={image_url}
              style={{
                width: '100%'
              }}
            />
            <div className="item_bg_color" />
            <div className="c_block_sliderbefore" />
          </div>
          )}

        </div>
      </div>

    </div>
  </div>
);
