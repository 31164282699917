import React, {useContext, useState} from 'react';
import {StoreContext, TOKEN_LOGIN} from '../StoreContext';
import styled from 'styled-components';
import {Button} from '../Button/Button';
import {IconToButton} from '../Button/IconToButton';
import {DisconnectButton} from '../Button/DisconnectButton';
import {deleteSocProvidersUrl} from "../../api/loginApi";
import {colorOrange} from "../Variable"
import {XPopupClose} from "../Styles/XPopupClose";


export function DisconnectPopup( ) {
  const {showDisconnectPopup, setShowDisconnectPopup} = useContext(StoreContext);
  const [showPreloader, setShowPreloader] = useState(false);

  function closePopup() {
    setShowDisconnectPopup(false)
  }

  const getLinkDelete = () => {
    setShowPreloader(true)
    deleteSocProvidersUrl(TOKEN_LOGIN, showDisconnectPopup)
      .then(() => {
        setShowPreloader(false)
        setShowDisconnectPopup("update")
        closePopup()
      })
  }

  if (!showDisconnectPopup) {
    return null
  }

  return (
    <StyledDisconnectPopup>
      <StyledConfirmPopupZ onClick={closePopup}/>
      <StyledConfirmPopupBody>
        <XPopupClose onClick={closePopup}/>
        <StyledDisconnectApproval>
          <StyledH2Lead>disconnect <StyledSocialmediaName>{showDisconnectPopup}</StyledSocialmediaName> from this account?</StyledH2Lead>
          {showPreloader ?
            <StyledCTxtDesc>Disconnecting</StyledCTxtDesc>
            :
            <StyledXFormLine>
              <Button
                title={"cancel"}
                color={colorOrange}
                onClick={closePopup}
              />

              <DisconnectButton
                title={"disconnect"}
                icon
                color={colorOrange}
                onClick={() => getLinkDelete()}
              >
                <IconToButton
                  icon={"buttonArrow"}
                  color={colorOrange}
                />
              </DisconnectButton>
            </StyledXFormLine>
          }
        </StyledDisconnectApproval>
      </StyledConfirmPopupBody>
    </StyledDisconnectPopup>
  );
}

const StyledDisconnectPopup = styled.div`
  position: fixed;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 300;
  display: flex;
  clear: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  @media screen and (max-width: 767px) {
    justify-content: flex-start;
  }
`;

const StyledConfirmPopupZ = styled.div`
  position: fixed;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 0;
  display: flex;
  clear: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(19, 19, 19, 0.86);
  
  @media screen and (max-width: 767px) {
    background-color: #131313;
  }
`;

const StyledConfirmPopupBody = styled.div`
  position: fixed;
  z-index: 1;
  display: flex;
  max-height: 400px;
  min-height: 300px;
  min-width: 400px;
  margin-right: auto;
  margin-left: auto;
  padding: 3em;
  clear: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 0px none transparent;
  border-radius: 20px;
  background-color: #400576;
  
  @media screen and (max-width: 991px) {
    top: auto;
    min-width: 200px;
    border-style: none;
  }
  
  @media screen and (max-width: 767px) {
    position: fixed;
    left: 0px;
    top: 0px;
    right: 0px;
    bottom: 0px;
    height: 100%;
    max-height: none;
    max-width: 100%;
    min-height: 0px;
    min-width: auto;
    border-radius: 0px;
  }
  
  @media screen and (max-width: 479px) {
    margin-top: 0em;
    padding-right: 15px;
    padding-left: 15px;
    border-radius: 0px;
  }
`;

const StyledDisconnectApproval = styled.div`
  display: flex;
  max-width: 436px;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-end;
  text-align: center;
  
  @media screen and (max-width: 767px) {
    align-items: center;
  }
  
  @media screen and (max-width: 479px) {
    max-width: 100%;
  }
`;

const StyledH2Lead = styled.div`
  width: 100%;
  margin-bottom: 0.5em;
  padding: 0.25em 0.6em;
  font-family: 'Codecpro ultra', sans-serif;
  font-size: 32px;
  line-height: 36px;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  
  @media screen and (max-width: 767px) {
    margin-top: 0px;
  }
  
  @media screen and (max-width: 479px) {
    padding-right: 0px;
    padding-left: 0px;
    font-size: 25px;
  }
`;

const StyledSocialmediaName = styled.span`
  color: #f77d00;
`;

const StyledCTxtDesc = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  max-height: 5.5em;
  margin-bottom: 1em;
  font-family: Codecpro, sans-serif;
  line-height: 140%;
  font-weight: 400;
  letter-spacing: 0.5px;
  
  @media screen and (min-width: 1440px) {
      font-size: 18px;
  }
  
  @media screen and (max-width: 991px) {
    margin-bottom: 10px;
  }
  
  @media screen and (max-width: 479px) {
    max-height: 4.2em;
  }
`;

const StyledXFormLine = styled.div`
  display: flex;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 0px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  justify-content: space-around;
  
  @media screen and (max-width: 479px) {
    flex-direction: column;
  }
`;