import React from 'react';
import styled from 'styled-components';

export function CWidth({children}) {
  return (
    <StyledCWidth>
      {children}
    </StyledCWidth>
  )
}

const StyledCWidth = styled.div`  
  position: relative;
  z-index: 5;
  display: flex;
  width: 100%;
  max-width: 1306px;
  margin-right: auto;
  margin-left: auto;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  
  @media screen and (max-width: 991px) {
    max-width: 100%;
    padding-right: 16px;
    padding-left: 16px;
  }
  
  @media screen and (max-width: 767px) {
    position: relative;
    padding-right: 0;
    padding-left: 0;
  }
  
  @media screen and (max-width: 479px) {
    justify-content: center;
  }
`;