import React from 'react';
import styled from 'styled-components';


export function XPopupClose({children, onClick}) {
  return (
    <StyledXPopupClose onClick={onClick}>
      {children}
    </StyledXPopupClose>
  )
}

const StyledXPopupClose = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 10;
  width: 40px;
  height: 40px;
  background-image: url("data:image/svg+xml,%3Csvg width='50' height='50' viewBox='0 0 50 50' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M25 50C11.1929 50 0 38.8071 0 25C0 11.1929 11.1929 0 25 0C38.8071 0 50 11.1929 50 25C50 38.8071 38.8071 50 25 50Z' fill='%23400576' fill-opacity='0.5'/%3E%3Cpath d='M34.0967 16L25.0967 25L34.0967 34M15.9997 16L24.9997 25L15.9997 34' stroke='white' stroke-width='3' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  background-position: 50% 50%;
  background-size: 40px 40px;
  background-repeat: no-repeat;
  opacity: 1;
  -webkit-transition: opacity 200ms ease-in-out;
  transition: opacity 200ms ease-in-out;
  cursor: pointer;
  
  &:hover {
    opacity: 0.8;
  }
  
  @media screen and (max-width: 479px) {
    position: fixed;
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: auto;
    z-index: 12;
    display: block;
    width: 100%;
    height: 60px;
    justify-content: flex-end;
    background-position: 96% 50%;
    opacity: 1;
  }
`;