import React from 'react';
import styled from 'styled-components';
import {Button} from './Button';


export const ShowMoreButton = styled(Button)`   
  @media screen and (max-width: 767px) {
    width: auto;
    padding: 0.7em 2em;
  }
`;