import React, { useContext } from 'react';
import styled, { css } from 'styled-components';
import { ButtonCart } from './Button';
import { StoreContext } from './StoreContext';
import { ProductPic } from './Product';

export function Bundle(props) {
  const {
    bundleData,
    template
  } = {
    ...props
  };

  return (

    <div className={`item_container item_container--${template}`}>
      <div className={`item item--${template}`}>
        <div className={`item_inner item_inner--${template}`}>
          <div className={`c_pic0 c_pic0--${template}`}>

            <BundlePics content={bundleData.content} {...bundleData} />

          </div>
          <div className={`c_txt c_txt--${template}`}>
            <div className={`s_info0 s_info0--${template}`}>

              <div className="c_tags0 c_tags0--bundle">
                <div className="c_badge c_badge--bundle">

                  {bundleData.price && (
                    <div className="s_badge s_badge--bundle">
                      {bundleData.content.length}
                      {' '}
                      item bundle
                    </div>
                  )}

                  {!bundleData.price && <div className="s_badge s_badge--out">Out of stock</div>}

                </div>
              </div>

              <div className="s_name_h1 s_name_h1--md">
                {bundleData.name}

              </div>

            </div>
            <div className={`s_cta s_cta--${template}`}>

              {bundleData.price && (
                <ButtonCart
                  productId={bundleData.sku}
                  unit_items={bundleData.price ? bundleData.content : null}
                  bundle_price={bundleData.price}
                  template={template}
                  sku={bundleData.sku}
                  itemType="bundle"
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>

  );
}

export const BundlePics = (props) => {
  const {
    cart, content, image_url, children
  } = { ...props };

  const getStyle = (q, initial = 'c_pic_bundle0--') => {
    let classN = '';
    if (content.length === 2) classN = `${initial}2`;
    if (content.length === 3) classN = `${initial}3`;
    if (content.length === 4) classN = '';
    if (content.length > 4 && content.length < 9) classN = `${initial}4`;
    if (content.length > 9) classN = `${initial}5`;
    return classN;
  };

  return (
    <>
      {children}

      {!cart && (
      <div className="c_pic_bundle_bg">
        <div className="c_pic_bundle_bg_blur">
          <img
            className="c_pic"
            src={image_url || content[0].image_url}
          />
        </div>
      </div>
      )}

      {Boolean(image_url) && <ProductPic template="bundle1" {...props} />}

      {!image_url && (
      <div className={`c_pic_bundle0 ${cart ? 'c_pic_bundle0--k' : ''} ${getStyle(content.length)}`} style={{ width: '100%' }}>

        {content && content.map((oneItem, ind) => (
          <div
            className={`c_pic_bundle ${getStyle(content.length, 'c_pic_bundle--')} ${cart ? 'c_pic_bundle--k' : ''} `}
            key={`bundle_${oneItem.sku}_pic_${ind}`}
          >
            <div
              className={`c_pic ${cart ? 'c_pic--k' : ''}`}
              style={{
                backgroundImage: `url(${oneItem.image_url})`
              }}
            >
              {!cart && (
              <div className="c_bundle_item_name">
                <div className="c_bundle_item_tooltip">
                  {oneItem.name}
                </div>
              </div>
              )}

            </div>
          </div>
        ))}
      </div>
      )}
    </>
  );
};

export const BundleGames = (props) => {
  const context = useContext(StoreContext);

  const { content, disabled = true } = { ...props };

  // const  getUrl = (sku) => {getData(context['skus'], sku, 'landing_page_url')}

  return (
    <>
      <div className="c_bundle_items0">
        {content.map((oneItem, ind) => (
          <CSSbundleItem
        // to={`/product/${oneItem['sku'].toLowerCase()}`}
        // to={getUrl(oneItem['sku'])}
            disabled={disabled}
            key={ind}
            className="c_bundle_item"
          >

            {oneItem.name}
          </CSSbundleItem>
        ))}
      </div>
    </>
  );
};

const CSSbundleItem = styled.span`
  text-decoration: none;
  ${({ disabled }) => disabled && css`
    pointer-events: none;
    /* background: #f9f0ff; */
  `};
`;
