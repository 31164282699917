import React, {
  useState, useContext
} from 'react';
import {
  Link
} from 'react-router-dom';
import { StoreContext } from './StoreContext';
import { Svg } from './Icons/Svg';
import { icons } from './Icons/icons';
import { formatAmount, formatCurrencyIso } from './tools/buyNowLogic';
import { BundlePics, BundleGames } from './Bundle';
import { skuForCart } from '../helpers/sku';

export function CartProduct(props) {
  const {

    productType, // bundle

    sku,

    image_url,

    name,

    unit_items,
    activeDrm,
    activeDrmSku,
    handleDrmChange,
    content,
    disabled,
    price
  } = props;
  const { bundles, cartDeleteItem } = useContext(StoreContext);
  const [hide, setHide] = useState(false);

  const handle = (newDrm, newDrmSku) => {
    handleDrmChange(sku, newDrm, newDrmSku);
  };

  const handleDelete = (sku) => () => {
    setHide(true);
    cartDeleteItem(skuForCart(sku, productType, unit_items));
  };

  const getDrmName = (drm) => {
    let drmName = '';
    Boolean(unit_items) && unit_items.forEach((element) => {
      if (element.drm_sku === drm) drmName = element.drm_name;
    });
    return drmName;
  };

  return (
    <li className={`k_item ${disabled ? 'inactive' : ''} ${hide ? 'hide' : ''}`} style={{ width: '100%' }}>
      <div className="k_li">
        <div className="k_col1">
          <div className="k_pic0">

            <div data-good="pic" data-kart-good="image_url" className="k_pic">

              {productType === 'game' && (
                <div className="k_pic2">
                  <div
                    className="c_pic c_pic--cart"
                    style={{
                      backgroundImage: `url(${image_url})`
                    }}
                  >
                    <PicActions
                      type={productType}
                      sku={sku}
                      handleDelete={handleDelete(sku)}
                    />

                  </div>
                </div>
              )}

              {productType === 'bundle' && (
                <BundlePics
                  cart
                  content={content}
                >
                  <PicActions
                    type={productType}
                    sku={sku}
                    handleDelete={handleDelete(sku)}
                  />

                </BundlePics>
              )}

            </div>

          </div>

          <div data-good="name" data-kart-good="name" className="k_name0">
            <div data-good="name" data-kart-good="name" className="k_name">
              {name}
              {productType === 'game' && (
                <>
                  <br />
                  <span className="k_for">
                    for&nbsp;
                    {getDrmName(activeDrm)}
                  </span>
                </>
              )}
            </div>

            {/* //TODO: select platform in cart */}
            {/* {productType === 'game' && <SelectPlatform
              handle={handle}
              unit_items={unit_items}

              activeDrm={activeDrm}
              activeDrmSku={activeDrmSku}
            />} */}

            {productType === 'bundle' && (
              <BundleGames
                content={content}
              />
            )}

          </div>

          <div data-good="desc" data-kart-good="desc" className="k_desc" />
        </div>

        <div className="k_col2">
          {price.amount_without_discount != price.amount && (
            <div
              data-good="price"
              data-kart-good="amount"
              className="k_price k_li_price_old"
            >
              {formatCurrencyIso(price.currency)}
              {formatAmount(price.amount_without_discount)}
            </div>
          )}
          <div
            data-good="price"
            data-kart-good="amount"
            className="k_price"
            style={{ display: 'flex', width: 80 }}
          >
            {formatCurrencyIso(price.currency)}
            {formatAmount(price.amount)}
          </div>
        </div>
      </div>
    </li>
  );
}

export function PicActions({
  sku = '',
  type = 'game',
  handleDelete = () => void (0)
}) {
  return (
    <div className="c_pic_actions0">
      {type === 'game' && (
        <Link to={`/product/${sku.toLowerCase()}`} className="c_pic_action">
          Go to game
        </Link>
      )}
      <div
        className="c_game_delete w-embed"
        onClick={handleDelete}
      >
        <svg
          width={10}
          height={10}
          viewBox="0 0 10 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.197266 8.06402L3.54881 4.65154L0.217578 1.24921L1.42617 0L4.76755 3.40233L8.10894 0L9.31753 1.24921L5.9863 4.65154L9.33784 8.06402L8.12925 9.31323L4.76755 5.89059L1.40585 9.31323L0.197266 8.06402Z"
            fill="black"
          />
        </svg>
      </div>
    </div>
  );
}

export function SelectPlatform(props) {
  const {
    activeDrm, activeDrmSku, unit_items, handle = () => void 0
  } = {
    ...props
  };

  const ifActive = (sku) => (sku === activeDrm);
  const colorActive = '#fff';
  const color = '#8400c5';

  return (
    <div className="k_drms0">
      {unit_items && unit_items.map((oneItem, ind) => (
        <div
          key={`drm_${oneItem.drm_sku}`}
          className={`k_drm1 ${ifActive(oneItem.drm_sku) ? ' k_drm1--active' : ''
            }`}
          value={oneItem.drm_sku}
          onClick={() => handle(oneItem.drm_sku, oneItem.sku)}
        >
          {/* {oneItem['drm_sku']} */}
          <span>
            <Svg
              color={ifActive(oneItem.drm_sku) ? colorActive : color}
              width={18}
              height={18}
            >
              {icons[`platform_${oneItem.drm_sku}`]}
            </Svg>
          </span>
          {/* {ifActive(oneItem['drm_sku']) && oneItem['drm_name']} */}
        </div>
      ))}
    </div>
  );
}

export function Select(props) {
  const { activeDrm, unit_items, handle = () => void 0 } = { ...props };
  return (
    <select
      className="cart_select"
      value={activeDrm}
      onChange={(e) => handle(e.target.value)}
      name="cars"
      id="cars"
      disabled={unit_items.length === 1}
    >
      {unit_items && unit_items.map((oneItem, ind) => (
        <option value={oneItem.drm_sku}>{oneItem.drm_name}</option>
      ))}
    </select>
  );
}
