import React, {useState, useContext, useEffect} from 'react';
import styled from 'styled-components';

import { StoreContext } from './StoreContext';
import {checkoutCart, setPSSuccessCallback, isMobile} from '../api/cartApi';
import {parseJwt} from "../helpers/helpers";

const CART_EMAIL_INCORRECT = 'Please enter correct email';

const validate = (email) => {
  const pattern = new RegExp(
    /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+(@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+))*$/
  );
  return !!pattern.test(email);
};

export const OrderButton = ({ handleClear }) => {
  const {
    setCartError, userID
  } = useContext(StoreContext);

  const { loginToken } = useContext(StoreContext);
  const [email, setEmail] = useState('');
  const [isRequesting, setIsRequesting] = useState(false);

  const handleCheckout = async (e) => {
    e.preventDefault();
    if (isRequesting) {
      return;
    }
    setCartError('');
    if (!validate(email)) {
      setCartError(CART_EMAIL_INCORRECT);
      return;
    }

    setPSSuccessCallback(() => {
      handleClear();
    });

    const popup = isMobile() && window.open('', '_blank');

    setIsRequesting(true);
    await (async () => {
      await checkoutCart({
        email, popup, userID, loginToken
      });
      setIsRequesting(false);
    })();
  };

  useEffect(() => {
    if (loginToken) {
      setEmail(parseJwt(loginToken).email)
    }
  }, [setEmail, loginToken]);

  return (
    <CssEmail0 className="k_buttons0">
      <div className="k_bottom">
        <div className="k_login_email00">
          <div className="k_login_email0 w-embed">
            <input
              placeholder="Enter email to continue"
              value={email}
              className="k_input"
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              disabled={loginToken ? "disabled" : null}
            />
          </div>
          <div className="k_login_email_valid0">
            <div className="k_login_email_valid">
              <div>Your purchase will be delivered to this email</div>
            </div>
          </div>
        </div>
        <button
          className={`button_cart button_cart--checkout ${
            !validate(email) || isRequesting ? 'button_cart--disabled' : ''
          }`}
          onClick={handleCheckout}
          type="submit"
        >
          Order Now
        </button>
      </div>
    </CssEmail0>
  );
};

const CssEmail0 = styled.div`
  &:hover .k_login_email_valid {
    opacity: 1;
    transform: translate(0);
  }
`;
