import React from 'react';
import styled from 'styled-components';
import {Svg} from "../Icons/Svg";
import {icons} from "../Icons/icons";


export function Error({title}) {

  return (
    <StyledAgeError>
      <Svg
        width={60}
        height={60}
      >
        {icons["accessDenied"]}
      </Svg>
      <StyledTitle>{title}</StyledTitle>
    </StyledAgeError>
  );
}

const StyledAgeError = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const StyledTitle = styled.div`
  width: 100%;
  padding: 1em;
  font-size: 1.8rem;
  line-height: 160%;
  text-align: center;
  
  @media screen and (max-width: 991px) {
      font-size: 120%;
  }
  
  @media screen and (max-width: 767px) {
      margin-top: 0;
  }
  
  @media screen and (max-width: 479px) {
      font-size: 1rem;
  }
`;
