import React from 'react';
import styled from 'styled-components';


export function TitleH2({title}) {
  return (
    <StyledCTitleBlock>
      <StyledHeading3>{title}</StyledHeading3>
    </StyledCTitleBlock>
  )
}

const StyledCTitleBlock = styled.div`  
  width: 100%;
  margin-top: 0;
  padding-left: 0;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  text-transform: none;
  font-weight: 400;
  text-align: center;
  
  @media screen and (max-width: 991px) {
    margin-bottom: 0;
    padding-left: 0;
  }
`;

const StyledHeading3 = styled.h2`  
  margin-bottom: 20px;
  padding-left: 0;
  font-family: 'Codecpro ultra', sans-serif;
  font-size: 2.5em;
  line-height: 1.5em;
  text-transform: uppercase;
    
  @media screen and (max-width: 479px) {;
    line-height: 1.5em;
    padding-left: 0;
    text-align: center;
  }
`;