import React from 'react';
import styled, {keyframes} from 'styled-components';


export function Glitch(props) {
  return (
    <StyledGlitch data-glitch={props.text}>
      <StyleText >{props.text}</StyleText>
    </StyledGlitch>
  );
}

const glitchAnimation = keyframes`
  0% {transform: translate(0)}
  20% {transform: translate(-1.5px, 1.5px)}
  40% {transform: translate(-1.5px, -1.5px)}
  60% {transform: translate(1.5px, 1.5px)}
  80% {transform: translate(1.5px, -1.5px)}
  100% {transform:translate(0)}
`

const StyledGlitch = styled.div`
  position: relative;
  z-index: 3;
  display: block;
  font-weight: 700;
  cursor: pointer;
 
  &:hover:before {
      -webkit-animation: ${glitchAnimation} .6s cubic-bezier(.25,.46,.45,.94);
      animation: ${glitchAnimation} .6s cubic-bezier(.25,.46,.45,.94);
      animation-iteration-count: 5;
      animation-fill-mode: both;
  }
  
  &:hover:after {
      animation: ${glitchAnimation} .6s cubic-bezier(.25,.46,.45,.94);
      animation-iteration-count: 5;
      animation-direction: reverse;
      animation-fill-mode: both;
  }
  
  &:before {
      color: #0ff;
      z-index: 1;
  }
  
  &:after {
      color: #f0f;
      z-index: 2;
  }
  
  &:before, &:after {
      display: block;
      content: attr(data-glitch);
      text-transform: uppercase;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      opacity: .8;
  }    
  
  @media screen and (min-width: 1920px) {
      position: relative;
      order: 1;
  }
`;

const StyleText = styled.div`
    position: relative;
    z-index: 3;
    align-self: center;
    background-color: transparent;
    color: #fff;
    font-weight: 700;
    text-transform: uppercase;
    cursor: pointer;
`;