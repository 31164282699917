export const mergeData = (dataAT, dataP2P) => {
  // TODO отрефакторить
  const getFromP2p = (sku) => {
    let p2pSKU = false;
    dataP2P.forEach((oneP2P) => {
      if (oneP2P.sku === sku) {
        p2pSKU = oneP2P;
      }
    });
    return p2pSKU;
  };

  // AT first, p2p second
  return dataAT
    .map((oneSku) => {
      if (getFromP2p(oneSku.fields.sku)) {
        return {
          ...oneSku,
          fields: {
            ...oneSku.fields,
            ...getFromP2p(oneSku.fields.sku)
          }
        };
      }
      return null;
    })
    .filter((v) => v)
    .reverse();
};

export const skuWithoutDRM = (item) => {
  if (item.type === 'bundle') {
    return item.sku;
  }
  const length = item.sku.length - item.drm_sku.length - 1;
  return item.sku.substr(0, length);
};

export const skuForCart = (sku, itemType, unit_items) => {
  let skuForCart = sku;
  if (itemType === 'game') {
    skuForCart = unit_items[0].sku;
  }
  return skuForCart;
};
