import React, { Fragment } from "react";
import { ParamsLink } from "../ParamsLink";

export function Footer() {
  return (
    <div className="c c--footer">
      <div className="c_width c_width--100">
        <div className="c_paddings">
          <div className="foot--copyright">
            <ParamsLink
              href="https://www.team17.com/"
              target="_blank"
              className="foot_image_link w-inline-block"
            >
              <img
                src="https://assets.website-files.com/5e73f39d8f7c0b9c03c51853/5f61496e0c2bd6474800bc78_team17_footer.png"
                width={300}
                srcSet="https://assets.website-files.com/5e73f39d8f7c0b9c03c51853/5f61496e0c2bd6474800bc78_team17_footer-p-500.png 500w, https://assets.website-files.com/5e73f39d8f7c0b9c03c51853/5f61496e0c2bd6474800bc78_team17_footer-p-800.png 800w, https://assets.website-files.com/5e73f39d8f7c0b9c03c51853/5f61496e0c2bd6474800bc78_team17_footer-p-1080.png 1080w, https://assets.website-files.com/5e73f39d8f7c0b9c03c51853/5f61496e0c2bd6474800bc78_team17_footer-p-1600.png 1600w, https://assets.website-files.com/5e73f39d8f7c0b9c03c51853/5f61496e0c2bd6474800bc78_team17_footer-p-2000.png 2000w, https://assets.website-files.com/5e73f39d8f7c0b9c03c51853/5f61496e0c2bd6474800bc78_team17_footer-p-2600.png 2600w, https://assets.website-files.com/5e73f39d8f7c0b9c03c51853/5f61496e0c2bd6474800bc78_team17_footer.png 2685w"
                sizes="(max-width: 479px) 200px, 180px"
                className="foot_image"
              />
            </ParamsLink>
          </div>

          <div className="foot--copyright last">
            <div className="f_left">
              <div className="f_txt_copyright">
                <div id="txt_footer_privacy">
                  Team17 store is powered by{" "}
                  <a href="https://xsolla.com/products/site-builder" target="_blank" rel="noopener noreferrer">
                    Xsolla Site&nbsp;Builder
                  </a>
                </div>
              </div>
            </div>

            <div className="f_center">
              <div className="f_txt_copyright">
                <div id="txt_footer_privacy">team17 2021 ©</div>
              </div>
            </div>
            <div className="f_right">
              <a
                href="https://www.team17.com/legal/"
                target="_blank"
                rel="noopener noreferrer"
                className="f_link border w-inline-block"
              >
                <div id="txt_footer_privacy">terms &amp; conditions</div>
              </a>
              <a
                href="https://www.team17.com/privacy-policy/"
                target="_blank"
                rel="noopener noreferrer"
                className="f_link no-border w-inline-block"
              >
                <div id="txt_footer_privacy">cookie policy</div>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="footer_bgz" />
    </div>
  );
}

export const FooterMainSite = () => (
  <Fragment>
    <div className="foot--copyright _50">
      <div className="div-block-4">
        <a href="!#" className="f_link w-inline-block">
          <div id="txt_footer_privacy">games</div>
        </a>
        <a href="!#" className="f_link w-inline-block">
          <div id="txt_footer_privacy">store</div>
        </a>
        <a
          href="https://www.team17.com/news/"
          target="_blank"
          rel="noopener noreferrer"
          className="f_link w-inline-block"
        >
          <div id="txt_footer_privacy">news</div>
        </a>
        <a
          href="https://www.team17.com/about-us/"
          target="_blank"
          rel="noopener noreferrer"
          className="f_link w-inline-block"
        >
          <div id="txt_footer_privacy">about</div>
        </a>
        <a href="!#" className="f_link w-inline-block">
          <div id="txt_footer_privacy">user research</div>
        </a>
      </div>
      <div className="div-block-5">
        <a
          href="https://www.team17.com/submit-a-game/"
          target="_blank"
          rel="noopener noreferrer"
          className="f_link w-inline-block"
        >
          <div id="txt_footer_privacy">submit game</div>
        </a>
        <a
          href="https://www.team17.com/careers/"
          target="_blank"
          rel="noopener noreferrer"
          className="f_link w-inline-block"
        >
          <div id="txt_footer_privacy">join us</div>
        </a>
        <a
          href="https://www.team17group.com/"
          target="_blank"
          rel="noopener noreferrer"
          className="f_link w-inline-block"
        >
          <div id="txt_footer_privacy">investors</div>
        </a>
        <a
          href="https://www.team17.com/contact/"
          target="_blank"
          rel="noopener noreferrer"
          className="f_link w-inline-block"
        >
          <div id="txt_footer_privacy">contact</div>
        </a>
        <a
          href="https://www.team17.com/support/"
          target="_blank"
          rel="noopener noreferrer"
          className="f_link w-inline-block"
        >
          <div id="txt_footer_privacy">support</div>
        </a>
        <a href="!#" className="f_link last w-inline-block">
          <div id="txt_footer_privacy">press</div>
        </a>
      </div>
    </div>

    <div className="foot--copyright up">
      <a
        href="https://www.facebook.com/Team17/"
        target="_blank"
        rel="noopener noreferrer"
        className="f_social-media_link w-inline-block"
      >
        <img src="https://assets.website-files.com/5e73f39d8f7c0b9c03c51853/5f2cf664c61165581b1f9dba_facebook.svg" />
      </a>
      <a
        href="https://twitter.com/Team17Ltd"
        target="_blank"
        rel="noopener noreferrer"
        className="f_social-media_link w-inline-block"
      >
        <img src="https://assets.website-files.com/5e73f39d8f7c0b9c03c51853/5f2cf1faa241abb1701ebab4_twitter.svg" />
      </a>
      <a
        href="https://www.instagram.com/team17ltd/"
        target="_blank"
        rel="noopener noreferrer"
        className="f_social-media_link w-inline-block"
      >
        <img
          src="https://assets.website-files.com/5e73f39d8f7c0b9c03c51853/5f310c4cdb97620632f3ea44_instagram.svg"
          width={23}
          height={23}
        />
      </a>
      <a
        href="https://www.youtube.com/user/Team17SoftwareLtd"
        target="_blank"
        rel="noopener noreferrer"
        className="f_social-media_link w-inline-block"
      >
        <img src="https://assets.website-files.com/5e73f39d8f7c0b9c03c51853/5f2cf6b16f4a7828150f3b2b_youtube.svg" />
      </a>
      <a
        href="https://discord.com/invite/team17"
        target="_blank"
        rel="noopener noreferrer"
        className="f_social-media_link w-inline-block"
      >
        <img src="https://assets.website-files.com/5e73f39d8f7c0b9c03c51853/5f2cf6d56880fe09e543a7e4_Discord.svg" />
      </a>
      <a
        href="https://www.twitch.tv/team17ltd"
        target="_blank"
        rel="noopener noreferrer"
        className="f_social-media_link w-inline-block"
      >
        <img src="https://assets.website-files.com/5e73f39d8f7c0b9c03c51853/5f2cf271793f144b21a5266e_twitch.svg" />
      </a>
      <a
        href="mailto:team17@vigocomms.com"
        className="f_social-media_link w-inline-block"
      >
        <img src="https://assets.website-files.com/5e73f39d8f7c0b9c03c51853/5f2cf70854df617c9fbaac6d_email.svg" />
      </a>
    </div>
  </Fragment>
);
