import React from 'react';
import {AccountTitle} from './AccountTitle'
import {AccountRowWrap} from './AccountRowWrap'
import {AccountRowSet} from './AccountRowSet'
import {SupportInfo} from '../SupportInfo'
import {IconLink} from '../IconLink'

export function AccountSupport() {

  return (
    <AccountRowWrap>
      <AccountTitle title={'support'}/>
      <AccountRowSet>
        <SupportInfo title={"If you have any troubles, feel free to "}>
          <IconLink link={"https://help.xsolla.com/"} text={" contact us"} />
        </SupportInfo>
      </AccountRowSet>
    </AccountRowWrap>
  );
}
