import jwt_decode from "jwt-decode";

export const getCookie = (name) => {
  const matches = document.cookie.match(new RegExp(
    `(?:^|; )${name.replace(/([.$?*|{}()[\]\\/+^])/g, '\\$1')}=([^;]*)`
  ));
  return matches ? decodeURIComponent(matches[1]) : undefined;
};

export const parseJwt = (token) => {
  if (token === "null") {
    return false;
  }

  return jwt_decode(token)
};

export const getEnDate = (unixTime) => {
  return new Date(+unixTime * 1000).toLocaleDateString("en-US")
}