import React, {
  useState, useContext, useEffect
} from 'react';

import { Link } from 'react-router-dom';
import { StoreContext } from './StoreContext';
import { Svg } from './Icons/Svg';
import { icons } from './Icons/icons';
import {
  getAmount,
  getAmountWithoutDiscount,
  formatAmount,
  checkStock,
  checkPreOrder,
  checkIfHasKeys,
  getCurrencyIso,
  checkBundlePrice,
  formatCurrencyIso,
  checkIfHasDiscount
} from './tools/buyNowLogic';
import { checkIfInCart } from './tools/getData';
import { skuForCart } from '../helpers/sku';
import { sendClick } from '../helpers/analytics';

export function Button(props) {
  const {
    type = 'game',
    sku,
    template = 'lg'
  } = { ...props };

  return (
    <>
      <ButtonCart itemType={type} {...props} />
      <Link
        to={`/product/${sku.toLowerCase()}`}
        className={`but but--2 but--2--${template}`}
        style={{ textDecoration: 'none' }}
      >
        {['lg', 'slider2'].includes(template) && <span className={`but_buy but_buy--${template}`}>Explore game</span>}
        <div className={`but_cta but_cta--more but_cta--${template}`}>
          <div className="w-embed">
            <svg
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.3636 5.2636C12.0121 5.61508 12.0121 6.18492 12.3636 6.5364L16.7272 10.9H5C4.44772 10.9 4 11.3477 4 11.9C4 12.4523 4.44772 12.9 5 12.9H16.7272L12.3636 17.2636C12.0121 17.6151 12.0121 18.1849 12.3636 18.5364C12.7151 18.8879 13.2849 18.8879 13.6364 18.5364L19.6364 12.5364C19.9879 12.1849 19.9879 11.6151 19.6364 11.2636L13.6364 5.2636C13.2849 4.91213 12.7151 4.91213 12.3636 5.2636Z"
                fill="white"
              />
            </svg>
          </div>
        </div>
      </Link>
    </>
  );
}

export const ButtonCart = (props) => {
  const {
    cart, setCartShown, cartDeleteItem, addItemToCart
  } = useContext(StoreContext);

  const {
    itemType = 'game',
    template = 'lg',
    children,
    unit_items,
    bundle_price,
    sku,
    isBlockedGame,
  } = { ...props };

  const itemIsBundle = itemType === 'bundle';
  const hasBundlePrice = itemIsBundle && bundle_price;
  const [itemInCart, setItemInCart] = useState(false);

  const handleCart = (sku) => {
    const itemSKU = skuForCart(sku, itemType, unit_items);
    if (!checkIfInCart(cart.items, itemSKU)) {
      addItemToCart(itemSKU);
      sendClick('sb-team17_store_add-item', sku, { source: 'store' });
    }
    setCartShown(true);
    sendClick('sb-team17_cart_show', 'item');
  };

  const handleDelete = (sku) => {
    cartDeleteItem(sku);
  };

  const itemCurrency = hasBundlePrice
    ? formatCurrencyIso(bundle_price.currency)
    : formatCurrencyIso(getCurrencyIso(unit_items));

  useEffect(() => {
    setItemInCart(checkIfInCart(cart.items, skuForCart(sku, itemType, unit_items)));
  }, [cart.items, itemType, sku, unit_items]);
  return (
    <div
      onClick={() => (checkBundlePrice(itemType, bundle_price) && (checkIfHasKeys(unit_items) || checkPreOrder(unit_items)) && !isBlockedGame
        ? handleCart(sku)
        : void 0)}
      className={`js-slider-stop but but--k but--k--${template}
        ${itemInCart ? 'but--k--active' : ''}
        ${
          !checkBundlePrice(itemType, bundle_price) || (!checkStock(unit_items) && !checkPreOrder(unit_items))
            ? 'but--k--out'
            : ''
        }
        ${isBlockedGame ? 'but--banned' : ''}
      `}
    >
      {!itemInCart && (
        <>
          {hasBundlePrice ? (
            <>
              {itemCurrency}
              {formatAmount(bundle_price.amount)}
              {checkIfHasDiscount(bundle_price) && (
                <div className="but_price but_price--old">
                  {itemCurrency}
                  {formatAmount(bundle_price.amount_without_discount)}
                </div>
              )}
            </>
          ) : (
            <>
              {itemCurrency}
              {formatAmount(getAmount(unit_items))}
              {Boolean(getAmountWithoutDiscount(unit_items)) && (
                <div className="but_price but_price--old">
                  {itemCurrency}
                  {formatAmount(getAmountWithoutDiscount(unit_items))}
                </div>
              )}
            </>
          )}

          &nbsp;
          {['lg', 'slider2', 'small'].includes(template) && (
            <>
              <span
                className={`but_buy1 but_buy--${template} ${
                  !checkBundlePrice(itemType, bundle_price) && !checkStock(unit_items) ? 'but--buy--disabled' : ''
                }`}
              >

                &nbsp;
                {checkPreOrder(unit_items) && 'Pre-order'}

                {!checkPreOrder(unit_items)
                  && checkBundlePrice(itemType, bundle_price) && checkStock(unit_items)
                  && 'Buy now'}

                {!checkPreOrder(unit_items)
                  && checkBundlePrice(itemType, bundle_price)
                  && !checkStock(unit_items)
                  && 'Out of stock'}
              </span>
            </>
          )}
        </>
      )}

      {itemInCart && 'Check out'}

      {checkBundlePrice(itemType, bundle_price) && (checkIfHasKeys(unit_items) || checkPreOrder(unit_items)) && (
        <div className={`but_cta but_cta--${template}`}>
          <CartIcon template={template} />
        </div>
      )}

      {children}
    </div>
  );
};

export const CartIcon = ({ template }) => (

  <div className="w-embed">
    {template !== 'small' && (
    <div className="but_k_icon_web">
      <Svg width={24} height={24} color="white">
        {icons.cart}
      </Svg>
    </div>
    )}

    {template === 'small' && (
    <div className="but_k_icon_web">
      <Svg width={18} height={18} color="white">
        {icons.cart}
      </Svg>
    </div>
    )}

    <div className="but_k_icon_mob">
      <Svg width={20} height={20} color="white">
        {icons.cart}
      </Svg>
    </div>
  </div>
);

export const CartIconClassic = () => (
  <div className="w-embed">
    <svg
      width={18}
      height={14}
      viewBox="0 0 18 14"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>Cart</title>
      <g id="Canvas" transform="translate(2183 241)">
        <g id="Cart">
          <use
            xlinkHref="#path0_fill"
            transform="translate(-2183 -241)"
            fill="#FFFFFF"
          />
        </g>
      </g>
      <defs>
        <path
          id="path0_fill"
          fillRule="evenodd"
          d="M 15.8943 1.86667L 15.0984 3.93654C 15.0879 3.96393 15.0759 3.99048 15.063 4.01614L 13.2 9.584C 13.0635 9.99178 12.6816 10.2667 12.2517 10.2667L 3.85327 10.2667C 3.42334 10.2667 3.0415 9.99178 2.90503 9.584L 0.947266 3.73334L 0.600098 3.73334C 0.268555 3.73334 0 3.46471 0 3.13333L 0 2.46667C 0 2.1353 0.268555 1.86667 0.600098 1.86667L 14.073 1.86667L 14.572 0.568527C 14.6255 0.429626 14.7114 0.312469 14.8181 0.222397C 14.9282 0.0867157 15.0959 0 15.2844 0L 17.4001 0C 17.7314 0 18 0.268631 18 0.600006L 18 1.26666C 18 1.59804 17.7314 1.86667 17.4001 1.86667L 15.8943 1.86667ZM 12.5881 3.73334L 3.51685 3.73334C 3.18115 3.73334 2.94092 4.05775 3.03857 4.37898L 4.04736 7.69131C 4.17554 8.1123 4.56372 8.40001 5.00391 8.40001L 11.1013 8.40001C 11.5413 8.40001 11.9297 8.1123 12.0579 7.69131L 13.0664 4.37898C 13.1643 4.05775 12.9241 3.73334 12.5881 3.73334ZM 2.84204 12.6C 2.84204 11.8268 3.46875 11.2 4.24194 11.2L 4.28418 11.2C 5.05737 11.2 5.68408 11.8268 5.68408 12.6C 5.68408 13.3732 5.05737 14 4.28418 14L 4.24194 14C 3.46875 14 2.84204 13.3732 2.84204 12.6ZM 10.4211 12.6C 10.4211 11.8268 11.0479 11.2 11.821 11.2L 11.8633 11.2C 12.6365 11.2 13.2632 11.8268 13.2632 12.6C 13.2632 13.3732 12.6365 14 11.8633 14L 11.821 14C 11.0479 14 10.4211 13.3732 10.4211 12.6Z"
        />
      </defs>
    </svg>
  </div>
);
