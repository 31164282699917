import React from 'react';
import styled from 'styled-components';


export function UserImage(props) {
  return (
    <StyledImage {...props} loading="lazy" alt="" className="user_img" />
  );
}

const StyledImage = styled.div`
  width: 24px;
  height: 24px;
  margin-right: 10px;
  border-radius: 20px;
  background-image: url(${(props)=> props.picture});
  background-size: contain;
`;