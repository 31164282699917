import React, {
  Fragment,
  useEffect,
  useState,
  useContext,
  useRef
} from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { Tags } from './Tags';
import { StoreContext } from './StoreContext';
import { searchProductsByReleased, searchProductsByType } from './Grid';
import { FilterCategories } from './FilterCategories';

export function FilterBar(props) {
  const {
    searchTerm,
    filterval = '',
    filterRel,
    filterTyp,

    productsForCats = [],
    productsForReleased = [],
    productsForType = [],
    handleSearch,
    handleGenre,
    handleType,
    handleReleased
  } = {
    ...props
  };

  const [search, setSearch] = useState(searchTerm);
  const [genre, setGenre] = useState(filterval);
  const [released, setReleased] = useState(filterRel);
  const [type, setType] = useState(filterTyp);

  const searchInput = useRef();

  useEffect(() => {
    if (searchTerm !== search) setSearch('');
  }, [searchTerm]);

  useEffect(() => {
    if (filterval !== genre) setGenre('');
  }, [filterval]);

  useEffect(() => {
    if (filterRel !== released) setReleased('');
  }, [filterRel]);

  useEffect(() => {
    if (filterTyp !== type) setType('');
  }, [filterTyp]);

  useEffect(() => {
    if (typeof search !== 'undefined') setSearchText(search);
  }, [search]);

  useEffect(() => {
    if (typeof genre !== 'undefined') handleGenre(genre);
  }, [genre]);

  useEffect(() => {
    if (typeof type !== 'undefined') handleType(type);
  }, [type]);

  useEffect(() => {
    if (typeof released !== 'undefined') handleReleased(released);
  }, [released]);

  const setSearchText = (searchText) => {
    handleSearch(searchText);
  };

  return (
    <div className="cc cc--sticky">
      <div className="c_width">
        <div id="sticker" className="f">
          <div className="ff">
            <div className="m_filters-form w-form">
              <div
                id="email-form"
                name="email-form"
                data-name="Email Form"
                className="f_filters"
              >

                <div className="f_filter-block">

                  <div className="f_filter-field">
                    <div className="f_input-label">find game</div>

                    <div className="f_filter-input0">
                      <input
                        ref={searchInput}
                        className={`f_filter-input f_filter-input--search ${
                          search ? 'f_filter-input--active' : ''
                        }`}
                      // placeholder="Search"
                        value={typeof search === 'undefined' ? '' : search}
                        onChange={(e) => setSearch(e.target.value)}
                      />

                      <div className="input-icon f_filter_search_icon f_filter_search_icon--active">
                        <svg
                          width={16}
                          height={16}
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M6 12C2.68629 12 0 9.31371 0 6C0 2.68629 2.68629 0 6 0C9.31371 0 12 2.68629 12 6C12 7.29583 11.5892 8.49572 10.8907 9.47653L14.9667 13.5525C15.3572 13.943 15.3572 14.5762 14.9667 14.9667C14.5762 15.3572 13.943 15.3572 13.5525 14.9667L9.47653 10.8907C8.49572 11.5892 7.29583 12 6 12ZM6 10.6C8.54051 10.6 10.6 8.54051 10.6 6C10.6 3.45949 8.54051 1.4 6 1.4C3.45949 1.4 1.4 3.45949 1.4 6C1.4 8.54051 3.45949 10.6 6 10.6Z"
                            fill="currentColor"
                          />
                        </svg>
                      </div>

                      {Boolean(search) && (
                      <div
                        className="f_filter_clear"
                        onClick={() => setSearch('')}
                      >
                        <svg
                          width={17}
                          height={17}
                          viewBox="0 0 17 17"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M8.5 17C13.1944 17 17 13.1944 17 8.5C17 3.80558 13.1944 0 8.5 0C3.80558 0 0 3.80558 0 8.5C0 13.1944 3.80558 17 8.5 17ZM5.18359 12.5677L8.49999 9.2513L11.8164 12.5677C12.0239 12.7752 12.3602 12.7752 12.5677 12.5677C12.7752 12.3602 12.7752 12.0239 12.5677 11.8164L9.2513 8.49999L12.5677 5.18359C12.7752 4.97613 12.7752 4.63976 12.5677 4.43229C12.3602 4.22482 12.0239 4.22482 11.8164 4.43229L8.49999 7.74869L5.18359 4.43229C4.97613 4.22482 4.63976 4.22482 4.43229 4.43229C4.22482 4.63976 4.22482 4.97613 4.43229 5.18359L7.74869 8.49999L4.43229 11.8164C4.22482 12.0239 4.22482 12.3602 4.43229 12.5677C4.63976 12.7752 4.97613 12.7752 5.18359 12.5677Z"
                            fill="currentColor"
                          />
                        </svg>
                      </div>
                      )}
                    </div>
                  </div>

                  <div className="f_filter-field">
                    <div className="f_input-label">by genre</div>

                    <div className="f_filter-input0">
                      <select
                        id="by-genre"
                        name="by-genre"
                        data-name="by-genre"
                        className={`f_filter-input w-select ${
                          genre ? 'f_filter-input--active' : ''
                        }`}
                        value={genre || ''}
                        onChange={(e) => {
                          setGenre(e.target.value);
                        }}
                      >
                        <FilterCategories
                          productsForCats={productsForCats}
                          all="All games"
                          textSearch={search}
                        />
                      </select>

                      {!genre && (
                      <div className="f_filter_clear w-embed input-icon">
                        <svg
                          width={8}
                          height={12}
                          viewBox="0 0 8 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M1.00507 4.50503C1.27844 4.7784 1.72166 4.7784 1.99502 4.50503L4.00005 2.50001L6.00507 4.50503C6.27844 4.7784 6.72166 4.7784 6.99502 4.50503C7.26839 4.23167 7.26839 3.78845 6.99502 3.51508L4.49502 1.01508C4.22166 0.741717 3.77844 0.741717 3.50507 1.01508L1.00507 3.51508C0.731707 3.78845 0.731707 4.23167 1.00507 4.50503ZM6.99502 7.49498C6.72166 7.22162 6.27844 7.22162 6.00507 7.49498L4.00005 9.50001L1.99502 7.49498C1.72166 7.22162 1.27844 7.22162 1.00507 7.49498C0.731707 7.76835 0.731707 8.21157 1.00507 8.48493L3.50507 10.9849C3.77844 11.2583 4.22166 11.2583 4.49502 10.9849L6.99502 8.48493C7.26839 8.21157 7.26839 7.76835 6.99502 7.49498Z"
                            fill="currentColor"
                          />
                        </svg>
                      </div>
                      )}

                      {Boolean(genre) && (
                      <div
                        className="f_filter_clear w-embed"
                        onClick={() => setGenre('')}
                      >
                        <svg
                          width={17}
                          height={17}
                          viewBox="0 0 17 17"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M8.5 17C13.1944 17 17 13.1944 17 8.5C17 3.80558 13.1944 0 8.5 0C3.80558 0 0 3.80558 0 8.5C0 13.1944 3.80558 17 8.5 17ZM5.18359 12.5677L8.49999 9.2513L11.8164 12.5677C12.0239 12.7752 12.3602 12.7752 12.5677 12.5677C12.7752 12.3602 12.7752 12.0239 12.5677 11.8164L9.2513 8.49999L12.5677 5.18359C12.7752 4.97613 12.7752 4.63976 12.5677 4.43229C12.3602 4.22482 12.0239 4.22482 11.8164 4.43229L8.49999 7.74869L5.18359 4.43229C4.97613 4.22482 4.63976 4.22482 4.43229 4.43229C4.22482 4.63976 4.22482 4.97613 4.43229 5.18359L7.74869 8.49999L4.43229 11.8164C4.22482 12.0239 4.22482 12.3602 4.43229 12.5677C4.63976 12.7752 4.97613 12.7752 5.18359 12.5677Z"
                            fill="currentColor"
                          />
                        </svg>
                      </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="f_filter-block">
                  <FilterReleased productsForReleased={productsForReleased} released={released} setReleased={setReleased} />
                  <FilterType productsForType={productsForType} type={type} setType={setType} />
                </div>

              </div>
              <div className="w-form-done">
                <div>Thank you! Your submission has been received!</div>
              </div>
              <div className="w-form-fail">
                <div>Oops! Something went wrong while submitting the form.</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export const FilterReleased = ({ productsForReleased, released, setReleased }) => {
  const context = useContext(StoreContext);
  return (
    <div className="f_filter-field">
      <div className="f_input-label">release</div>
      <div className="f_radio0">
        <div
          className={`f_radio ${!released && 'f_radio--active'} `}
          onClick={() => setReleased('')}
        >
          <div className="f_radio_label">
            <div>all</div>
          </div>
        </div>

        <div
          className={`f_radio ${released === 'buynow' && 'f_radio--active'} `}
          onClick={() => setReleased('buynow')}
        >
          Released
&nbsp;-&nbsp;
          {searchProductsByReleased(productsForReleased, 'buynow', context).length}
        </div>

        <div
          className={`f_radio ${released === 'preorder' && 'f_radio--active'} `}
          onClick={() => setReleased('preorder')}
        >
          Coming soon - &nbsp;
          {searchProductsByReleased(productsForReleased, 'preorder', context).length}
        </div>
      </div>
    </div>
  );
};

export const FilterType = ({ productsForType, type, setType }) => {
  const context = useContext(StoreContext);

  return (
    <div className="f_filter-field">
      <div className="f_input-label">Type</div>
      <div className="f_radio0">
        <div
          className={`f_radio ${!type && 'f_radio--active'} `}
          onClick={() => setType('')}
        >
          <div className="f_radio_label">
            <div>all</div>
          </div>
        </div>

        <div
          className={`f_radio ${
            type === 'game' && 'f_radio--active'
          } `}
          onClick={() => setType('game')}
        >
          Game
&nbsp;-&nbsp;
          {searchProductsByType(productsForType, 'game', context).length}
        </div>

        <div
          className={`f_radio ${
            type === 'dlc' && 'f_radio--active'
          } `}
          onClick={() => setType('dlc')}
        >
          DLC
&nbsp;-&nbsp;
          {searchProductsByType(productsForType, 'dlc', context).length}
        </div>

        <div
          className={`f_radio ${
            type === 'other' && 'f_radio--active'
          } `}
          onClick={() => setType('other')}
        >
          Other
&nbsp;-&nbsp;
          {searchProductsByType(productsForType, 'other', context).length}
        </div>
      </div>
    </div>
  );
};
