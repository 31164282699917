import React, {useContext} from 'react';
import {AccountTitle} from './AccountTitle'
import {AccountRowWrap} from './AccountRowWrap'
import {AccountRowSet} from './AccountRowSet'
import {SettingsButton} from '../SettingsButton'
import {SettingsInfo} from '../SettingsInfo'
import {StoreContext} from "../StoreContext";

export function AccountInfo() {

  const {userProfile} = useContext(StoreContext);
  const {setLoginWidgetShown} = useContext(StoreContext);

  function openWidgetHandler() {
    setLoginWidgetShown("resetPas")
  }

  return (
    <AccountRowWrap>
      <AccountTitle title={'Account info'}/>
      {userProfile.email && (
        <AccountRowSet>
          <SettingsInfo title={"Email address"} titleValue={userProfile.email} />
        </AccountRowSet>
      )}
      <AccountRowSet direction={"column"}>
        <SettingsInfo title={"Password"} titleValue={'•••••••'} />
        <SettingsButton text={"edit"} type={"edit"} onClick={openWidgetHandler} cursor={"pointer"} />
      </AccountRowSet>
    </AccountRowWrap>
  );
}
