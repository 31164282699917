import React from 'react';
import ReactDOM from 'react-dom';
import GameStore from './GameStore/GameStore';
import { StoreProvider } from './GameStore/StoreContext';
import {initAnalytics} from './helpers/analytics';

import './GameStore/team17.css';
import './index.css';

const App = () => (
  <React.StrictMode>
    <StoreProvider>
      <div className="bg">
        <GameStore />
      </div>
    </StoreProvider>
  </React.StrictMode>
);

initAnalytics()
ReactDOM.render(<App />, document.getElementById('root'));
