import React, { useContext, useState, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import Product from './Product';
import { getData, filterProducts } from './tools/getData';
import { StoreContext } from './StoreContext';
import ProductList from './ProductList';
import { Slide } from './Slider';
import { Menu } from './Menu';
import { Pagination } from './Pagination';
import { FilterBar } from './FilterBar';
import { ProductAppearer } from './ProductAppearer';
import { Bundle } from './Bundle';
import { checkPreOrder, checkStock } from './tools/buyNowLogic';
import { GridZeroState } from './GridZeroState';

export const searchProductsByText = (arr, searchTerm, context) => {
  if (searchTerm) {
    arr = arr.filter((one) => getData(context.skus, one.fields.ref_sku[0])
      .name.toLowerCase()
      .includes(searchTerm.toLowerCase()));
  }
  return arr;
};

export const searchProductsByType = (arr, searchType, context) => {
  if (searchType) {
    arr = arr.filter(
      (one) => getData(context.skus, one.fields.ref_sku[0]).item_type === searchType
    );
  }
  return arr;
};

export const searchProductsByReleased = (arr, searchRel, context) => {
  if (searchRel) {
    arr = arr.filter((one) => {
      let ret = false;
      let preOrd = getData(context.skus, one.fields.ref_sku[0]);
      preOrd = checkPreOrder(preOrd.unit_items) || !checkStock(preOrd.unit_items);
      ret = !!(searchRel === 'preorder' && preOrd);
      ret = searchRel === 'buynow' && !preOrd ? true : ret;

      return ret;
    });
  }
  return arr;
};

export function Grid(props) {
  const {
    route,

    blockId,
    template,
    limit,
    show_more,
    blockType,
    title,
    unique,
    configurable,

    fixed_products = [],

    filter_key,
    filter_val,

    filters
  } = { ...props };

  const grid = useRef(null);
  const [page, setPage] = useState(show_more === 'pagination' ? 1 : 0);
  const [bundlePage, setBundlePage] = useState(1);
  const [searchTerm, setsearchTerm] = useState();
  const [filterkey, setFilterkey] = useState(filter_key);
  const [filterval, setFilterval] = useState(filter_val);
  const [filterRel, setFilterRel] = useState();
  const [filterTyp, setFilterTyp] = useState();

  const applyTextSearch = (newSearch) => {
    if (searchTerm !== newSearch) {
      setsearchTerm(newSearch);
    } else {
      setsearchTerm('');
    }
    setPage(1);
    if (newSearch) onPagination();
  };

  const applyGenre = (newGenre) => {
    if (newGenre) {
      setFilterkey('genre');
      setFilterval(newGenre);
    } else {
      setFilterkey();
      setFilterval('');
    }

    setPage(1);
    // onPagination();
  };

  const applyItemTypeFilter = (newTyp) => {
    setFilterTyp(newTyp);
    setPage(1);
    onPagination();
  };
  const applyFilterByRelease = (newReleased) => {
    setFilterRel(newReleased);
    setPage(1);
    onPagination();
  };

  const drawFilters = () => (
    <FilterBar
      searchTerm={searchTerm}
      filterval={filterval}
      filterRel={filterRel}
      filterTyp={filterTyp}
      productsForCats={filteredProducts(
        null,
        null,
        searchTerm,
        filterTyp,
        filterRel
      )}
      productsForReleased={filteredProducts(
        filterkey,
        filterval,
        searchTerm,
        filterTyp,
        null
      )}
      productsForType={filteredProducts(
        filterkey,
        filterval,
        searchTerm,
        null,
        filterRel
      )}
      // search={searchTerm['search']}
      handleSearch={applyTextSearch}
      handleGenre={applyGenre}
      handleType={applyItemTypeFilter}
      handleReleased={applyFilterByRelease}
    />
  );

  const context = useContext(StoreContext);
  const [lim, setLim] = useState(limit);

  const paginate = (array, limit, page) => {
    if (!page) {
      array.length = limit && limit < array.length ? limit : array.length;
      return array;
    }
    if (!page) {
      const newArray = [...array];
      newArray.length = limit && limit < array.length ? limit : array.length;
      return newArray;
    }

    return array.slice(limit * page - limit, limit * page);
  };

  const filteredProducts = (
    filterkey,
    filterval,
    searchTerm,
    filterTyp,
    filterRel
  ) => {
    let arr = filterProducts(
      context.products,
      context.skus,
      context.tags,
      filterkey,
      filterval
    );

    arr = filterTyp ? searchProductsByType(arr, filterTyp, context) : arr;

    arr = filterRel ? searchProductsByReleased(arr, filterRel, context) : arr;

    arr = searchTerm ? searchProductsByText(arr, searchTerm, context) : arr;

    if (fixed_products.length) {
      arr = fixed_products.map((one) => getData(context.products, one, null, true)).filter(Boolean);
    }
    return arr;
  };

  const onPagination = () => {
    window.scrollTo({
      // TODO: speed scroll
      top: grid.current.offsetTop,
      left: 0,
      behavior: 'smooth'
    });
  };

  // const [productsSelect, setProductsSelect] = useState(false);

  return (
    <>
      <CssGrid
        key={`grid_${blockId}}`}
        ref={grid}
        className={`c c--${template}`}
        shown={false}
      >
        {blockType === 'menu_single' && (
          <Menu active={filterval || 'genre'} tags={filterkey} />
        )}
        {blockType === 'products' && (
          <ProductList
            blockType={blockType}
            {...props}
            limit={limit}
            title={
              searchTerm
                ? // ? <Fragment><span style='opacity: 0.6'>Search:</span> {searchTerm['search']}</Fragment>
                `Search: ${searchTerm}`
                : title
            }
            template={template}
            show_more={show_more}
            setLim={setLim}
            lim={lim}
            filters={filters ? drawFilters() : null}
          >
            {context.skus
              && context.products
              && paginate(
                filteredProducts(
                  filterkey,
                  // filterval = searchTerm['genres'][0] ? searchTerm['genres'][0] : filterval,
                  filterval,
                  searchTerm,
                  filterTyp,
                  filterRel
                ),
                limit,
                page
              ).map((one, ind) => {
                // filteredProducts().map((one, ind) => {

                // let id = context["skus"][ind]["id"];
                const productId = one.id;
                const oneProduct = { ...one.fields };
                const oneSku = getData(context.skus, oneProduct.ref_sku[0]);
                const ageRestriction = context.ageGateTable.get(oneSku.landing_page_url)
                const isBlockedGame = ageRestriction && (context.ageGate === null || Number(context.ageGate) < ageRestriction);
                if (
                  [
                    'lg',
                    'small'
                    // 'slider2',
                    // 'micro', 'sale', 'featured', 'bundles'
                  ].includes(template)
                ) {
                  // setAppeared([...appeared, productId])
                  return (
                    <ProductAppearer
                      template={template}
                      order={ind}
                      key={`tag_${productId}`}
                    >
                      <Product
                        // template={filteredProducts.length <= 2 ? 'feat' : template}
                        template={template}
                        // key={`tag_${ind}_${id}`}
                        productId={productId}
                        // id={id}
                        {...oneSku}
                        {...oneProduct}
                        isBlockedGame={isBlockedGame}
                        limit={limit}
                      />
                    </ProductAppearer>
                  );
                }

                if (['slider2'].includes(template)) {
                  return (
                    <Slide
                      key={`tag_slide_${productId}`}
                      {...oneSku}
                      template={template}
                      blockType={blockType}
                    >
                      <Product
                        // template={filteredProducts.length <= 2 ? 'feat' : template}
                        template={template}
                        // key={`tag_${ind}_${id}`}
                        // key={`tag_${uniqid()}_${productId}`}
                        productId={productId}
                        // id={id}
                        {...oneSku}
                        {...oneProduct}
                        limit={limit}
                      />
                    </Slide>
                  );
                }
              })}
          </ProductList>
        )}

        {blockType === 'bundles' && (
          <ProductList
            blockType={blockType}
            {...props}
            limit={limit}
            title={
              searchTerm
                ? // ? <Fragment><span style='opacity: 0.6'>Search:</span> {searchTerm['search']}</Fragment>
                `Search: ${searchTerm}`
                : title
            }
            // template='slider2'
            template={template}
            // show_more={show_more}
            setLim={setLim}
            lim={lim}
          >
            {context.bundles
              .filter((one) => one.content[0].has_keys)
              .map((oneBundle, ind) => (
                <Slide
                  template={template}
                  key={`bundle_${oneBundle.sku}`}
                  {...oneBundle}
                  blockType={blockType}
                >
                  <Bundle
                    template={template}
                    bundleData={{ ...oneBundle }}
                    limit={limit}
                  />
                </Slide>
              )
                // }
              )}
          </ProductList>
        )}
        {!filteredProducts(
          filterkey,
          filterval,
          searchTerm,
          filterTyp,
          filterRel
        ).length && (
          <GridZeroState
            handleClear={() => {
              applyTextSearch();
              applyItemTypeFilter();
              applyFilterByRelease();
              applyGenre();
            }}
          />
        )}

        {Boolean(limit)
          && ['pagination', 'slider'].includes(show_more)
          && template !== 'slider2' && (
            <Pagination
              template={show_more}
              pages={Math.ceil(
                blockType === 'bundles'
                  ? context.bundles.length / limit
                  : filteredProducts(
                    filterkey,
                    filterval,
                    searchTerm,
                    filterTyp,
                    filterRel
                  ).length / limit
              )}
              page={page}
              handle={(newPage) => {
                setPage(newPage);
                onPagination();
              }}
            />
        )}
      </CssGrid>
    </>
  );
}

const CssHover = styled.div`
  @media screen and (max-width: 991px) {
  }
  @media screen and (max-width: 767px) {
    display: none;
  }
  @media screen and (max-width: 479px) {
    display: none;
  }
`;

const CssGrid = styled.div`
  width: 100%;
  min-height: 10vh;
  position: relative;

  /* z-index: 0; */
  &:hover {
    /* z-index: 1; */
    /* padding-bottom: 100px;
    margin-bottom: -100px; */
  }

  & .hover::after {
    content: "";
    background: rgba(255, 255, 255, 0.05);
    box-shadow: inset 0px 0px 50px 0px rgba(255, 255, 255, 0.1);
    opacity: 1;
    position: absolute;
    z-index: 100;
    top: 8px;
    left: 8px;
    right: 8px;
    bottom: 8px;
    transform: scale(1.05);
    transition: all 250ms cubic-bezier(0.42, 0, 0.58, 1);
    border: 1px dashed rgba(255, 255, 255, 0.4);
  }
  &:hover .hover::after {
    /* transform: scale(0.98, 0.99); */
    transform: scale(1);
  }

  & .hover {
    /* background: rgba(255,255,255,0.001); */
    pointer-events: none;
    opacity: 0;

    position: absolute;
    z-index: 100;
    top: 0px;
    left: 0px;
    right: 0px;
    /* bottom: 100px; */
    bottom: 0px;

    overflow: hidden;
  }
  &:hover .hover {
    opacity: ${({ shown }) => (shown ? 1 : 0)};
  }
`;

export default withRouter(Grid);
