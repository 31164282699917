import React from 'react';
import styled from 'styled-components';
import {Svg} from "./Icons/Svg";
import {icons} from "./Icons/icons";


export function SettingsSocMedia({title, platformName}) {
  return (
    <StyledSettingsSocMedia>
      <Svg
        width={32}
        height={32}
      >
        {icons[platformName]}
      </Svg>
      <StyledMediaName>{title}</StyledMediaName>
    </StyledSettingsSocMedia>
  );
}

const StyledSettingsSocMedia = styled.div` 
  display: flex;
  width: 90%;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px none #f77d00;
  
  @media screen and (max-width: 479px) {
    width: 50%;
  }
`;

const StyledMediaName = styled.div`
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 10px;
`;

