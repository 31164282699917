import React, {useContext, useEffect} from 'react';
import {Widget} from "@xsolla/login-sdk";

import {
  IS_DEV,
  PROJECT_ID,
  PREFERRED_LOCALE,
  LOGIN_CALLBACK_URL,
  LOGIN_CALLBACK_URL_LOCALE,
  XSOLLA_LOGIN_TOKEN_SB
} from '../config';
import {StoreContext} from "./StoreContext";


let xl = new Widget({
  projectId: PROJECT_ID,
  preferredLocale: PREFERRED_LOCALE,
  callbackUrl: IS_DEV ? LOGIN_CALLBACK_URL_LOCALE : LOGIN_CALLBACK_URL,
  iframeZIndex: 1000,
  showPopupBackground: true,
  popupBackgroundColor: "rgb(55, 0, 101)"
});

let xlreset = new Widget({
  projectId: PROJECT_ID,
  preferredLocale: PREFERRED_LOCALE,
  callbackUrl: IS_DEV ? LOGIN_CALLBACK_URL_LOCALE : LOGIN_CALLBACK_URL,
  iframeZIndex: 1000,
  showPopupBackground: true,
  page: "passwordSet",
  popupBackgroundColor: "rgb(55, 0, 101)"
});

export function LogInWidget() {

  const {loginWidgetShown, setLoginWidgetShown} = useContext(StoreContext);

  useEffect(() => {
    if (loginWidgetShown === "open") {
      if (!document.getElementById("xl_auth").childNodes.length) {
        xl.mount('xl_auth');
      }

      xl.open();
    }

    if (loginWidgetShown === "resetPas") {
      if (!document.getElementById("xl_reset").childNodes.length) {
        xlreset.mount('xl_reset');
      }

      xlreset.open();
    }

  }, [loginWidgetShown]);

  xl.on(xl.events.Close, function () {
    setLoginWidgetShown(false)
    xl.close()
  });

  xlreset.on(xlreset.events.Close, function () {
    setLoginWidgetShown(false)
    xlreset.close()
  });

  const url = new URL(window.location.href),
    urlToken = url.searchParams.get("token");

  if (urlToken != null) {
    document.cookie = `${XSOLLA_LOGIN_TOKEN_SB}=${urlToken};`;
    window.location.assign("/")
  }

  return (
    <>
      <div id="xl_auth" style={{maxHeight: 0}}/>
      <div id="xl_reset" style={{maxHeight: 0}}/>
    </>
  );
}
