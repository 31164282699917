import React, {
  Fragment, useEffect, useState, useContext
} from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { StoreContext } from './StoreContext';

export function ProductAppearer({
  children, appeared = false, order = 0, template = 'lg'
}) {
  const context = useContext(StoreContext);
  const [shown, setShown] = useState(context.cartShown ? true : appeared);

  let time = null;
  useEffect(() => {
    if (!shown) {
      time = setTimeout(() => {
        setShown(
          true
        );
      }, order * 50);
    } else {
      setShown(
        true
      );
    }
    return () => {
      clearTimeout(time);
    };
  }, []);

  return (
    <CssProductAppearer shown={shown} className={`grid-item grid-item--${template}`}>
      {children}
    </CssProductAppearer>
  );
}

const CssProductAppearer = styled.div`


  transition: transform 350ms ease-out, opacity 150ms ease-out;
  ${({ shown }) => !shown && css`
    opacity: 0;
    transform: translateY(20px) scale(1);
  `
};
`;
