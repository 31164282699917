import {useEffect, useRef} from  'react'


export function useOnClickOutside(onClick) {
  let containerRef = useRef(null)

  useEffect(() => {
    function onClickOutside(event) {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        onClick()
      }
    }

    document.addEventListener("mousedown", onClickOutside)
    return () => {
      document.removeEventListener("mousedown", onClickOutside)
    }
  }, [containerRef, onClick])

  return containerRef
}