import React from 'react';
import styled from 'styled-components';


export function CPaddings({children}) {
  return (
    <StyledCPaddings>
      {children}
    </StyledCPaddings>
  )
}

const StyledCPaddings = styled.div`  
  display: flex;
  width: 100%;
  padding-top: 3em;
  padding-bottom: 0;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  
  @media screen and (max-width: 767px) {
    padding-top: 2em;
  }
`;