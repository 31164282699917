import React, {useContext, useEffect} from 'react';
import {Redirect, withRouter, useLocation} from 'react-router-dom';
import {StoreContext} from "../StoreContext";
import {AccountSettingsForm} from './AccountSettingsForm';
import {DisconnectPopup} from './DisconnectPopup';


export function Account() {
  const {loginToken} = useContext(StoreContext);
  const {showCartButton, setShowCartButton} = useContext(StoreContext);
  let location = useLocation();

  useEffect(() => {
    setShowCartButton(false)
  }, [showCartButton,setShowCartButton, location]);


  if (!loginToken) {
    return <Redirect to={"/"}/>
  }

  return (
    <>
    <div data-ix="h-bg-show" className="c c--settings">
      <div className="cc">
        <div className="x_settings">
          <div className="x_signup_shade"/>
          <div className="x_settings_card">
            <div className="l_paddings_m">
              <div className="x_settings-form w-form">
                <AccountSettingsForm/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <DisconnectPopup/>
    </>
  );
}

export default withRouter(Account);
