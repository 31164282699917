import React, { useState, useContext } from 'react';
import { formatAmount, formatCurrencyIso } from './tools/buyNowLogic';
import { CartError } from './CartError';
import { CartCoupon } from './CartCoupon';
import { OrderButton } from './OrderButton';
import { redeemCoupon, getErrorMsg, clearCartApi, saveItemsToCart } from '../api/cartApi';
import { StoreContext } from './StoreContext';
import { CartBonusList } from './CartBonusList';
import { sendClick } from '../helpers/analytics';

export const CartBottom = ({
  cartItems, handleClear, price, coupon, setCoupon
}) => {
  const [isLoadingCoupon, setLoadingCoupon] = useState(false);
  const {
    updateCart, setCartError, userID, cartError,
    loginToken, cart
  } = useContext(StoreContext);

  const bonusProducts = cartItems.filter((item) => item.is_free);
  const subtotal = price ? price.amount_without_discount : 0;
  const total = price ? price.amount : 0;
  const discount = subtotal - total;
  const totalCurrency = price ? price.currency : 'USD';
  const hasDiscount = subtotal !== total;

  const handleApplyCode = async (event) => {
    event.preventDefault();
    setCartError('');
    if (!coupon) {
      return;
    }
    setLoadingCoupon(true);

    await clearCartApi(userID);
    if (cart.items.length) {
      await saveItemsToCart(userID, loginToken, cart);
    }

    const result = await redeemCoupon({
      couponCode: coupon,
      userID,
      token: loginToken
    });

    if (result.errorMessage) {
      setCartError(getErrorMsg(result));
      sendClick('sb-team17_cart_promocode-redeem', coupon, { type: 'invalid' });
    } else {
      await updateCart();
      sendClick('sb-team17_cart_promocode-redeem', coupon, { type: 'valid' });
      setCoupon('');
    }
    setLoadingCoupon(false);
  };

  return (
    <div className="k_bot">
      {
        !!cartError && <CartError errorMessage={cartError} />
      }
      <CartCoupon
        handleApplyCode={handleApplyCode}
        isLoadingCoupon={isLoadingCoupon}
        coupon={coupon}
        setCoupon={setCoupon}
      />
      <div className="k_buttons0">
        {bonusProducts && bonusProducts.length > 0 && (<CartBonusList products={bonusProducts} />)}
        <ul className="k_ula k_ul--totals w-list-unstyled">
          <li className="k_li_total">
            <div className="k_name k_name--tot">
              <div>Subtotal (taxes and fees may apply)</div>
              <div className="k_name_dots" />
            </div>
            <div data-kart="total" className="k_price k_price--total">
              {formatCurrencyIso(totalCurrency)}
              {formatAmount(subtotal)}
            </div>
          </li>
          <li className={`k_li_total ${!hasDiscount ? 'hide_discount' : ''}`}>
            <div className="k_name k_name--tot">
              <div>Discount</div>
              <div className="k_name_dots" />
            </div>
            <div data-kart="total" className="k_price k_price--total">
              {formatCurrencyIso(totalCurrency)}
              {formatAmount(discount)}
            </div>
          </li>
          <li className={`k_li_total ${!hasDiscount ? 'hide_discount' : ''}`}>
            <div className="k_name k_name--tot">
              <div>Total</div>
              <div className="k_name_dots" />
            </div>
            <div data-kart="total" className="k_price k_price--total">
              {formatCurrencyIso(totalCurrency)}
              {formatAmount(total)}
            </div>
          </li>
        </ul>
        {!!cartItems.length && (
          <OrderButton
            cartItems={cartItems}
            coupon={coupon}
            handleClear={handleClear}
          />
        )}
      </div>
    </div>
  );
};
