import React from 'react';

export function Pagination({
  template,
  pages,
  page,
  handle
}) {
  const arr = [];
  for (let i = 0; i < pages; i++) {
    arr.push(i);
  }
  return (
    <>
      {template === 'pagination' && arr.length > 1
        && (
          <div className="pagi0">
            {arr.map((item, ind) => (
              <div key={`page_${ind}`} className={`pagi ${page === ind + 1 ? 'pagi--active' : ''}`} onClick={() => handle(ind + 1)}>
                <div>{ind + 1}</div>
              </div>
            ))}
          </div>
        )}

      {template === 'slider'
        && (
          <div className="pagi0 pagi0--leftright">
            <div className={`pagi ${page === page - 1 ? 'pagi--active' : ''}`} onClick={() => handle(page - 1)}>
              {page}
              {' '}
              ←
            </div>
            <div className={`pagi ${page === page + 1 ? 'pagi--active' : ''}`} onClick={() => handle(page + 1)}>
              {page}
              →
            </div>
          </div>
        )}
    </>
  );
}
