import React from 'react';
import {AccountDescription} from "./Account/AccountDescription";


export function SupportInfo({title, children}) {

  return (
    <AccountDescription>
      {title}
      {children}
    </AccountDescription>
  );
}