import React from 'react';
import styled from 'styled-components';

export function SettingsInfo({title, titleValue}) {

  return (
    <StyledSettingsInfo>
      <StyledLabel>{title}</StyledLabel>
      <StyledH4 className="h4">{titleValue}</StyledH4>
    </StyledSettingsInfo>
  );
}

const StyledSettingsInfo = styled.div`
  width: 80%;
  border-bottom: 1px none #f77d00;
  
  @media screen and (max-width: 991px) {    
    width: 87%;
  }
  @media screen and (max-width: 479px) {    
    width: 100%;
  }  
`;

const StyledLabel = styled.div`
  margin-bottom: 0px;
  font-family: Codecpro, sans-serif;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 1.2px;
  text-transform: uppercase;
  
  @media screen and (min-width: 1920px) {    
    font-size: 14px;
  }
  @media screen and (max-width: 479px) {    
    display: flex;
  }  
`;

const StyledH4 = styled.h4`
  font-weight: bold;
  margin-bottom: 10px;
  font-size: 18px;
  line-height: 24px;
  display: flex;
  margin-top: 0px;
`;