import React from 'react';
import { Tags } from './Tags';

export function Menu({
  tags = 'platform',
  type = 'menu',
  active = 'coop'
}) {
  return (

    <div id="sticker" className="c">
      <div id="sticker" className="m">
        <div id="sticker" className="mm">
          {/* <div className="mm"> */}
          <ul
            className="m_ul w-list-unstyled"
          >

            <Tags
              active={active}
              what={tags}
              route={tags}
              className="m_ul"
              disabled={false}
              type="menu"
            />
            {/* <li className="m_li active">
              <div>All games</div>
            </li>
            <li className="m_li">
              <div>PC</div>
            </li>
            <li className="m_li">
              <div>PS4</div>
            </li>
            <li className="m_li">
              <div>Switch</div>
            </li>
            <li className="m_li">
              <div>xbox 1</div>
            </li> */}
          </ul>
          {/* <ul className="m_ul w-list-unstyled">
            <li className="m_li">
              <div>Coming soon</div>
            </li>
          </ul> */}
        </div>
      </div>
    </div>

  );
}
