import React, { useState, useEffect, useContext } from 'react';
import { getCookie, ACCEPT_COOKIES, AGE_GATE } from './CookieBanner';
import { StoreContext } from './StoreContext';

export function ParamsLink(props) {
  const context = useContext(StoreContext);

  const { href, children, ...rest } = props;

  const [hrefWithParams, setHref] = useState(href);

  useEffect(() => {
    const url = new URL(href);
    url.searchParams.append('gdpr', context.gdpr);
    if (context.ageGate) url.searchParams.append('age', context.ageGate);
    setHref(url.href);
  }, [href, context.ageGate, context.gdpr]);

  return (
    <a
      href={hrefWithParams}
      {...rest}
    >
      {children}
    </a>
  );
}
