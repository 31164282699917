import React, {Fragment} from 'react';
import {Link, useLocation} from 'react-router-dom';
import { Search } from './Search';
import { LogIn } from './LogIn';
import { ParamsLink } from './ParamsLink';

export function Header({ children }) {
  return (
    <div>
      <div
        data-collapse="medium"
        data-animation="default"
        data-duration={400}
        role="banner"
        className="h w-nav"
      >
        <div className="h_bg h_bg--team17-1" />
        <div className="hh w-container">
          <div className="h_left logo">
            <a href="/" target="_self" className="h_logo w-inline-block" />
          </div>

          <Search />
          <LogIn />
        </div>
        <div className="w-nav-overlay" data-wf-ignore id="w-nav-overlay-0" />
      </div>

      {/* <SubHeader/> */}

      <Breadcrubms />
    </div>
  );
}

export const Navigation = () => (
  <nav role="navigation" className="h_right_nav w-nav-menu">
    <a
      href="https://www.team17group.com/"
      target="_blank"
      className="h_link w-inline-block"
    >
      <div>investors</div>
    </a>
    <a
      href="https://www.team17.com/contact/"
      target="_blank"
      className="h_link w-inline-block"
    >
      <div>contact</div>
    </a>
    <a
      href="https://www.team17.com/support/"
      target="_blank"
      className="h_link w-inline-block"
    >
      <div>support</div>
    </a>
    <a href="#" className="h_link last w-inline-block">
      <div>Press</div>
    </a>
  </nav>
);


export const Breadcrubms = () => {


let routesCur = ["/", ...useLocation().pathname.split('/')].filter((el)=> el !== "");
const routesAll = [
  {route:"/", name:"store"},
  {route:"account", name:"account settings"}
]
let routeItem;

return(
  <div className="sub_h">
    <div className="h_bg sub_h_bg--team17" />
    <div className="sub_hh">
      <div className="h_left">
        <ParamsLink href="https://team17.com" className="h_link w-inline-block">
          <div>team17 home</div>
        </ParamsLink>

        {routesCur.map(function (item,i) {
          routeItem = routesAll.find(itemRoute => itemRoute.route === item)

          if (!routeItem) return true;

          return(
            <Fragment key={i}>
              <div className="h_bread w-embed">
                <svg
                  width={14}
                  height={13}
                  viewBox="0 0 14 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.02717 2.19497C4.7538 1.92161 4.7538 1.47839 5.02717 1.20503C5.30054 0.931658 5.74375 0.931658 6.01712 1.20503L10.6838 5.87169C10.9572 6.14506 10.9572 6.58827 10.6838 6.86164L6.01712 11.5283C5.74375 11.8017 5.30054 11.8017 5.02717 11.5283C4.7538 11.2549 4.7538 10.8117 5.02717 10.5384L9.19886 6.36667L5.02717 2.19497Z"
                    fill="white"
                  />
                </svg>
              </div>
              <Link to={routeItem.route} className={`h_link w-inline-block ${ i === routesCur.length - 1 && "h_link--active" }`}>
                {routeItem.name}
              </Link>
            </Fragment>
          );
        })}

      </div>
    </div>
  </div>
)};

export const SubHeader = () => (
  <div className="sub_h">
    <div className="h_bg sub_h_bg--team17" />
    <div className="sub_hh">
      <div className="h_left mobile-hide">
        <a href="#" className="h_link w-inline-block">
          <div>games</div>
        </a>
        <a href="/" className="h_link w-inline-block">
          <div>store</div>
        </a>
        <a
          href="https://www.team17.com/news/"
          target="_blank"
          className="h_link w-inline-block"
        >
          <div>news</div>
        </a>
        <a
          href="https://www.team17.com/about-us/"
          target="_blank"
          className="h_link w-inline-block"
        >
          <div>about</div>
        </a>
        <a href="#" className="h_link w-inline-block">
          <div>user research</div>
        </a>
        <a
          href="https://www.team17.com/submit-a-game/"
          target="_blank"
          className="h_link w-inline-block"
        >
          <div>submit game</div>
        </a>
        <a
          href="https://www.team17.com/careers/"
          target="_blank"
          className="h_link w-inline-block"
        >
          <div>join us</div>
        </a>
      </div>
      <div className="h_right">
        <a
          href="https://www.facebook.com/Team17/"
          target="_blank"
          className="h_social-media_link w-inline-block"
        >
          <img src="https://assets.website-files.com/5e73f39d8f7c0b9c03c51853/5f2cf664c61165581b1f9dba_facebook.svg" />
        </a>
        <a
          href="https://twitter.com/Team17Ltd"
          target="_blank"
          className="h_social-media_link w-inline-block"
        >
          <img src="https://assets.website-files.com/5e73f39d8f7c0b9c03c51853/5f2cf1faa241abb1701ebab4_twitter.svg" />
        </a>
        <a
          href="https://www.instagram.com/team17ltd/"
          target="_blank"
          className="h_social-media_link w-inline-block"
        >
          <img
            src="https://assets.website-files.com/5e73f39d8f7c0b9c03c51853/5f310c4cdb97620632f3ea44_instagram.svg"
            width={15}
            height={19}
          />
        </a>
        <a
          href="https://www.youtube.com/user/Team17SoftwareLtd"
          target="_blank"
          className="h_social-media_link w-inline-block"
        >
          <img src="https://assets.website-files.com/5e73f39d8f7c0b9c03c51853/5f2cf6b16f4a7828150f3b2b_youtube.svg" />
        </a>
        <a
          href="https://discord.gg/team17"
          target="_blank"
          className="h_social-media_link w-inline-block"
        >
          <img src="https://assets.website-files.com/5e73f39d8f7c0b9c03c51853/5f2cf6d56880fe09e543a7e4_Discord.svg" />
        </a>
        <a
          href="https://www.twitch.tv/team17ltd"
          target="_blank"
          className="h_social-media_link w-inline-block"
        >
          <img src="https://assets.website-files.com/5e73f39d8f7c0b9c03c51853/5f2cf271793f144b21a5266e_twitch.svg" />
        </a>
        <a
          href="mailto:team17@vigocomms.com"
          className="h_social-media_link w-inline-block"
        >
          <img src="https://assets.website-files.com/5e73f39d8f7c0b9c03c51853/5f2cf70854df617c9fbaac6d_email.svg" />
        </a>
      </div>
    </div>
  </div>
);
