import React from 'react';
import styled from 'styled-components';

export function AccountRowSet({children, direction, align}) {

  return (
    <StyledRow direction={direction} align={align}>{children}</StyledRow>
  );
}

const StyledRow = styled.div`
  display: flex;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 20px;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px none rgba(226, 231, 255, 0.5);
  align-items: ${(props) => props.align || ''};
  
  @media screen and (max-width: 479px) {
    flex-direction: ${(props) => props.direction || "row" };
    align-items: flex-start;
  }
`;
